import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { Button, Table, Typography, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { moderateAdvert } from '@/modules/moderation/api';
import {
  getDateStr,
  APPROVED_ID,
  getAdvertName,
  REJECTED_ID
} from '@/modules/moderation/helpers';
import { useModerationAdverts } from '@/modules/moderation/hooks';
import { RejectForm } from '@/modules/moderation/RejectForm';
import { ModerationAdvert } from '@/modules/moderation/types';
import { ADV_CUR, TABLE_ACTION_CLASS } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';
import { showAlert } from '@/utils/network';
import { Nullable } from '@/utils/types';

export function ModerationList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Data
  const [refreshKey, setRefreshKey] = useState(0);
  const [data, isLoading] = useModerationAdverts(refreshKey);
  const refreshData = () => {
    setRefreshKey((p) => p + 1);
  };

  // Moderate
  const [isChanging, setChanging] = useState(false);

  const [isRejectOpen, setRejectOpen] = useState(false);
  const [rejectAdId, setRejectAdId] = useState<Nullable<number>>(null);
  const [reasonText, setReasonText] = useState('');
  const changeStatus = useCallback(
    async (id: number, approved: boolean) => {
      setChanging(true);
      try {
        await moderateAdvert({
          status_id: approved ? APPROVED_ID : REJECTED_ID,
          advertisement_id: id,
          rejected_reason: approved ? null : reasonText
        });
        if (!approved) {
          setReasonText('');
          setRejectAdId(null);
        }
        refreshData();
      } catch (error) {
        showAlert({ error });
      } finally {
        setChanging(false);
      }
    },
    [reasonText]
  );

  const onRejectClick = (id: number) => {
    setRejectOpen(true);
    setRejectAdId(id);
  };
  const onReject = () => {
    setRejectOpen(false);
    if (rejectAdId) changeStatus(rejectAdId, false);
  };

  // Columns
  const columns = useMemo<ColumnsType<ModerationAdvert>>(
    () => [
      {
        key: 'name',
        title: t('name'),
        render: (_, rec) => {
          return <p style={{ color: '#1677FF' }}>{getAdvertName(t, rec)}</p>;
        }
      },
      {
        key: 'price',
        title: t('price'),
        render: (_, rec) =>
          rec.price ? `${numberWithSpaces(rec.price)} ${ADV_CUR}` : ''
      },
      {
        key: 'date',
        title: t('date'),
        render: (_, rec) => getDateStr(rec.sent_on_moderation_at)
      },
      {
        key: 'actions',
        render: (_, rec) => (
          <Space>
            <Button
              className={TABLE_ACTION_CLASS}
              onClick={() => changeStatus(rec.ad_id, true)}
              disabled={isChanging || isLoading}
              icon={<CheckSquareOutlined />}
              type="link"
            />
            <Button
              className={TABLE_ACTION_CLASS}
              onClick={() => onRejectClick(rec.ad_id)}
              disabled={isChanging || isLoading}
              icon={<CloseSquareOutlined />}
              type="link"
              danger
            />
          </Space>
        ),
        width: 64
      }
    ],
    [changeStatus, isChanging, isLoading, t]
  );

  return (
    <div>
      <Space className="table-header">
        <Typography.Title>{t('moderationAdverts')}</Typography.Title>
      </Space>

      <Table
        columns={columns}
        rowKey={(rec) => rec.ad_id}
        dataSource={data}
        pagination={false}
        loading={isLoading}
        onRow={(rec) => ({
          onClick: (e) => {
            const el = e.target as HTMLElement;
            if (!el?.closest(`.${TABLE_ACTION_CLASS}`)) {
              navigate(`/moderation/${rec.ad_id}`);
            }
          }
        })}
      />

      <RejectForm
        isOpen={isRejectOpen}
        setOpen={setRejectOpen}
        text={reasonText}
        setText={setReasonText}
        onSubmit={onReject}
      />
    </div>
  );
}

import { Table, Tag } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getAdvertName, getDateStr } from '@/modules/moderation/helpers';
import { AdvertStatusType } from '@/modules/moderation/types';
import { useSearchAdvertsPg } from '@/modules/search/hooks';
import {
  SearchAdvert,
  SearchAdvertsReq,
  SortFilter
} from '@/modules/search/types';
import { ADV_CUR, APP_URL, pgConfig, PG_SIZE } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';

import cls from './SearchResult.module.scss';

type Props = {
  req: SearchAdvertsReq;
  sortFilters: SortFilter[];
  showSort?: boolean;
};

export function SearchResult({ req }: Props) {
  const { t } = useTranslation();

  const [pg, setPg] = useState<TablePaginationConfig>({
    ...pgConfig
  });
  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPg((p) => ({ ...p, ...pagination }));
  };

  const pgReq = useMemo(() => {
    const current = pg.current;
    const pageSize = pg.pageSize;
    const isValid = typeof current === 'number' && typeof pageSize === 'number';

    if (isValid) {
      const limit = pageSize;
      const skip = (current - 1) * limit;
      return { limit, skip };
    }
    return { limit: PG_SIZE, skip: 0 };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pg.current, pg.pageSize]);
  const { isLoading, data } = useSearchAdvertsPg(req, pgReq);

  useEffect(() => {
    if (data) setPg((p) => ({ ...p, total: data.ads_count }));
  }, [data]);

  const columns: ColumnsType<SearchAdvert> = [
    {
      title: t('id'),
      dataIndex: 'id',
      width: 64
    },
    {
      title: t('name'),
      render: (_, rec) => {
        return (
          <a
            style={{ color: '#1677FF' }}
            href={`${APP_URL}/${rec.url}`}
            target="_blank"
            rel="noreferrer"
          >
            {getAdvertName(t, rec)}
          </a>
        );
      }
    },
    {
      key: 'price',
      title: t('price'),
      render: (_, rec) =>
        rec.price ? `${numberWithSpaces(rec.price)} ${ADV_CUR}` : ''
    },
    {
      key: 'date',
      title: t('publishDate'),
      render: (_, rec) => (rec.published_at ? getDateStr(rec.published_at) : '')
    },
    {
      key: 'status',
      title: t('status'),
      render: (_, rec) => (
        <Tag
          color={
            rec.status.title === AdvertStatusType.published
              ? 'green'
              : undefined
          }
        >
          {rec.status.name}
        </Tag>
      )
    }
  ];

  return (
    <div className={cls.root}>
      {data && (
        <ul className={cls.statuses}>
          {data.statuses.map((s, i) => (
            <li key={s.id}>
              {s.name}: {s.count}
              {i !== data.statuses.length - 1 && <span>/</span>}
            </li>
          ))}
        </ul>
      )}
      <Table
        columns={columns}
        rowKey={(rec) => rec.id}
        dataSource={data?.ads}
        loading={isLoading}
        pagination={pg}
        onChange={handleTableChange}
      />
    </div>
  );
}

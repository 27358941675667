import qs from 'qs';
import { useLocation } from 'react-router-dom';

export function useQuery(): qs.ParsedQs {
  const { search } = useLocation();
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  return params;
}

export function removeQueryParam(name: string): string {
  const params = new URLSearchParams();
  params.delete(name);
  return params.toString();
}

export function getNumberFromString(str: string): number | null {
  const result = str.match(/\d+/);
  if (result && result[0]) {
    const value = result[0];
    const num = Number(value);

    if (num && !Number.isNaN(num)) return num;
  }

  return null;
}

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuthed } from '@/modules/auth/store';
import { AuthState } from '@/modules/auth/types';

export function PrivateRoute() {
  const location = useLocation();
  const [isAuthed, authState] = useAuthed();

  if (authState === AuthState.initial) {
    return null;
  }

  return isAuthed ? (
    <Outlet />
  ) : (
    <Navigate to="/auth" state={{ from: location }} />
  );
}

import { useState, useCallback, useEffect } from 'react';

import { axios } from '@/api/instance';
import { useCommonStore } from '@/app/common-store';
import {
  GetModerationReplyReviewsReq,
  GetModerationReplyReviewsRes,
  GetModerationReviewsReq,
  GetModerationReviewsRes
} from '@/modules/moderation/reviews/types';
import { showAlert } from '@/utils/network';

export function useModerationReviews(
  params?: GetModerationReviewsReq,
  refreshKey?: number
): [GetModerationReviewsRes, boolean] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetModerationReviewsRes>([]);

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetModerationReviewsRes>(
        '/admin/moderation/reviews',
        {
          params,
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, isLoading];
}

export function useModerationReplyReviews(
  params?: GetModerationReplyReviewsReq,
  refreshKey?: number
): [GetModerationReplyReviewsRes, boolean] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetModerationReplyReviewsRes>([]);

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetModerationReplyReviewsRes>(
        '/admin/moderation/reviews/reply',
        {
          params,
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, isLoading];
}

import { EditOutlined } from '@ant-design/icons';
import { Button, Table, Typography, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createBank, updateBank } from '@/modules/banks/api';
import { useBanks } from '@/modules/banks/hooks';
import { BankType } from '@/modules/banks/types';
import { TABLE_ACTION_CLASS } from '@/utils/consts';
import { showAlert } from '@/utils/network';
import { Nullable } from '@/utils/types';

import { BankForm, FormType } from './BankForm/BankForm';

export function BanksList() {
  const { t } = useTranslation();

  // Data
  const [refreshKey, setRefreshKey] = useState(0);
  const [data, isLoading] = useBanks(refreshKey);
  const refreshData = () => {
    setRefreshKey((p) => p + 1);
  };

  // Add
  const [isAdding, setAdding] = useState<boolean>(false);
  const [isAddOpen, setAddOpen] = useState<boolean>(false);
  const onAddClick = () => {
    setAddOpen(true);
  };
  const onAddSave = async (form: FormType) => {
    setAdding(true);

    try {
      await createBank({
        ...form
      });
      setAddOpen(false);
      refreshData();
    } catch (error) {
      showAlert({ error });
    } finally {
      setAdding(false);
    }
  };

  // Edit
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const [selectedBank, setSelectedBank] = useState<Nullable<BankType>>(null);
  const onEditClick = (bank: BankType) => {
    setSelectedBank(bank);
    setEditOpen(true);
  };
  const onEditSave = async (form: FormType) => {
    if (!selectedBank) return;

    setEditing(true);
    try {
      await updateBank({
        ...form,
        bank_id: selectedBank.id
      });
      setSelectedBank(null);
      setEditOpen(false);
      refreshData();
    } catch (error) {
      showAlert({ error });
    } finally {
      setEditing(false);
    }
  };

  // Columns
  const columns = useMemo<ColumnsType<BankType>>(
    () => [
      {
        key: 'img',
        render: (_, rec) => (
          <img
            src={rec.photo_url}
            width={40}
            height={40}
            style={{ objectFit: 'contain' }}
          />
        ),
        width: 54
      },
      {
        key: 'name_ru',
        dataIndex: 'name_ru',
        title: t('bankNameRu')
      },
      {
        key: 'name_en',
        dataIndex: 'name_en',
        title: t('bankNameEn')
      },
      {
        key: 'interestRate',
        title: t('interestRate'),
        render: (_, rec) => `${rec.interest_rate}%`
      },
      {
        key: 'initialPayment',
        title: t('initialPayment'),
        render: (_, rec) => `${rec.initial_payment}%`
      },
      {
        key: 'minLoanTerm',
        title: t('minLoanTerm'),
        dataIndex: 'minimum_loan_term'
      },
      {
        key: 'maxLoanTerm',
        title: t('maxLoanTerm'),
        dataIndex: 'maximum_loan_term'
      },
      {
        key: 'edit',
        render: (_, rec) => (
          <Button
            className={TABLE_ACTION_CLASS}
            onClick={() => onEditClick(rec)}
            disabled={isEditing || isAdding}
            icon={<EditOutlined />}
            type="link"
          />
        ),
        width: 64
      }
    ],
    [isAdding, isEditing, t]
  );

  return (
    <div>
      <Space className="table-header">
        <Typography.Title>{t('banks')}</Typography.Title>
        <Button onClick={onAddClick} type="primary" disabled={isAdding}>
          {t('common.add')}
        </Button>
      </Space>

      <Table
        columns={columns}
        rowKey={(rec) => rec.id}
        dataSource={data}
        pagination={false}
        loading={isLoading}
      />

      <BankForm
        title={t('common.add')}
        isOpen={isAddOpen}
        setOpen={setAddOpen}
        onSave={onAddSave}
        loading={isAdding}
      />

      {selectedBank && (
        <BankForm
          key={selectedBank.id}
          title={t('common.change')}
          isOpen={isEditOpen}
          setOpen={setEditOpen}
          onSave={onEditSave}
          loading={isEditing}
          initialData={{
            ...selectedBank
          }}
        />
      )}
    </div>
  );
}

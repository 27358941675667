import { AccountType } from '@/modules/accounts/types';
import { ComplectationWithOptions } from '@/modules/complectation/types';
import { EmirateType } from '@/modules/emirates/types';
import { EmojiType } from '@/modules/emojis/types';
import { RegSpec } from '@/modules/regional-specs/types';
import { TariffType } from '@/modules/tariffs/types';
import {
  TransportBodySimple,
  TransportBrand,
  TransportCategory,
  TransportColor,
  TransportDriveUnit,
  TransportEngine,
  TransportGeneration,
  TransportModel,
  TransportModification,
  TransportOwner,
  TransportTransmission,
  TransportType,
  TransportYear
} from '@/modules/transport/types';
import { VrpCode, VrpType } from '@/modules/vrp/types';
import { CommonObj, Nullable, NullableValues, PhotoType } from '@/utils/types';

export enum AdvertStatusType {
  moderation = 'on_moderation',
  rejected = 'rejected',
  published = 'published',
  sold = 'sold',
  draft = 'draft',
  removed = 'removed_from_publication'
}

export interface AdvertStatus extends CommonObj {
  title: AdvertStatusType;
}

export interface AdvertStatusWithCount extends AdvertStatus {
  count: number;
}

export interface ModerationAdvert {
  ad_id: number;
  sent_on_moderation_at: number;
  price: Nullable<number>;
  brand: Nullable<TransportBrand>;
  model: Nullable<TransportModel>;
  generation: Nullable<TransportGeneration>;
  year_of_issue: Nullable<TransportYear>;
  vrp_plate_emirate: Nullable<EmirateType>;
  vrp_type: Nullable<VrpType>;
  vrp_plate_number: Nullable<string>;
  vrp_plate_code: Nullable<VrpCode>;
}

export type GetModerationAdvertsRes = ModerationAdvert[];

interface ModerationAdvertDetailBase {
  description: string;
  owner: TransportOwner;
  call_before: number;
  call_after: number;
  name: string;
  address: string;
  email: string;
  emirate: EmirateType;
  regional_spec: RegSpec;
  lat: number;
  long: number;
  available_chat: boolean;
  available_phone: boolean;
  available_trade: boolean;
  price: number;
  tariff: TariffType;
  published_at: number;
  price_is_increase: boolean;
  author_avatar_url: string;
  stickers: EmojiType[];
  description_language_code: string;
  discount_credit: number;
  discount_trade_in: number;
  maximum_discount: number;
  snapshot_url: string;
  brand: TransportBrand;
  model: TransportModel;
  generation: TransportGeneration;
  year_of_issue: TransportYear;
  body: TransportBodySimple;
  modification: TransportModification;
  type: TransportType;
  engine: TransportEngine;
  drive_unit: TransportDriveUnit;
  transmission: TransportTransmission;
  colour: TransportColor;
  mileage: number;
  is_broken: boolean;
  photos: PhotoType[];
  vin: string;
  complectation: ComplectationWithOptions;
  detailed_characteristics_available: boolean;
  vrp_plate_emirate: EmirateType;
  vrp_type: VrpType;
  vrp_plate_number: string;
  vrp_image_url: string;
  vrp_plate_code: VrpCode;
}

export interface ModerationAdvertDetail
  extends NullableValues<ModerationAdvertDetailBase> {
  id: number;
  category: TransportCategory;
  status: AdvertStatus;
  account_type: AccountType;
  author_id: number;
  url: string;
}

// Moderate
export interface ModerateAdvertReq {
  status_id: number;
  advertisement_id: number;
  rejected_reason: Nullable<string>;
}

import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/api/instance';
import { useCommonStore } from '@/app/common-store';
import { GetCategoriesReq, GetCategoriesRes } from '@/modules/category/types';
import { showAlert } from '@/utils/network';

export function getCategories(params?: GetCategoriesReq, langCode?: string) {
  return axios.get<GetCategoriesRes>('/categories/list', {
    params,
    headers: {
      lang: langCode || null
    }
  });
}

export function useCategories(
  params?: GetCategoriesReq
): [GetCategoriesRes | undefined, boolean] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetCategoriesRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getCategories(params, langCode);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function onlyNumbers(value: string, allowedChars = ''): string {
  const reg = new RegExp(`[^0-9${allowedChars}]`, 'g');
  return value.trim().replace(reg, '');
}

export function numberWithSpaces(x: number): string {
  // return x.toLocaleString();
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
}

export function capitalizeWord(word: string): string {
  const loweredCase = word.toLowerCase();
  return word[0].toUpperCase() + loweredCase.slice(1);
}

export function removeSpaces(word: string): string {
  return word.replace(/\s/g, '');
}

export function maxLength(str: string, max: number): string {
  if (str.length > max) {
    return str.slice(0, max);
  }

  return str;
}

export function declension(
  count: number,
  one: string,
  two: string,
  five: string
): string {
  const last = count % 10;

  if (count >= 12 && last <= 14) {
    return five;
  }

  if (last >= 11 && last <= 14) {
    return five;
  }

  if (last >= 2 && last <= 4) {
    return two;
  }

  if (last === 1) {
    return one;
  }

  return five;
}

export function numberInRange(value: string, min: number, max: number) {
  if (typeof value === 'undefined' || value === null) return '';
  if (value[value.length - 1] === '.') return value;

  const valueInt = Number(onlyNumbers(value, '.'));

  if (valueInt < min) return min.toString();
  if (valueInt > max) return max.toString();
  if (valueInt >= min && valueInt <= max) return value;

  return '';
}

import { Space, TablePaginationConfig, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccounts } from '@/modules/account/api';
import { UserAccount } from '@/modules/account/types';
import { getDateStr } from '@/modules/moderation/helpers';
import { APP_URL, pgConfig, PG_SIZE } from '@/utils/consts';

export function AccountsList() {
  const { t } = useTranslation();

  const [pg, setPg] = useState<TablePaginationConfig>({
    ...pgConfig
  });
  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPg((p) => ({ ...p, ...pagination }));
  };

  const pgReq = useMemo(() => {
    const current = pg.current;
    const pageSize = pg.pageSize;
    const isValid = typeof current === 'number' && typeof pageSize === 'number';

    if (isValid) {
      const limit = pageSize;
      const skip = (current - 1) * limit;
      return { limit, skip };
    }

    return { limit: PG_SIZE, skip: 0 };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pg.current, pg.pageSize]);
  const [accountsData, isLoading] = useAccounts(pgReq);
  const accounts = accountsData?.accounts || [];

  useEffect(() => {
    if (accountsData) setPg((p) => ({ ...p, total: accountsData.count }));
  }, [accountsData]);

  const columns: ColumnsType<UserAccount> = [
    {
      key: 'id',
      title: t('id'),
      render: (_, rec) => {
        return (
          <a
            href={`${APP_URL}/profile/${rec.id}`}
            target="_blank"
            rel="noreferrer"
            style={{ color: '#1677FF' }}
          >
            {rec.id}
          </a>
        );
      }
    },
    {
      key: 'name',
      title: t('name'),
      render: (_, rec) => <p className="wrap_text">{rec.name}</p>
    },
    {
      key: 'phone',
      title: t('phone'),
      dataIndex: 'phone_number'
    },
    {
      key: 'email',
      title: t('email'),
      dataIndex: 'email'
    },
    {
      key: 'date',
      title: t('regDate'),
      render: (_, rec) => getDateStr(rec.registration_date)
    }
  ];

  return (
    <div>
      <Space className="table-header">
        <Typography.Title>{t('accounts')}</Typography.Title>
      </Space>

      <Table
        columns={columns}
        rowKey={(rec) => rec.id}
        dataSource={accounts}
        loading={isLoading}
        pagination={pg}
        onChange={handleTableChange}
      />
    </div>
  );
}

import { Input, Modal, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UpdateShowroomTariffsReq } from '@/modules/tariffs/types';

export type FormType = UpdateShowroomTariffsReq;

type Props = {
  title: string;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  initialData: FormType;
  onSave: (form: FormType) => void;
  loading?: boolean;
};

export function TariffForm({
  title,
  isOpen,
  setOpen,
  initialData,
  onSave,
  loading
}: Props) {
  const { t } = useTranslation();

  const [id, setId] = useState(initialData.id);
  const [firstDayPrice, setFirstDayPrice] = useState(
    initialData.first_day_price
  );
  const [nextDaysPrice, setNextDaysPrice] = useState(
    initialData.next_days_price
  );

  const close = () => {
    setOpen(false);
    setId(0);
    setFirstDayPrice(0);
    setNextDaysPrice(0);
  };

  useEffect(() => {
    setId(initialData.id);
    setFirstDayPrice(initialData.first_day_price || 0);
    setNextDaysPrice(initialData.next_days_price || 0);
  }, [initialData]);

  const isValid = !!firstDayPrice && !!nextDaysPrice;

  const onOk = () => {
    onSave({
      id: id,
      first_day_price: firstDayPrice,
      next_days_price: nextDaysPrice
    });
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      okText={t('common.save')}
      cancelText={t('common.cancel')}
      onCancel={close}
      onOk={onOk}
      okButtonProps={{ disabled: loading || !isValid }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <p>{t('firstDayPrice')}</p>
          <Input
            value={firstDayPrice}
            type="number"
            onChange={(e) => setFirstDayPrice(Number(e.currentTarget.value))}
            size="large"
          />
        </div>
        <div>
          <p>{t('nextDaysPrice')}</p>
          <Input
            value={nextDaysPrice}
            type="number"
            onChange={(e) => setNextDaysPrice(Number(e.currentTarget.value))}
            size="large"
          />
        </div>
      </Space>
    </Modal>
  );
}

import {
  ExclamationCircleFilled,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { Button, Table, Typography, Space, Modal, Input, Checkbox } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCommonStore } from '@/app/common-store';
import { deleteBrand, updateBrand } from '@/modules/transport/api';
import { useBrandsPg } from '@/modules/transport/hooks';
import { TransportBrand } from '@/modules/transport/types';
import { MAX_PG_LIMIT, TABLE_ACTION_CLASS } from '@/utils/consts';
import { showAlert } from '@/utils/network';
import { Nullable } from '@/utils/types';

import { BrandForm, FormType } from './BrandForm/BrandForm';

const { confirm } = Modal;

export function BrandsList() {
  const { t } = useTranslation();
  const setCrumbBrand = useCommonStore((s) => s.setCrumbBrand);

  // Data
  const [search, setSearch] = useState('');
  const [popularFirst, setPopularFirst] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const req = useMemo(() => {
    return {
      skip: 0,
      limit: MAX_PG_LIMIT
    };
  }, []);
  const [data, , isLoading] = useBrandsPg(req, refreshKey);
  const filteredData = useMemo(() => {
    const lowSearch = search.toLowerCase();
    const searched = search
      ? data.filter((v) => {
          const keywords = v.keywords
            ? v.keywords.map((k) => k.toLowerCase())
            : [];

          return (
            v.name.toLowerCase().includes(lowSearch) ||
            keywords.some((k) => k.includes(lowSearch))
          );
        })
      : data;

    return popularFirst
      ? [...searched].sort((a, b) => {
          const popularA = a.is_popular ? 1 : -1;
          const popularB = b.is_popular ? 1 : -1;
          return popularB - popularA;
        })
      : searched;
  }, [data, popularFirst, search]);

  const refreshData = () => {
    setRefreshKey((p) => p + 1);
  };

  // Add
  const [isAdding] = useState<boolean>(false);
  const [isAddOpen, setAddOpen] = useState<boolean>(false);
  const onAddClick = () => {
    setAddOpen(true);
  };
  const onAddSave = (form: FormType) => {
    // todo: create new brand
    console.log(form);
  };

  // Edit
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const [selectedBrand, setSelectedBrand] =
    useState<Nullable<TransportBrand>>(null);
  const onEditClick = (brand: TransportBrand) => {
    setSelectedBrand(brand);
    setEditOpen(true);
  };
  const onEditSave = async (form: FormType) => {
    if (!selectedBrand) return;

    setEditing(true);
    try {
      await updateBrand({
        ...form,
        brand_id: selectedBrand.id,
        is_popular: selectedBrand.is_popular
      });
      setSelectedBrand(null);
      setEditOpen(false);
      refreshData();
    } catch (error) {
      showAlert({ error });
    } finally {
      setEditing(false);
    }
  };
  const togglePopular = useCallback(
    async (brand: TransportBrand, is_popular: boolean) => {
      setEditing(true);
      try {
        await updateBrand({ ...brand, is_popular, brand_id: brand.id });
        refreshData();
      } catch (error) {
        showAlert({ error });
      } finally {
        setEditing(false);
      }
    },
    []
  );

  // Delete
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const makeBrandDelete = useCallback((brand_id: number) => {
    return new Promise<void>((resolve, reject) => {
      setDeleting(true);

      deleteBrand({ brand_id })
        .then(() => {
          refreshData();
          resolve();
        })
        .catch((error) => {
          showAlert({ error });
          reject();
        })
        .finally(() => {
          setDeleting(false);
        });
    });
  }, []);

  const showDeleteConfirm = useCallback(
    (brand: TransportBrand) => {
      confirm({
        title: t('common.deleteConfirm'),
        icon: <ExclamationCircleFilled />,
        content: brand.name,
        okText: t('common.delete'),
        cancelText: t('common.cancel'),
        onOk() {
          return makeBrandDelete(brand.id);
        }
      });
    },
    [makeBrandDelete, t]
  );
  const onDeleteClick = useCallback(
    (brand: TransportBrand) => {
      setEditOpen(false);
      showDeleteConfirm(brand);
    },
    [showDeleteConfirm]
  );

  // Columns
  const columns = useMemo<ColumnsType<TransportBrand>>(
    () => [
      {
        key: 'img',
        render: (_, rec) => <img src={rec.photo_url} width={22} height={22} />,
        width: 54
      },
      {
        key: 'dark_img',
        render: (_, rec) => (
          <img
            style={{ background: 'black' }}
            src={rec.dark_mode_photo_url}
            width={22}
            height={22}
          />
        ),
        width: 54
      },
      {
        key: 'name',
        title: t('brandName'),
        render: (_, rec) => {
          const params = {
            brand_id: rec.id.toString(),
            brand_name: rec.name
          };

          return (
            <Link to={`/db/models?${new URLSearchParams(params).toString()}`}>
              <span>{rec.name}</span>
            </Link>
          );
        }
      },
      {
        key: 'popular',
        title: t('popular'),
        render: (_, rec) => (
          <Checkbox
            className={TABLE_ACTION_CLASS}
            checked={rec.is_popular}
            onChange={() => togglePopular(rec, !rec.is_popular)}
            disabled={isDeleting || isEditing}
          />
        )
      },
      {
        key: 'edit',
        render: (_, rec) => (
          <Button
            className={TABLE_ACTION_CLASS}
            onClick={() => onEditClick(rec)}
            disabled={isDeleting || isEditing}
            icon={<EditOutlined />}
            type="link"
          />
        ),
        width: 64
      },
      {
        key: 'delete',
        render: (_, rec) => (
          <Button
            className={TABLE_ACTION_CLASS}
            onClick={() => onDeleteClick(rec)}
            disabled={isDeleting || isEditing}
            icon={<DeleteOutlined />}
            type="link"
            danger
          />
        ),
        width: 64
      }
    ],
    [isDeleting, isEditing, onDeleteClick, t, togglePopular]
  );

  return (
    <div>
      <Space className="table-header">
        <Typography.Title>{t('brands')}</Typography.Title>
        <Button onClick={onAddClick} type="primary">
          {t('common.add')}
        </Button>
      </Space>

      <Space className="table-bar">
        <Input
          style={{ width: 320 }}
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          placeholder={t('common.search') as string}
        />
        <Checkbox
          checked={popularFirst}
          onChange={(e) => setPopularFirst(e.target.checked)}
        >
          {t('popularFirst')}
        </Checkbox>
      </Space>

      <Table
        columns={columns}
        rowKey={(rec) => rec.id}
        dataSource={filteredData}
        pagination={false}
        loading={isLoading}
        onRow={(rec) => ({
          onClick: (e) => {
            const el = e.target as HTMLElement;
            if (!el?.closest(`.${TABLE_ACTION_CLASS}`)) {
              setCrumbBrand(rec.name, '/db/brands');
            }
          }
        })}
      />

      <BrandForm
        title={t('common.add')}
        isOpen={isAddOpen}
        setOpen={setAddOpen}
        onSave={onAddSave}
        loading={isAdding}
      />

      {selectedBrand && (
        <BrandForm
          key={selectedBrand.id}
          title={t('common.change')}
          isOpen={isEditOpen}
          setOpen={setEditOpen}
          onSave={onEditSave}
          loading={isEditing}
          initialData={{
            ...selectedBrand,
            keywords: selectedBrand.keywords || []
          }}
        />
      )}
    </div>
  );
}

import { FilterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '@/hooks/useDebounce';
import { EmirateType } from '@/modules/emirates/types';
import { RegSpec } from '@/modules/regional-specs/types';
import {
  DEBOUNCE_TIME,
  EMPTY_BRAND_ID,
  SEARCH_RESULT_ID
} from '@/modules/search/consts';
import {
  calcSelectedParamsCount,
  storeToSearchReq,
  defaultSearchReq
} from '@/modules/search/helpers';
import {
  useSearchSelect,
  SearchSelectContext
} from '@/modules/search/search-select-ctx';
import { SearchDesktop } from '@/modules/search/SearchAdverts/SearchCars/SearchDesktop';
import { SearchResult } from '@/modules/search/SearchAdverts/SearchResult/SearchResult';
import { useSearchStore } from '@/modules/search/store';
import {
  BrokenParam,
  PubDay,
  SearchAdvertsReq,
  SortFilter
} from '@/modules/search/types';
import { useTransportYears } from '@/modules/transport/hooks';
import {
  TransportBrand,
  TransportColor,
  TransportType
} from '@/modules/transport/types';
import { MAX_PG_LIMIT } from '@/utils/consts';
import { Nullable } from '@/utils/types';

import cls from './SearchCars.module.scss';

type Props = {
  req: SearchAdvertsReq;
  showResults: boolean;
  brands: TransportBrand[];
  types: TransportType[];
  colors: TransportColor[];
  emirates: EmirateType[];
  regSpecs: RegSpec[];
  pubDays: PubDay[];
  brokenParams: BrokenParam[];
  sortFilters: SortFilter[];
  hideFilters: boolean;
  toggleHideFilters: (v: boolean) => void;
};

export function SearchCars({
  req: initialReq,
  showResults,
  brands,
  types,
  colors,
  emirates,
  regSpecs,
  pubDays,
  brokenParams,
  sortFilters,
  hideFilters,
  toggleHideFilters
}: Props) {
  const { t } = useTranslation();

  const searchStore = useSearchStore((s) => s);
  const debouncedStore = useDebounce(searchStore, DEBOUNCE_TIME);
  const [, allSelectedParamsCount] = useMemo(
    () => calcSelectedParamsCount(searchStore),
    [searchStore]
  );

  const [req, setReq] = useState(initialReq);

  // Auto filters
  const { autoFilters } = searchStore;
  const brandsIds = useMemo<number[]>(
    () =>
      autoFilters
        .filter((v) => !!v.brand_id && v.brand_id !== EMPTY_BRAND_ID)
        .map((v) => v.brand_id),
    [autoFilters]
  );
  const modelsIds = useMemo<number[]>(
    () =>
      autoFilters
        .filter((v) => v.model_id !== null)
        .map((v) => v.model_id) as number[],
    [autoFilters]
  );

  // Years
  const yearsReq = useMemo(
    () => ({
      limit: MAX_PG_LIMIT,
      brand_ids: brandsIds,
      model_ids: modelsIds
    }),
    [brandsIds, modelsIds]
  );
  const [years, yearsLoading] = useTransportYears(yearsReq);

  // Search
  const searchReq = useMemo(
    () => storeToSearchReq(debouncedStore),
    [debouncedStore]
  );
  // const searchReqUrl = useMemo(
  //   () => stringifySearchReq(searchReq),
  //   [searchReq]
  // );

  const [searchStateReq, setSearchStateReq] = useState<
    Nullable<SearchAdvertsReq>
  >(showResults ? searchReq : null);

  useEffect(() => {
    if (showResults) {
      setSearchStateReq(searchReq);
    }
  }, [searchReq, showResults]);

  // const searchStateReqUrl = useMemo(
  //   () => (searchStateReq ? stringifySearchReq(searchStateReq) : ''),
  //   [searchStateReq]
  // );

  const searchResultReq = useMemo(() => searchStateReq, [searchStateReq]);
  // const searchResultUrl = useMemo(
  //   () => (isDesktop ? searchStateReqUrl : searchReqUrl),
  //   [isDesktop, searchReqUrl, searchStateReqUrl]
  // );

  // Handlers
  // useEffect(() => {
  //   if (typeof searchCount === 'number' && searchCount <= 0)
  //     setSearchStateReq(null);
  // }, [searchCount]);

  const onResetClick = () => {
    setReq(defaultSearchReq);
    searchStore.resetValues();
    // navigate(pathname, { replace: true });
  };

  const onSearchClick = () => {
    // const url = stringifySearchReq({
    //   ...searchReq,
    //   auto_filter: mapAutoFilters(searchStore.autoFilters, true)
    // });

    // const newUrl = `${pathname}?${url}`;
    // navigate(newUrl, { replace: true });
    setSearchStateReq(searchReq);
    // scrollToSection(SEARCH_RESULT_ID);
  };

  useEffect(() => {
    if (showResults) {
      onSearchClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showResults]);

  // Search contexts
  const searchSelects = useSearchSelect();

  return (
    <SearchSelectContext.Provider value={searchSelects}>
      <div className={cls.root}>
        <div className={cls.container}>
          <div className={cls.tools}>
            <Button
              disabled={allSelectedParamsCount <= 0}
              onClick={onResetClick}
            >
              {t('common.reset')}
            </Button>
            <Button
              icon={<FilterOutlined />}
              onClick={() => toggleHideFilters(!hideFilters)}
            >
              {t(hideFilters ? 'showFilter' : 'hideFilter')}
            </Button>
          </div>

          <SearchDesktop
            initialReq={req}
            brands={brands}
            types={types}
            colors={colors}
            emirates={emirates}
            regSpecs={regSpecs}
            years={years?.years || []}
            yearsLoading={yearsLoading}
            brokenParams={brokenParams}
            pubDays={pubDays}
            hideFilters={hideFilters}
          />

          <div id={SEARCH_RESULT_ID} className={cls.results}>
            {searchResultReq && (
              <SearchResult
                // key={searchResultUrl}
                req={searchResultReq}
                sortFilters={sortFilters}
                // showSort={showSort}
              />
            )}
          </div>
        </div>
      </div>
    </SearchSelectContext.Provider>
  );
}

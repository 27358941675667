import { Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { smartAutoFiltersIds } from '@/modules/search/helpers';
import { useSearchStore } from '@/modules/search/store';
import { useTransportTransmissions } from '@/modules/transport/hooks';
import { GetTransportTransmissionsReq } from '@/modules/transport/types';
import { MAX_PG_LIMIT } from '@/utils/consts';
import { Nullable } from '@/utils/types';

type Props = {
  initialIds: number[];
};

export function TransmissionsSelect({ initialIds }: Props) {
  const { t } = useTranslation();

  const autoFilters = useSearchStore((s) => s.autoFilters);
  const transmissionsIds = useSearchStore((s) => s.transmissionsIds);
  const setTransmissionsIds = useSearchStore((s) => s.setTransmissionsIds);

  const [shouldLoadTransmissions, setShouldLoadTransmissions] = useState(
    initialIds.length > 0
  );

  const req = useMemo<Nullable<GetTransportTransmissionsReq>>(() => {
    if (!shouldLoadTransmissions) return null;
    const ids = smartAutoFiltersIds(autoFilters);
    return { limit: MAX_PG_LIMIT, ...ids };
  }, [autoFilters, shouldLoadTransmissions]);
  const [transmissions] = useTransportTransmissions(req);

  const options = useMemo(
    () =>
      transmissions
        ? transmissions.map((e) => ({ value: e.id, label: e.name }))
        : [],
    [transmissions]
  );

  useEffect(() => {
    if (!transmissionsIds && initialIds.length > 0) {
      setTransmissionsIds(initialIds);
    }
  }, [transmissionsIds, initialIds, setTransmissionsIds]);

  return (
    <div>
      <Select
        options={options}
        value={transmissionsIds || []}
        onChange={setTransmissionsIds}
        placeholder={t('transmissionShort')}
        onFocus={() => setShouldLoadTransmissions(true)}
        mode="multiple"
        onClear={() => setTransmissionsIds([])}
        allowClear
        size="large"
        style={{ width: '100%' }}
      />
    </div>
  );
}

import { Select } from 'antd';
import { useEffect, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchSelectContext } from '@/modules/search/search-select-ctx';
import { useSearchStore } from '@/modules/search/store';
import { BrokenParam } from '@/modules/search/types';
import { Nullable } from '@/utils/types';

type Props = {
  initialId: Nullable<number>;
  params: BrokenParam[];
};

export function BrokenParamsSelect({ initialId, params }: Props) {
  const { t } = useTranslation();

  const brokenParamId = useSearchStore((s) => s.brokenParamId);
  const setBrokenParamId = useSearchStore((s) => s.setBrokenParamId);
  const { brokenParamsSetted, setBrokenParamsSetted } =
    useContext(SearchSelectContext);

  const options = useMemo(
    () => params.map((e) => ({ value: e.id, label: e.name })),
    [params]
  );

  const onChange = (id: number) => {
    setBrokenParamsSetted(true);
    setBrokenParamId(id);
  };

  const onClear = () => {
    setBrokenParamsSetted(true);
    setBrokenParamId(null);
  };

  useEffect(() => {
    if (!brokenParamId && initialId && !brokenParamsSetted) {
      setBrokenParamsSetted(true);
      setBrokenParamId(initialId);
    }
  }, [
    brokenParamId,
    initialId,
    brokenParamsSetted,
    setBrokenParamId,
    setBrokenParamsSetted
  ]);

  return (
    <div>
      <Select
        options={options}
        value={brokenParamId}
        onChange={onChange}
        placeholder={t('condition')}
        onClear={onClear}
        allowClear
        size="large"
        style={{ width: '100%' }}
      />
    </div>
  );
}

import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { YearSelect } from '@/modules/search/SearchAdverts/SearchCars/YearSelect';
import { useSearchStore } from '@/modules/search/store';
import { TransportYear } from '@/modules/transport/types';
import { Nullable } from '@/utils/types';

type Props = {
  initialId: Nullable<number>;
  allYears: TransportYear[];
  yearsLoading: boolean;
};

export function YearMin({ initialId, allYears, yearsLoading }: Props) {
  const { t } = useTranslation();

  const yearMaxId = useSearchStore((s) => s.yearMaxId);
  const yearMinId = useSearchStore((s) => s.yearMinId);
  const setYearMinId = useSearchStore((s) => s.setYearMinId);

  const filteredYears = useMemo(() => {
    if (yearMaxId) {
      const maxYear = allYears.find((y) => y.id === yearMaxId);
      if (!maxYear) return allYears;
      const maxYearNum = Number(maxYear.name);
      if (!maxYearNum || Number.isNaN(maxYearNum)) return allYears;

      return allYears.filter((y) => {
        const yearNum = Number(y.name);
        if (yearNum && !Number.isNaN(yearNum)) {
          return yearNum <= maxYearNum;
        }

        return true;
      });
    }

    return allYears;
  }, [allYears, yearMaxId]);

  useEffect(() => {
    if (!yearMinId && initialId) {
      setYearMinId(initialId);
    }
  }, [yearMinId, initialId, setYearMinId]);

  return (
    <YearSelect
      allYears={filteredYears}
      yearsLoading={yearsLoading}
      selectedId={yearMinId}
      placeholder={t('yearShortFrom')}
      onChange={setYearMinId}
      onClear={() => setYearMinId(null)}
    />
  );
}

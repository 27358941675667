import { Input, Modal, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UploadRes } from '@/modules/files/types';
import { Uploader } from '@/modules/files/Uploader/Uploader';
import { useBodyChildsPg } from '@/modules/transport/hooks';
import { MAX_PG_LIMIT } from '@/utils/consts';
import { onlyNumbers } from '@/utils/format';
import { Nullable } from '@/utils/types';

import cls from './BodyForm.module.scss';

export type FormType = {
  photo_url: string;
  count_doors: number;
  body_child_id: number;
};

type Props = {
  title: string;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  initialData?: FormType;
  onSave: (form: FormType) => void;
  loading?: boolean;
};

export function BodyForm({
  title,
  isOpen,
  setOpen,
  initialData,
  onSave,
  loading
}: Props) {
  const { t } = useTranslation();

  // Image
  const [uploading, setUploading] = useState(false);
  const [src, setSrc] = useState(initialData?.photo_url || '');
  const onSrcUpload = (res: UploadRes) => {
    setSrc(res.url);
  };

  // Doors
  const [doorsCount, setDoorsCount] = useState(initialData?.count_doors || 0);

  // Child
  const [childId, setChildId] = useState<Nullable<number>>(
    initialData?.body_child_id || null
  );

  const req = useMemo(() => {
    return {
      skip: 0,
      limit: MAX_PG_LIMIT
    };
  }, []);
  const [bodyChilds, , bodyChildsLoading] = useBodyChildsPg(req);
  const bodyOptions = bodyChilds.map((b) => ({ value: b.id, label: b.name }));

  const close = () => {
    setOpen(false);
    setSrc('');
    setDoorsCount(0);
    setChildId(null);
  };

  useEffect(() => {
    if (initialData) {
      setSrc(initialData.photo_url);
      setDoorsCount(initialData.count_doors);
      setChildId(initialData.body_child_id);
    }
  }, [initialData]);

  const isValid = !!src && !!childId;
  const onOk = () => {
    if (isValid)
      onSave({
        photo_url: src,
        count_doors: doorsCount,
        body_child_id: childId
      });
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      okText={t('common.save')}
      cancelText={t('common.cancel')}
      onCancel={close}
      onOk={onOk}
      okButtonProps={{ disabled: loading || !isValid }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Uploader
        type="image"
        src={src}
        loading={uploading}
        setLoading={setUploading}
        onUpload={onSrcUpload}
      />
      <p className={cls.label}>{t('numberOfDoors')}</p>
      <Input
        value={doorsCount}
        onChange={(e) =>
          setDoorsCount(Number(onlyNumbers(e.currentTarget.value)))
        }
        size="large"
      />
      <p className={cls.label}>{t('bodyChild')}</p>
      <Select
        size="large"
        value={childId}
        onChange={setChildId}
        options={bodyOptions}
        style={{ width: '100%' }}
        loading={bodyChildsLoading}
      />
    </Modal>
  );
}

import { Select } from 'antd';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSearchStore } from '@/modules/search/store';
import { TransportColor } from '@/modules/transport/types';

type Props = {
  initialIds: number[];
  colors: TransportColor[];
};

export function ColorsSelect({ initialIds, colors }: Props) {
  const { t } = useTranslation();

  const colorsIds = useSearchStore((s) => s.colorsIds);
  const setColorsIds = useSearchStore((s) => s.setColorsIds);

  const options = useMemo(
    () => colors.map((e) => ({ value: e.id, label: e.name })),
    [colors]
  );

  useEffect(() => {
    if (!colorsIds && initialIds.length > 0) {
      setColorsIds(initialIds);
    }
  }, [colorsIds, initialIds, setColorsIds]);

  return (
    <div>
      <Select
        options={options}
        value={colorsIds || []}
        onChange={setColorsIds}
        placeholder={t('color')}
        mode="multiple"
        onClear={() => setColorsIds([])}
        allowClear
        size="large"
        style={{ width: '100%' }}
      />
    </div>
  );
}

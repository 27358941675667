import { getCookie } from '@/utils/cookie';

import { AuthType } from './types';

export const APP_AUTH_NAME = 'autoae-admin-auth-token';

// Get auth from cookie
export function getCookieAuth(): AuthType | undefined {
  let auth: AuthType | undefined;
  const authInCookie = getCookie(APP_AUTH_NAME);

  if (authInCookie) {
    const parsed = JSON.parse(authInCookie) as AuthType;
    if (parsed) {
      auth = parsed;
    }
  }

  return auth;
}

import { Input, Modal, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PromptType } from '@/modules/prompts/types';

export type FormType = PromptType;

type Props = {
  title: string;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  initialData: FormType;
  onSave: (form: { id: number; prompt: string }) => void;
  loading?: boolean;
};

export function PromptForm({
  title,
  isOpen,
  setOpen,
  initialData,
  onSave,
  loading
}: Props) {
  const { t } = useTranslation();

  const [id, setId] = useState(initialData.prompt_id);
  const [text, setText] = useState(initialData.prompt || '');
  const close = () => {
    setOpen(false);
    setId(0);
    setText('');
  };

  useEffect(() => {
    setId(initialData.prompt_id);
    setText(initialData.prompt || '');
  }, [initialData]);

  const isValid = !!text;

  const onOk = () => {
    onSave({
      id: id,
      prompt: text
    });
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      okText={t('common.save')}
      cancelText={t('common.cancel')}
      onCancel={close}
      onOk={onOk}
      okButtonProps={{ disabled: loading || !isValid }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <p>{t('prompts.text')}</p>
          <Input.TextArea
            value={text}
            onChange={(e) => setText(e.currentTarget.value)}
            size="large"
          />
        </div>
      </Space>
    </Modal>
  );
}

import {
  ExclamationCircleFilled,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { Button, Typography, Space, Modal, Card } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCommonStore } from '@/app/common-store';
import { deleteGeneration, updateGeneration } from '@/modules/transport/api';
import { GenerationImage } from '@/modules/transport/generations/GenerationsList/GenerationImage';
import { useGenerationsPg } from '@/modules/transport/hooks';
import { TransportGeneration } from '@/modules/transport/types';
import { MAX_PG_LIMIT } from '@/utils/consts';
import { showAlert } from '@/utils/network';
import { useQuery } from '@/utils/query';
import { Nullable } from '@/utils/types';

import { GenerationForm, FormType } from './GenerationForm/GenerationForm';
import cls from './GenerationsList.module.scss';

const { confirm } = Modal;

const skeletons = new Array(3).fill(0);

type Props = {
  modelId: number;
  modelName: string;
};

function GenerationsList({ modelId, modelName }: Props) {
  const { t } = useTranslation();
  const crumbModelName = useCommonStore((s) => s.crumbs.modelName);
  const setCrumbGeneration = useCommonStore((s) => s.setCrumbGeneration);

  // Data
  const req = useMemo(() => {
    return {
      model_id: modelId,
      skip: 0,
      limit: MAX_PG_LIMIT
    };
  }, [modelId]);

  const [data, , isLoading, fetchGenerations] = useGenerationsPg(req);

  const refreshData = useCallback(() => {
    fetchGenerations();
  }, [fetchGenerations]);

  // Add
  const [isAdding] = useState<boolean>(false);
  const [isAddOpen, setAddOpen] = useState<boolean>(false);
  const onAddClick = () => {
    setAddOpen(true);
  };
  const onAddSave = (form: FormType) => {
    // todo: create new generation
    console.log(form);
  };

  // Edit
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const [selectedGeneration, setSelectedGeneration] =
    useState<Nullable<TransportGeneration>>(null);
  const onEditClick = (generation: TransportGeneration) => {
    setSelectedGeneration(generation);
    setEditOpen(true);
  };
  const onEditSave = async ({ year_from, year_to, ...form }: FormType) => {
    if (!selectedGeneration) return selectedGeneration;

    setEditing(true);
    try {
      await updateGeneration({
        ...form,
        generation_id: selectedGeneration.id,
        year_from: year_from ? Number(year_from) : null,
        year_to: year_to ? Number(year_to) : null
      });
      setSelectedGeneration(null);
      setEditOpen(false);
      refreshData();
    } catch (error) {
      showAlert({ error });
    } finally {
      setEditing(false);
    }
  };

  // Delete
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const makeGenerationDelete = useCallback(
    (generation_id: number) => {
      return new Promise<void>((resolve, reject) => {
        setDeleting(true);

        deleteGeneration({ generation_id })
          .then(() => {
            refreshData();
            resolve();
          })
          .catch((error) => {
            showAlert({ error });
            reject();
          })
          .finally(() => {
            setDeleting(false);
          });
      });
    },
    [refreshData]
  );

  const showDeleteConfirm = useCallback(
    (generation: TransportGeneration) => {
      confirm({
        title: t('common.deleteConfirm'),
        icon: <ExclamationCircleFilled />,
        content: generation.name,
        okText: t('common.delete'),
        cancelText: t('common.cancel'),
        onOk() {
          return makeGenerationDelete(generation.id);
        }
      });
    },
    [makeGenerationDelete, t]
  );
  const onDeleteClick = useCallback(
    (generation: TransportGeneration) => {
      setEditOpen(false);
      showDeleteConfirm(generation);
    },
    [showDeleteConfirm]
  );

  return (
    <div>
      <Space className="table-header">
        <Typography.Title>
          {crumbModelName || modelName
            ? t('modelGenerations', { model: crumbModelName || modelName })
            : t('generations')}
        </Typography.Title>
        <Button onClick={onAddClick} type="primary">
          {t('common.add')}
        </Button>
      </Space>

      {data.length > 0 && !isLoading && (
        <ul className={cls.list}>
          {data.map((g) => (
            <li key={g.id}>
              <Card
                cover={
                  <GenerationImage
                    to={`/db/bodies?generation_id=${g.id}`}
                    onClick={() => {
                      setCrumbGeneration(
                        g.name,
                        `/db/generations?model_id=${modelId}`
                      );
                    }}
                    src={g.photo_url}
                  />
                }
                actions={[
                  <Button
                    key="edit"
                    onClick={() => onEditClick(g)}
                    disabled={isEditing || isDeleting}
                    icon={<EditOutlined />}
                    type="link"
                  />,
                  <Button
                    key="delete"
                    onClick={() => onDeleteClick(g)}
                    disabled={isEditing || isDeleting}
                    icon={<DeleteOutlined />}
                    type="link"
                    danger
                  />
                ]}
              >
                <Card.Meta
                  title={g.name}
                  description={
                    g.year_from && g.year_to
                      ? `${g.year_from} - ${g.year_to}`
                      : g.year_from || g.year_to
                  }
                />
              </Card>
            </li>
          ))}
        </ul>
      )}

      {isLoading && (
        <ul className={cls.list}>
          {skeletons.map((_, i) => (
            <li key={i}>
              <Card
                loading
                cover={<GenerationImage to="/db" />}
                actions={[
                  <Button
                    key="edit"
                    disabled
                    icon={<EditOutlined />}
                    type="link"
                  />,
                  <Button
                    key="delete"
                    disabled
                    icon={<DeleteOutlined />}
                    type="link"
                    danger
                  />
                ]}
              />
            </li>
          ))}
        </ul>
      )}

      <GenerationForm
        title={t('common.add')}
        isOpen={isAddOpen}
        setOpen={setAddOpen}
        onSave={onAddSave}
        loading={isAdding}
      />

      {selectedGeneration && (
        <GenerationForm
          title={t('common.change')}
          isOpen={isEditOpen}
          setOpen={setEditOpen}
          onSave={onEditSave}
          loading={isEditing}
          initialData={{
            name: selectedGeneration.name,
            photo_url: selectedGeneration.photo_url || '',
            year_from: selectedGeneration.year_from || '',
            year_to: selectedGeneration.year_to || '',
            is_restyle: !!selectedGeneration.is_restyle
          }}
        />
      )}
    </div>
  );
}

export function GenerationsListWrap() {
  const query = useQuery();
  const modelId = useMemo(() => {
    if (query.model_id) {
      const num = Number(query.model_id);
      if (!Number.isNaN(num)) return num;
    }
    return null;
  }, [query.model_id]);
  const modelName = useMemo(() => query.model_name || '', [query.model_name]);

  return modelId ? (
    <GenerationsList modelId={modelId} modelName={modelName as string} />
  ) : (
    <></>
  );
}

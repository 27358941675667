import { Input, Modal, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UploadRes } from '@/modules/files/types';
import { Uploader } from '@/modules/files/Uploader/Uploader';
import {
  PromotionOption,
  UpdatePromotionPackageReq
} from '@/modules/promotion/types';
import { onlyNumbers } from '@/utils/format';
import { Nullable } from '@/utils/types';

import cls from './PackageForm.module.scss';

export type FormType = UpdatePromotionPackageReq;

type Props = {
  title: string;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  initialData?: FormType;
  onSave: (form: FormType) => void;
  fullOptions: PromotionOption[];
  loading?: boolean;
};

export function PackageForm({
  title,
  isOpen,
  setOpen,
  initialData,
  onSave,
  fullOptions,
  loading
}: Props) {
  const { t } = useTranslation();

  const [uploading, setUploading] = useState(false);
  const [src, setSrc] = useState(initialData?.logo_url || '');
  const onSrcUpload = (res: UploadRes) => {
    setSrc(res.url);
  };

  const [name, setName] = useState(initialData?.name || '');
  const [price, setPrice] = useState(
    initialData?.price ? String(initialData?.price) : ''
  );
  const [oldPrice, setOldPrice] = useState(
    initialData?.old_price ? String(initialData?.old_price) : ''
  );
  const [validDays, setValidDays] = useState(
    initialData?.valid_days ? String(initialData?.valid_days) : ''
  );
  const fullOptionsList = fullOptions.map((opt) => ({
    label: opt.name,
    value: opt.id
  }));
  const [options, setOptions] = useState<Nullable<number[]>>(
    initialData?.options
      ? initialData.options.filter((opt) => opt.include).map((opt) => opt.id)
      : null
  );

  const close = () => {
    setOpen(false);
    setSrc('');
    setName('');
    setPrice('');
    setOldPrice('');
    setValidDays('');
    setOptions(null);
  };

  useEffect(() => {
    if (initialData && isOpen) {
      setSrc(initialData.logo_url || '');
      setName(initialData.name || '');
      setPrice(initialData.price ? String(initialData.price) : '');
      setOldPrice(initialData.old_price ? String(initialData.old_price) : '');
      setValidDays(
        initialData.valid_days ? String(initialData.valid_days) : ''
      );
      setOptions(
        initialData.options
          ? initialData.options
              .filter((opt) => opt.include)
              .map((opt) => opt.id)
          : null
      );
    }
  }, [initialData, isOpen]);

  const isValid = !!name && !!price;

  const onOk = () => {
    onSave({
      name,
      options: options
        ? fullOptions.map((opt) => {
            return { ...opt, include: options.includes(opt.id) };
          })
        : null,
      logo_url: src,
      price: Number(price),
      old_price: Number(oldPrice),
      valid_days: Number(validDays)
    });
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      okText={t('common.save')}
      cancelText={t('common.cancel')}
      onCancel={close}
      onOk={onOk}
      okButtonProps={{ disabled: loading || !isValid }}
      cancelButtonProps={{ disabled: loading }}
    >
      <div className={cls.uploads}>
        <Uploader
          type="image"
          src={src}
          loading={uploading}
          setLoading={setUploading}
          onUpload={onSrcUpload}
        />
      </div>
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <p>{t('name')}</p>
          <Input
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            size="large"
          />
        </div>
        <div>
          <p>{t('price')}</p>
          <Input
            value={price}
            onChange={(e) => setPrice(onlyNumbers(e.currentTarget.value))}
            size="large"
            inputMode="numeric"
          />
        </div>
        <div>
          <p>{t('oldPrice')}</p>
          <Input
            value={oldPrice}
            onChange={(e) => setOldPrice(onlyNumbers(e.currentTarget.value))}
            size="large"
            inputMode="numeric"
          />
        </div>
        <div>
          <p>{t('validDays')}</p>
          <Input
            value={validDays}
            onChange={(e) => setValidDays(onlyNumbers(e.currentTarget.value))}
            size="large"
            inputMode="numeric"
          />
        </div>
        <div>
          <p>{t('options')}</p>
          <Select
            mode="tags"
            style={{ width: '100%' }}
            value={options}
            onChange={setOptions}
            options={fullOptionsList}
          />
        </div>
      </Space>
    </Modal>
  );
}

import { Outlet } from 'react-router-dom';

import { BaseLayout } from '@/ui/layouts/BaseLayout/BaseLayout';

export function RootLayout() {
  return (
    <BaseLayout>
      <Outlet />
    </BaseLayout>
  );
}

import { Input } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { MIN_TIME_TO_100, MAX_TIME_TO_100 } from '@/modules/search/helpers';
import { useSearchStore } from '@/modules/search/store';
import { numberInRange, numberWithSpaces } from '@/utils/format';

type Props = {
  initialMin: number;
  initialMax: number;
};

export function TimeToHundred({ initialMin, initialMax }: Props) {
  const { t } = useTranslation();

  const min = useSearchStore((s) => s.timeTo100Min);
  const setMin = useSearchStore((s) => s.setTimeTo100Min);
  const max = useSearchStore((s) => s.timeTo100Max);
  const setMax = useSearchStore((s) => s.setTimeTo100Max);

  useEffect(() => {
    if (min === null && initialMin) {
      setMin(initialMin);
    }
  }, [initialMin, min, setMin]);

  useEffect(() => {
    if (max === null && initialMax) {
      setMax(initialMax);
    }
  }, [initialMax, max, setMax]);

  const handleValue = (e: React.FormEvent<HTMLInputElement>) =>
    Number(
      numberInRange(e.currentTarget.value, MIN_TIME_TO_100, MAX_TIME_TO_100)
    );

  return (
    <>
      <Input
        value={min ? numberWithSpaces(min) : ''}
        onChange={(e) => setMin(handleValue(e))}
        placeholder={t('timeTo100From') as string}
        inputMode="numeric"
        size="large"
      />
      <Input
        value={max ? numberWithSpaces(max) : ''}
        onChange={(e) => setMax(handleValue(e))}
        placeholder={t('to') as string}
        inputMode="numeric"
        size="large"
      />
    </>
  );
}

import { Select } from 'antd';
import { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { MAX_VRP_NUMBER_LEN } from '@/modules/search/helpers';
import { SearchSelectContext } from '@/modules/search/search-select-ctx';
import { useSearchStore } from '@/modules/search/store';
import { Nullable } from '@/utils/types';

const options = new Array(MAX_VRP_NUMBER_LEN).fill(null).map((_, i) => ({
  id: String(++i),
  text: String(i)
}));

type Props = {
  initialId: Nullable<number>;
};

export function PlateNumberLengthSelect({ initialId }: Props) {
  const { t } = useTranslation();

  const vrpPlateNumberLen = useSearchStore((s) => s.vrpPlateNumberLen);
  const setVrpPlateNumberLen = useSearchStore((s) => s.setVrpPlateNumberLen);
  const { plateNumberLenSetted, setPlateNumberLenSetted } =
    useContext(SearchSelectContext);

  const onChange = (v: number) => {
    setPlateNumberLenSetted(true);
    setVrpPlateNumberLen(v);
  };

  const onClear = () => {
    setPlateNumberLenSetted(true);
    setVrpPlateNumberLen(null);
  };

  useEffect(() => {
    if (!vrpPlateNumberLen && initialId !== null && !plateNumberLenSetted) {
      setPlateNumberLenSetted(true);
      setVrpPlateNumberLen(initialId);
    }
  }, [
    vrpPlateNumberLen,
    initialId,
    setVrpPlateNumberLen,
    plateNumberLenSetted,
    setPlateNumberLenSetted
  ]);

  return (
    <Select
      options={options}
      value={vrpPlateNumberLen}
      placeholder={t('numberLength')}
      onChange={onChange}
      onClear={onClear}
      allowClear
      size="large"
      style={{ width: '100%' }}
    />
  );
}

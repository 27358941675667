import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useMemo } from 'react';

import { useEmirates } from '@/modules/emirates/api';
import { useRegSpecs } from '@/modules/regional-specs/api';
import {
  useBrokenParams,
  usePubDays,
  useSearchReq,
  useSortFilters
} from '@/modules/search/hooks';
import { SearchCars } from '@/modules/search/SearchAdverts/SearchCars/SearchCars';
import {
  useBrandsPg,
  useTransportColors,
  useTransportTypes
} from '@/modules/transport/hooks';
import { MAX_PG_LIMIG_REQ } from '@/utils/consts';

type Props = {
  categoryId: number;
  hideFilters: boolean;
  toggleHideFilters: (v: boolean) => void;
  showResults?: boolean;
};

export function SearchCarsWrap({
  categoryId,
  hideFilters,
  toggleHideFilters,
  showResults: showResultsProp
}: Props) {
  const [req, showResults] = useSearchReq();

  const [brands, , brandsLoading] = useBrandsPg(MAX_PG_LIMIG_REQ);
  const [types, typesLoading] = useTransportTypes(MAX_PG_LIMIG_REQ);
  const [colors, colorsLoading] = useTransportColors(MAX_PG_LIMIG_REQ);
  const [emirates, emiratesLoading] = useEmirates(MAX_PG_LIMIG_REQ);
  const [regSpecs, regSpecsLoading] = useRegSpecs(MAX_PG_LIMIG_REQ);
  const [pubDays, pubDaysLoading] = usePubDays(MAX_PG_LIMIG_REQ);
  const [brokenParams, brokenParamsLoading] = useBrokenParams(MAX_PG_LIMIG_REQ);
  const sortReq = useMemo(
    () => ({
      ...MAX_PG_LIMIG_REQ,
      category_id: categoryId
    }),
    [categoryId]
  );
  const [sortFilters, sortFiltersLoading] = useSortFilters(sortReq);
  const loading =
    brandsLoading ||
    typesLoading ||
    colorsLoading ||
    emiratesLoading ||
    regSpecsLoading ||
    pubDaysLoading ||
    brokenParamsLoading ||
    sortFiltersLoading;

  if (loading) return <Spin indicator={<LoadingOutlined />} />;

  return (
    <SearchCars
      req={req}
      showResults={showResults || !!showResultsProp}
      brands={brands || []}
      types={types || []}
      colors={colors || []}
      emirates={emirates || []}
      regSpecs={regSpecs || []}
      pubDays={pubDays || []}
      brokenParams={brokenParams || []}
      sortFilters={sortFilters || []}
      hideFilters={hideFilters}
      toggleHideFilters={toggleHideFilters}
    />
  );
}

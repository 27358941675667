import { Input } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { MAX_HORSEPOWER, MIN_HORSEPOWER } from '@/modules/search/helpers';
import { useSearchStore } from '@/modules/search/store';
import { numberInRange, numberWithSpaces } from '@/utils/format';

type Props = {
  initialMin: number;
  initialMax: number;
};

export function HorsePower({ initialMin, initialMax }: Props) {
  const { t } = useTranslation();

  const min = useSearchStore((s) => s.horsePowerMin);
  const setMin = useSearchStore((s) => s.setHorsePowerMin);
  const max = useSearchStore((s) => s.horsePowerMax);
  const setMax = useSearchStore((s) => s.setHorsePowerMax);

  useEffect(() => {
    if (min === null && initialMin) {
      setMin(initialMin);
    }
  }, [initialMin, min, setMin]);

  useEffect(() => {
    if (max === null && initialMax) {
      setMax(initialMax);
    }
  }, [initialMax, max, setMax]);

  const handleValue = (e: React.FormEvent<HTMLInputElement>) =>
    Number(
      numberInRange(e.currentTarget.value, MIN_HORSEPOWER, MAX_HORSEPOWER)
    );

  return (
    <>
      <Input
        value={min || min === 0 ? numberWithSpaces(min) : ''}
        onChange={(e) => setMin(handleValue(e))}
        placeholder={t('horsepowerFrom') as string}
        inputMode="numeric"
        size="large"
      />
      <Input
        value={max || max === 0 ? numberWithSpaces(max) : ''}
        onChange={(e) => setMax(handleValue(e))}
        placeholder={t('to') as string}
        inputMode="numeric"
        size="large"
      />
    </>
  );
}

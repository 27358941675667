import { FilterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '@/hooks/useDebounce';
import { EmirateType } from '@/modules/emirates/types';
import { DEBOUNCE_TIME, SEARCH_RESULT_ID } from '@/modules/search/consts';
import {
  calcVrpSelectedParamsCount,
  storeToSearchReq,
  defaultSearchReq
} from '@/modules/search/helpers';
import {
  useSearchSelect,
  SearchSelectContext
} from '@/modules/search/search-select-ctx';
import { SearchPlatesDesktop } from '@/modules/search/SearchAdverts/SearchPlates/SearchPlatesDesktop';
import { SearchResult } from '@/modules/search/SearchAdverts/SearchResult/SearchResult';
import { useSearchStore } from '@/modules/search/store';
import { SearchAdvertsReq, PubDay, SortFilter } from '@/modules/search/types';
import { Nullable } from '@/utils/types';

import cls from '../SearchCars/SearchCars.module.scss';

type Props = {
  req: SearchAdvertsReq;
  showResults: boolean;
  emirates: EmirateType[];
  pubDays: PubDay[];
  sortFilters: SortFilter[];
  hideFilters: boolean;
  toggleHideFilters: (v: boolean) => void;
};

export function SearchPlates({
  req: initialReq,
  showResults,
  emirates,
  pubDays,
  sortFilters,
  hideFilters,
  toggleHideFilters
}: Props) {
  const { t } = useTranslation();

  const searchStore = useSearchStore((s) => s);
  const debouncedStore = useDebounce(searchStore, DEBOUNCE_TIME);
  const selectedParamsCount = useMemo(
    () => calcVrpSelectedParamsCount(searchStore),
    [searchStore]
  );

  const [req, setReq] = useState(initialReq);

  // Search
  const searchReq = useMemo(
    () => storeToSearchReq(debouncedStore),
    [debouncedStore]
  );
  // const searchReqUrl = useMemo(
  //   () => stringifySearchReq(searchReq),
  //   [searchReq]
  // );

  const [searchStateReq, setSearchStateReq] = useState<
    Nullable<SearchAdvertsReq>
  >(showResults ? searchReq : null);

  useEffect(() => {
    if (showResults) {
      setSearchStateReq(searchReq);
    }
  }, [searchReq, showResults]);

  // const searchStateReqUrl = useMemo(
  //   () => (searchStateReq ? stringifySearchReq(searchStateReq) : ''),
  //   [searchStateReq]
  // );

  const searchResultReq = useMemo(() => searchStateReq, [searchStateReq]);
  // const searchResultUrl = useMemo(
  //   () => (isDesktop ? searchStateReqUrl : searchReqUrl),
  //   [isDesktop, searchReqUrl, searchStateReqUrl]
  // );
  // Handlers
  // useEffect(() => {
  //   if (typeof searchCount === 'number' && searchCount <= 0)
  //     setSearchStateReq(null);
  // }, [searchCount]);

  const onResetClick = () => {
    setReq(defaultSearchReq);
    searchStore.resetValues();
    // navigate(pathname, { replace: true });
  };

  const onSearchClick = () => {
    // const url = stringifySearchReq(searchReq);
    // const newUrl = `${pathname}?${url}`;
    // navigate(newUrl, { replace: true });
    setSearchStateReq(searchReq);
    // scrollToSection(SEARCH_RESULT_ID);
  };

  useEffect(() => {
    if (showResults) {
      onSearchClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showResults]);

  // Search contexts
  const searchSelects = useSearchSelect();

  return (
    <SearchSelectContext.Provider value={searchSelects}>
      <div className={cls.root}>
        <div className={cls.container}>
          <div className={cls.tools}>
            <Button disabled={selectedParamsCount <= 0} onClick={onResetClick}>
              {t('common.reset')}
            </Button>
            <Button
              icon={<FilterOutlined />}
              onClick={() => toggleHideFilters(!hideFilters)}
            >
              {t(hideFilters ? 'showFilter' : 'hideFilter')}
            </Button>
          </div>

          <SearchPlatesDesktop
            initialReq={req}
            emirates={emirates}
            pubDays={pubDays}
            hideFilters={hideFilters}
          />

          <div id={SEARCH_RESULT_ID} className={cls.results}>
            {searchResultReq && (
              <SearchResult
                // key={searchResultUrl}
                req={searchResultReq}
                sortFilters={sortFilters}
                // showSort={showSort}
              />
            )}
          </div>
        </div>
      </div>
    </SearchSelectContext.Provider>
  );
}

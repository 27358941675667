import { AxiosError } from 'axios';

import { AlertType, useAlertStore } from '@/ui/Alert/alert-store';

type ErrorType = AxiosError | string;

function getErrorText(error: ErrorType): string {
  const isString = typeof error === 'string';
  if (error && isString) {
    return error;
  }

  const isAxiosError =
    typeof error === 'object' &&
    'isAxiosError' in error &&
    error.isAxiosError &&
    error.response;

  if (isAxiosError) {
    const responseData = error?.response?.data;
    if (responseData && typeof responseData === 'string') return responseData;

    const isApiError =
      !!responseData &&
      typeof responseData === 'object' &&
      'message' in responseData;
    if (isApiError) {
      const resData = responseData as { message: string };
      if (resData.message && typeof resData.message === 'string') {
        return resData.message;
      }
    }

    return error.message;
  }

  const isApiError = typeof error === 'object' && 'message' in error;
  if (error && isApiError) {
    if (error.message && typeof error.message === 'string') {
      return error.message;
    }
  }

  return 'Unknown error';
}

type AlertOptions = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  text?: string;
  type?: AlertType;
};

export function showAlert({ error, text, type }: AlertOptions): void {
  const content = error ? getErrorText(error) : text || '';
  const t = type || 'success';
  const alertType = error ? 'error' : t;

  useAlertStore.getState().show({ type: alertType, text: content });
}

export function getFormData(obj: Record<string, string | Blob>): FormData {
  const formData = new FormData();
  const keys = Object.keys(obj);

  keys.forEach((key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
      formData.append(key, obj[key]);
    }
  });

  return formData;
}

import { Select } from 'antd';
import { useEffect } from 'react';

import { AutoAPI } from '@/api/instance';
import { useCommonStore } from '@/app/common-store';
import { useLangs } from '@/modules/langs/hooks';

export function SwitchLang() {
  const [langsData, loading] = useLangs();
  const langs = langsData.map((l) => l.lang_code);
  const options = langs.map((l) => ({ value: l, label: l }));

  const langCode = useCommonStore((s) => s.langCode);
  const setLangCode = useCommonStore((s) => s.setLangCode);

  useEffect(() => {
    if (!langCode && langs.length > 0) {
      setLangCode(langs[0]);
    }
  }, [langCode, langs, setLangCode]);

  useEffect(() => {
    if (langCode) {
      AutoAPI.setLang(langCode);
    }
  }, [langCode]);

  return (
    <Select
      loading={loading}
      value={langCode}
      onChange={setLangCode}
      options={options}
    />
  );
}

import {
  CheckSquareOutlined,
  CloseSquareOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button, Descriptions, Space, Spin, Typography } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { moderateAdvert } from '@/modules/moderation/api';
import {
  APPROVED_ID,
  getAdvertName,
  REJECTED_ID
} from '@/modules/moderation/helpers';
import { useModerationAdvertDetail } from '@/modules/moderation/hooks';
import { RejectForm } from '@/modules/moderation/RejectForm';
import { ADV_CUR, APP_URL } from '@/utils/consts';
import { minutesToHHMM } from '@/utils/date';
import { numberWithSpaces } from '@/utils/format';
import { showAlert } from '@/utils/network';
import { parseNull } from '@/utils/parse';
import { Nullable } from '@/utils/types';

import cls from './ModerationAdvertDetail.module.scss';

const { Item } = Descriptions;

const VRP_CATEGORY_ID = 2;

type Props = {
  id: number;
};

function ModerationAdvertDetail({ id }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [detail, loading] = useModerationAdvertDetail(id);

  const isVrp = detail?.category.id === VRP_CATEGORY_ID;

  const title = useMemo(() => {
    if (!detail) return '';
    return getAdvertName(t, detail);
  }, [detail, t]);

  const coords = useMemo(() => {
    if (!detail) return '';

    const lat = detail.lat || '';
    const lon = detail.long || '';
    if (!lat && !lon) return '';

    return [lat, lon].join(', ');
  }, [detail]);

  // Moderate
  const [isChanging, setChanging] = useState(false);
  const [isRejectOpen, setRejectOpen] = useState(false);
  const [rejectAdId, setRejectAdId] = useState<Nullable<number>>(null);
  const [reasonText, setReasonText] = useState('');
  const changeStatus = useCallback(
    async (id: number, approved: boolean) => {
      setChanging(true);
      try {
        await moderateAdvert({
          status_id: approved ? APPROVED_ID : REJECTED_ID,
          advertisement_id: id,
          rejected_reason: approved ? null : reasonText
        });
        if (!approved) {
          setReasonText('');
          setRejectAdId(null);
        }
        navigate('/moderation');
      } catch (error) {
        showAlert({ error });
      } finally {
        setChanging(false);
      }
    },
    [navigate, reasonText]
  );

  const onRejectClick = (id: number) => {
    setRejectOpen(true);
    setRejectAdId(id);
  };
  const onReject = () => {
    setRejectOpen(false);
    if (rejectAdId) changeStatus(rejectAdId, false);
  };

  return (
    <div className={cls.root}>
      {loading && <Spin indicator={<LoadingOutlined />} />}

      {!loading && detail && (
        <div>
          <header className={cls.header}>
            <Typography.Title level={2}>{title}</Typography.Title>
            <Space>
              <Space>
                <Button
                  onClick={() => onRejectClick(detail.id)}
                  disabled={isChanging}
                  type="primary"
                  danger
                >
                  {t('reject')}
                </Button>
                <Button
                  onClick={() => changeStatus(detail.id, true)}
                  disabled={isChanging}
                  type="primary"
                >
                  {t('approve')}
                </Button>
              </Space>
            </Space>
          </header>

          {isVrp && detail.vrp_image_url && (
            <img className={cls.vrp_image} src={detail.vrp_image_url} alt="" />
          )}

          {!isVrp && detail.photos && detail.photos.length > 0 && (
            <ul className={cls.photos}>
              {detail.photos.map((p) => (
                <li key={p.photo_url}>
                  <a href={p.photo_url} target="_blank" rel="noreferrer">
                    <img src={p.photo_url} alt="" />
                  </a>
                </li>
              ))}
            </ul>
          )}

          <Descriptions bordered size="small">
            <Item span={3} label={t('description')}>
              {detail.description}
            </Item>
            <Item span={3} label={t('descriptionCode')}>
              {detail.description_language_code}
            </Item>
            <Item span={3} label={t('price')}>
              {detail.price
                ? `${numberWithSpaces(detail.price)} ${ADV_CUR}`
                : parseNull(detail.price)}
            </Item>
            <Item span={3} label={t('discountCredit')}>
              {detail.discount_credit
                ? `${numberWithSpaces(detail.discount_credit)} ${ADV_CUR}`
                : parseNull(detail.discount_credit)}
            </Item>
            <Item span={3} label={t('discountTradeIn')}>
              {detail.discount_trade_in
                ? `${numberWithSpaces(detail.discount_trade_in)} ${ADV_CUR}`
                : parseNull(detail.discount_trade_in)}
            </Item>
            <Item span={3} label={t('maximumDiscount')}>
              {detail.maximum_discount
                ? `${numberWithSpaces(detail.maximum_discount)} ${ADV_CUR}`
                : parseNull(detail.maximum_discount)}
            </Item>
            <Item span={3} label={t('availableTrade')}>
              <Button
                icon={
                  detail.available_trade ? (
                    <CheckSquareOutlined />
                  ) : (
                    <CloseSquareOutlined />
                  )
                }
                type="link"
                danger={!detail.available_trade}
                style={{ pointerEvents: 'none' }}
              />
            </Item>
            <Item span={3} label={t('tariff')}>
              {detail.tariff?.title}
            </Item>

            <Item span={3} label={t('id')}>
              {detail.id}
            </Item>
            <Item span={3} label={t('url')}>
              <a
                className={cls.link}
                href={`${APP_URL}/${detail.url}`}
                target="_blank"
                rel="noreferrer"
              >{`${APP_URL}/${detail.url}`}</a>
            </Item>
            <Item span={3} label={t('status')}>
              {detail.status.name}
            </Item>

            <Item span={3} label="account_type">
              {detail.account_type.name}
            </Item>
            <Item span={3} label="author_id">
              {detail.author_id}
            </Item>

            <Item span={3} label={t('authorName')}>
              <Space>
                {detail.author_avatar_url && (
                  <img
                    className={cls.avatar}
                    src={detail.author_avatar_url}
                    alt=""
                  />
                )}
                {detail.name}
              </Space>
            </Item>
            <Item span={3} label={t('email')}>
              {detail.email}
            </Item>
            <Item span={3} label={t('emirate')}>
              {detail.emirate?.name}
            </Item>
            <Item span={3} label={t('address')}>
              {detail.address}
            </Item>
            <Item span={3} label={t('coords')}>
              {coords}
            </Item>
            <Item span={3} label={t('snapshot')}>
              {detail.snapshot_url && (
                <img
                  className={cls.snapshot}
                  src={detail.snapshot_url}
                  alt=""
                />
              )}
            </Item>
            <Item span={3} label={t('callAfter')}>
              {detail.call_after ? minutesToHHMM(detail.call_after) : 0}
            </Item>
            <Item span={3} label={t('callBefore')}>
              {detail.call_before ? minutesToHHMM(detail.call_before) : 0}
            </Item>
            <Item span={3} label={t('availableChat')}>
              <Button
                icon={
                  detail.available_chat ? (
                    <CheckSquareOutlined />
                  ) : (
                    <CloseSquareOutlined />
                  )
                }
                type="link"
                danger={!detail.available_chat}
                style={{ pointerEvents: 'none' }}
              />
            </Item>
            <Item span={3} label={t('availablePhone')}>
              <Button
                icon={
                  detail.available_phone ? (
                    <CheckSquareOutlined />
                  ) : (
                    <CloseSquareOutlined />
                  )
                }
                type="link"
                danger={!detail.available_phone}
                style={{ pointerEvents: 'none' }}
              />
            </Item>

            {isVrp ? (
              <>
                <Item span={3} label={t('plateEmirate')}>
                  {detail.vrp_plate_emirate?.name}
                </Item>
                <Item span={3} label={t('plateType')}>
                  {detail.vrp_type?.name}
                </Item>
                <Item span={3} label={t('code')}>
                  {detail.vrp_plate_code?.name}
                </Item>
                <Item span={3} label={t('number')}>
                  {detail.vrp_plate_number}
                </Item>
              </>
            ) : (
              <>
                <Item span={3} label={t('category')}>
                  {detail.category.name}
                </Item>
                <Item span={3} label={t('type')}>
                  {detail.type?.name}
                </Item>
                <Item span={3} label={t('brand')}>
                  {detail.brand?.name}
                </Item>
                <Item span={3} label={t('model')}>
                  {detail.model?.name}
                </Item>
                <Item span={3} label={t('generation')}>
                  {detail.generation?.name}
                </Item>
                <Item span={3} label={t('year')}>
                  {detail.year_of_issue?.name}
                </Item>
                <Item span={3} label={t('body')}>
                  {detail.body?.name}
                </Item>
                <Item span={3} label={t('modification')}>
                  {detail.modification?.name}
                </Item>
                <Item span={3} label={t('engine')}>
                  {detail.engine?.name}
                </Item>
                <Item span={3} label={t('driveUnit')}>
                  {detail.drive_unit?.name}
                </Item>
                <Item span={3} label={t('transmission')}>
                  {detail.transmission?.name}
                </Item>
                <Item span={3} label={t('color')}>
                  {detail.colour?.name}
                </Item>
                <Item span={3} label={t('mileage')}>
                  {detail.mileage
                    ? `${numberWithSpaces(detail.mileage)} ${t(
                        'common.units.km'
                      )}`
                    : parseNull(detail.mileage)}
                </Item>
                <Item span={3} label={t('broken')}>
                  <Button
                    icon={
                      detail.is_broken ? (
                        <CheckSquareOutlined />
                      ) : (
                        <CloseSquareOutlined />
                      )
                    }
                    type="link"
                    danger={!detail.is_broken}
                    style={{ pointerEvents: 'none' }}
                  />
                </Item>
                <Item span={3} label={t('vin')}>
                  {detail.vin}
                </Item>
                <Item span={3} label={t('complectation')}>
                  {detail.complectation?.name}
                </Item>
                <Item span={3} label={t('regSpec')}>
                  {detail.regional_spec?.name}
                </Item>
              </>
            )}

            <Item span={3} label={t('stickers')}>
              {detail.stickers?.map((s) => s.title).join(', ')}
            </Item>
          </Descriptions>
        </div>
      )}

      <RejectForm
        isOpen={isRejectOpen}
        setOpen={setRejectOpen}
        text={reasonText}
        setText={setReasonText}
        onSubmit={onReject}
      />
    </div>
  );
}

export function ModerationAdvertDetailWrap() {
  const { id } = useParams();
  const numId = id ? Number(id) : null;
  const isIdValid = !!numId && !Number.isNaN(numId);
  return isIdValid ? <ModerationAdvertDetail id={numId} /> : <></>;
}

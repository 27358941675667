import { Select } from 'antd';
import { useEffect, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchSelectContext } from '@/modules/search/search-select-ctx';
import { useSearchStore } from '@/modules/search/store';
import { useVrpTypes } from '@/modules/vrp/hooks';
import { GetVrpTypesReq } from '@/modules/vrp/types';
import { Nullable } from '@/utils/types';

type Props = {
  initialId: Nullable<number>;
};

export function PlateTypeSelect({ initialId }: Props) {
  const { t } = useTranslation();

  const emirateId = useSearchStore((s) => s.vrpPlateEmirateId);
  const typeId = useSearchStore((s) => s.vrpTypeId);
  const setTypeId = useSearchStore((s) => s.setVrpTypeId);
  const setTypeName = useSearchStore((s) => s.setVrpTypeName);

  const { plateTypeSetted, setPlateTypeSetted } =
    useContext(SearchSelectContext);

  // Base info
  const req = useMemo<Nullable<GetVrpTypesReq>>(
    () => ({ emirate_id: emirateId || null }),
    [emirateId]
  );
  const [allTypes, infoLoading] = useVrpTypes(req);

  // Types
  const options = useMemo(
    () =>
      allTypes ? allTypes.map((e) => ({ value: e.id, label: e.name })) : [],
    [allTypes]
  );

  const onChange = (v: number) => {
    setPlateTypeSetted(true);
    setTypeId(v);
    const type = allTypes?.find((tp) => tp.id === v);
    setTypeName(type?.name || null);
  };

  const onClear = () => {
    setPlateTypeSetted(true);
    setTypeId(null);
    setTypeName(null);
  };

  useEffect(() => {
    if (!typeId && initialId !== null && !plateTypeSetted) {
      setPlateTypeSetted(true);
      setTypeId(initialId);
      setTypeName(allTypes?.find((opt) => opt.id === initialId)?.name || null);
    }
  }, [
    typeId,
    initialId,
    setTypeId,
    plateTypeSetted,
    setPlateTypeSetted,
    setTypeName,
    allTypes
  ]);

  const isDisabled = useMemo(
    () => infoLoading || options.length === 0,
    [infoLoading, options]
  );

  return (
    <div>
      <Select
        options={options}
        value={typeId}
        onChange={onChange}
        onClear={onClear}
        allowClear
        placeholder={t('type')}
        disabled={isDisabled}
        size="large"
        style={{ width: '100%' }}
      />
    </div>
  );
}

import qs from 'qs';

import { EMPTY_BRAND_ID, PRICE_MAX, PRICE_MIN } from '@/modules/search/consts';
import {
  parseBool,
  parseNum,
  isArr,
  parseAutoFilter,
  StringsObject,
  parseIds,
  parseString
} from '@/modules/search/parse-helpers';
import { SearchStore } from '@/modules/search/store';
import { SearchAdvertsReq, AutoFilterIds } from '@/modules/search/types';
import { Nullable } from '@/utils/types';

export const MAX_MILEAGE_LEN = 7;
export const MAX_PRICE_LEN = 8;
export const MIN_TIME_TO_100 = 1;
export const MAX_TIME_TO_100 = 50;
export const MIN_HORSEPOWER = 0;
export const MAX_HORSEPOWER = 10000;
export const MIN_VOLUME = 0;
export const MAX_VOLUME = 10;
export const MAX_VRP_NUMBER_LEN = 5;

export const defaultSearchReq: SearchAdvertsReq = {
  user_account_id: null,
  type_id: null,
  category_id: null,
  auto_filter: null,
  auto_filter_exclude: null,
  emirate_ids: null,
  regional_spec_ids: null,
  year_of_issue_max_id: null,
  year_of_issue_min_id: null,
  price_max: null,
  price_min: null,
  mileage_max: null,
  mileage_min: null,
  transmission_ids: null,
  body_ids: null,
  engine_ids: null,
  drive_unit_ids: null,
  colour_ids: null,
  broken_id: null,
  days_publication_id: null,
  sort_filter_id: null,
  available_trade: null,
  time_to_100_from: null,
  time_to_100_up_to: null,
  horse_power_from: null,
  horse_power_up_to: null,
  volume_from: null,
  volume_up_to: null,
  vrp_plate_emirate_id: null,
  vrp_type_id: null,
  vrp_plate_number_len: null,
  push_period_id: null,
  notification_is_activated: null,
  forbidden_from_22_pm_to_9_am: null,
  vin: null,
  advertisement_type_id: null
};

// Parse / stringify
export function removeEmptyValues(
  req: SearchAdvertsReq
): Partial<SearchAdvertsReq> {
  const keys = Object.keys(req).filter((key) => {
    const value = req[key as keyof SearchAdvertsReq];
    if (value === null || value === undefined) return false;
    if (Array.isArray(value)) return value.length > 0;
    if (typeof value === 'boolean') return value;
    return true;
  });

  const filteredReq: Partial<SearchAdvertsReq> = {};
  keys.forEach((key) => {
    const k = key as keyof SearchAdvertsReq;
    const value = req[k];
    // @ts-ignore
    filteredReq[k] = value;
  });

  return filteredReq;
}

export function stringifySearchReq(req: SearchAdvertsReq): string {
  const mergedReq = {
    ...req,
    auto_filter: req.auto_filter
      ? req.auto_filter.filter((v) => v.brand_id !== EMPTY_BRAND_ID)
      : null,
    auto_filter_exclude: req.auto_filter_exclude
      ? req.auto_filter_exclude.filter((v) => v.brand_id !== EMPTY_BRAND_ID)
      : null
  };
  const filteredReq = removeEmptyValues(mergedReq);
  const result = qs.stringify(filteredReq);

  return result;
}

export function parseSearchParams(
  params: Nullable<URLSearchParams>
): qs.ParsedQs {
  return qs.parse(params ? params.toString() : '');
}

export function parseResultsParam(params: Nullable<URLSearchParams>) {
  const parsed = qs.parse(params ? params.toString() : '');
  if (!parsed || Object.keys(parsed).length <= 0) return false;
  return !!parseBool(parsed.results);
}

// Create search request
export function paramsToSearchReq(parsed: qs.ParsedQs): SearchAdvertsReq {
  if (!parsed || Object.keys(parsed).length <= 0) return defaultSearchReq;

  let price_max = parseNum(parsed.price_max);
  price_max = price_max === PRICE_MAX ? null : price_max;
  let price_min = parseNum(parsed.price_min);
  price_min = price_min === PRICE_MIN ? null : price_min;

  const req: SearchAdvertsReq = {
    category_id: parseNum(parsed.category_id),
    auto_filter: isArr(parsed.auto_filter)
      ? parseAutoFilter(parsed.auto_filter as StringsObject[])
      : null,
    auto_filter_exclude: isArr(parsed.auto_filter_exclude)
      ? parseAutoFilter(parsed.auto_filter_exclude as StringsObject[])
      : null,
    type_id: parseNum(parsed.type_id),
    year_of_issue_min_id: parseNum(parsed.year_of_issue_min_id),
    year_of_issue_max_id: parseNum(parsed.year_of_issue_max_id),
    mileage_min: parseNum(parsed.mileage_min),
    mileage_max: parseNum(parsed.mileage_max),
    price_min,
    price_max,
    emirate_ids: isArr(parsed.emirate_ids)
      ? parseIds(parsed.emirate_ids as string[])
      : null,
    regional_spec_ids: isArr(parsed.regional_spec_ids)
      ? parseIds(parsed.regional_spec_ids as string[])
      : null,
    body_ids: isArr(parsed.body_ids)
      ? parseIds(parsed.body_ids as string[])
      : null,
    transmission_ids: isArr(parsed.transmission_ids)
      ? parseIds(parsed.transmission_ids as string[])
      : null,
    engine_ids: isArr(parsed.engine_ids)
      ? parseIds(parsed.engine_ids as string[])
      : null,
    drive_unit_ids: isArr(parsed.drive_unit_ids)
      ? parseIds(parsed.drive_unit_ids as string[])
      : null,
    colour_ids: isArr(parsed.colour_ids)
      ? parseIds(parsed.colour_ids as string[])
      : null,
    broken_id: parseNum(parsed.broken_id),
    sort_filter_id: parseNum(parsed.sort_filter_id),
    days_publication_id: parseNum(parsed.days_publication_id),
    available_trade: parseBool(parsed.available_trade),
    time_to_100_from: parseNum(parsed.time_to_100_from),
    time_to_100_up_to: parseNum(parsed.time_to_100_up_to),
    horse_power_from: parseNum(parsed.horse_power_from),
    horse_power_up_to: parseNum(parsed.horse_power_up_to),
    volume_from: parseNum(parsed.volume_from),
    volume_up_to: parseNum(parsed.volume_up_to),
    user_account_id: parseNum(parsed.user_account_id),
    vrp_plate_emirate_id: parseNum(parsed.vrp_plate_emirate_id),
    vrp_type_id: parseNum(parsed.vrp_type_id),
    vrp_plate_number_len: parseNum(parsed.vrp_plate_number_len),
    vin: parseString(parsed.vin),
    advertisement_type_id: parseNum(parsed.advertisement_type_id),
    push_period_id: parseNum(parsed.push_period_id),
    notification_is_activated: parseBool(parsed.notification_is_activated),
    forbidden_from_22_pm_to_9_am: parseBool(parsed.forbidden_from_22_pm_to_9_am)
  };

  return req;
}

export const mapAutoFilters = (
  data: AutoFilterIds[],
  skipGenerationsArray?: boolean
) => {
  let mappedAutoFilters: AutoFilterIds[] = [];

  data
    .filter((v) => v.brand_id !== EMPTY_BRAND_ID && v.brand_id !== undefined)
    .forEach((autofilter) => {
      let temp: AutoFilterIds[] = [];

      if (!skipGenerationsArray && Array.isArray(autofilter.generation_id)) {
        temp = autofilter.generation_id.map((generation) => ({
          brand_id: autofilter.brand_id,
          model_id: autofilter.model_id,
          generation_id: generation
        }));
      } else {
        temp = [
          {
            brand_id: autofilter.brand_id,
            model_id: autofilter.model_id,
            generation_id: autofilter.generation_id
          }
        ];
      }

      mappedAutoFilters = [...mappedAutoFilters, ...temp];
    });

  return mappedAutoFilters;
};

export function storeToSearchReq(store: SearchStore): SearchAdvertsReq {
  const result: SearchAdvertsReq = {
    ...defaultSearchReq,
    category_id: store.categoryId,
    auto_filter: mapAutoFilters(store.autoFilters),
    auto_filter_exclude: mapAutoFilters(store.autoFiltersExclude),
    user_account_id: store.userId,
    type_id: store.typeId,
    year_of_issue_min_id: store.yearMinId,
    year_of_issue_max_id: store.yearMaxId,
    sort_filter_id: store.sortId,
    broken_id: store.brokenParamId,
    days_publication_id: store.pubDayId,
    emirate_ids: store.emiratesIds,
    regional_spec_ids: store.regSpecsIds,
    body_ids: store.bodiesIds,
    transmission_ids: store.transmissionsIds,
    engine_ids: store.enginesIds,
    drive_unit_ids: store.driveUnitsIds,
    colour_ids: store.colorsIds,
    mileage_min: store.mileageMin || null,
    mileage_max: store.mileageMax || null,
    price_min: store.priceMin || null,
    price_max: store.priceMax || null,
    available_trade: store.tradeAvailable || null,
    time_to_100_from: store.timeTo100Min || null,
    time_to_100_up_to: store.timeTo100Max || null,
    horse_power_from: store.horsePowerMin || null,
    horse_power_up_to: store.horsePowerMax || null,
    volume_from: store.volumeMin || null,
    volume_up_to: store.volumeMax || null,
    vin: store.vin,
    advertisement_type_id: store.advertTypeId,
    vrp_plate_emirate_id: store.vrpPlateEmirateId,
    vrp_type_id: store.vrpTypeId,
    vrp_plate_number_len: store.vrpPlateNumberLen
  };

  return result;
}

// Smart filters
type SmartAutoFiltersIds = {
  brand_ids: number[];
  model_ids: number[];
};

export function smartAutoFiltersIds(
  autoFilters: AutoFilterIds[]
): SmartAutoFiltersIds {
  const result: SmartAutoFiltersIds = {
    brand_ids: [],
    model_ids: []
  };

  return autoFilters.reduce((acc, cur) => {
    const tmp = { ...acc };
    const { brand_id, model_id } = cur;

    if (model_id !== null) {
      tmp.model_ids.push(model_id);
    } else if (!!brand_id && brand_id !== EMPTY_BRAND_ID) {
      tmp.brand_ids.push(brand_id);
    }

    return { ...tmp };
  }, result);
}

// Other
const countByArr = (arr?: Nullable<unknown[]>) => {
  return arr && arr.length > 0 ? 1 : 0;
};

export function calcSelectedParamsCount(store: SearchStore): [number, number] {
  let count = 0;
  count += countByArr(store.emiratesIds);
  count += countByArr(store.regSpecsIds);
  count += countByArr(store.bodiesIds);
  count += countByArr(store.transmissionsIds);
  count += countByArr(store.enginesIds);
  count += countByArr(store.driveUnitsIds);
  count += countByArr(store.colorsIds);
  count += store.yearMinId ? 1 : 0;
  count += store.yearMaxId ? 1 : 0;
  count += store.mileageMin ? 1 : 0;
  count += store.mileageMax ? 1 : 0;
  count += store.priceMin ? 1 : 0;
  count += store.priceMax ? 1 : 0;
  count += store.brokenParamId ? 1 : 0;
  count += store.pubDayId ? 1 : 0;
  count += store.tradeAvailable ? 1 : 0;
  count += store.timeTo100Min ? 1 : 0;
  count += store.timeTo100Max ? 1 : 0;
  count += store.horsePowerMin ? 1 : 0;
  count += store.horsePowerMax ? 1 : 0;
  count += store.volumeMin ? 1 : 0;
  count += store.volumeMax ? 1 : 0;

  let allParamsCount = count;

  allParamsCount += store.advertTypeId !== 1 ? 1 : 0;
  allParamsCount += store.typeId ? 1 : 0;
  allParamsCount += countByArr(
    store.autoFilters.filter((a) => a.brand_id !== EMPTY_BRAND_ID)
  );

  return [count, allParamsCount];
}

export function calcVrpSelectedParamsCount(store: SearchStore): number {
  let count = 0;
  count += countByArr(store.emiratesIds);
  count += store.vrpPlateEmirateId ? 1 : 0;
  count += store.vrpTypeId ? 1 : 0;
  count += store.vrpPlateNumberLen ? 1 : 0;
  count += store.priceMin ? 1 : 0;
  count += store.priceMax ? 1 : 0;
  count += store.pubDayId ? 1 : 0;

  return count;
}

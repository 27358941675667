import type { TablePaginationConfig } from 'antd/es/table';

export const isProdApi = process.env.REACT_APP_SERVER === 'prod';
export const AUTH_PASS = process.env.REACT_APP_AUTH_PASS;

// URLs
export const INVALID_IMG_SRC = 'https://..';
export const APP_URL = process.env.REACT_APP_APP_URL as string;
export const API_URL = `${process.env.REACT_APP_API_URL as string}/api`;

// Other
export const ADV_CUR = 'AED';
export const SECONDS_IN_DAY = 86400;
export const HOURS_IN_DAY = 24;
export const BREAKPOINT = 770;
export const INTERSECTION_THRESHOLD = [
  0, 0.1, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1
];
export const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

// Table pagination
export const MAX_PG_LIMIT = 1000;
export const MAX_PG_LIMIG_REQ = { limit: MAX_PG_LIMIT, skip: 0 };
export const TABLE_ACTION_CLASS = 'table-action';
export const PG_SIZES = [10, 20, 50, 100, 500, 1000];
export const PG_SIZE = PG_SIZES[0];
export const pgConfig: TablePaginationConfig = {
  current: 1,
  total: 1,
  pageSize: PG_SIZE,
  showSizeChanger: true,
  pageSizeOptions: PG_SIZES,
  position: ['bottomLeft']
};

import { Collapse } from 'react-collapse';

import { EmirateType } from '@/modules/emirates/types';
import { AdvertTypes } from '@/modules/search/SearchAdverts/SearchCars/AdvertTypes';
import { EmiratesSelect } from '@/modules/search/SearchAdverts/SearchCars/EmiratesSelect';
import { Price } from '@/modules/search/SearchAdverts/SearchCars/Price';
import { PubDaysSelect } from '@/modules/search/SearchAdverts/SearchCars/PubDaysSelect';
import { PlateEmirateSelect } from '@/modules/search/SearchAdverts/SearchPlates/PlateEmirateSelect';
import { PlateNumberLengthSelect } from '@/modules/search/SearchAdverts/SearchPlates/PlateNumberLengthSelect';
import { PlateTypeSelect } from '@/modules/search/SearchAdverts/SearchPlates/PlateTypeSelect';
import { PubDay, SearchAdvertsReq } from '@/modules/search/types';

import cls from '../SearchCars/SearchCars.module.scss';

type Props = {
  emirates: EmirateType[];
  pubDays: PubDay[];
  initialReq: SearchAdvertsReq;
  hideFilters?: boolean;
};

export function SearchPlatesDesktop({
  emirates,
  pubDays,
  initialReq,
  hideFilters
}: Props) {
  return (
    <Collapse isOpened={!hideFilters}>
      <div className={cls.box}>
        <ul className={cls.fields}>
          <li>
            <EmiratesSelect
              initialIds={initialReq.emirate_ids || []}
              emirates={emirates}
            />
          </li>
          <li>
            <AdvertTypes initialId={initialReq.advertisement_type_id} />
          </li>
          <li />
          <li>
            <PlateEmirateSelect
              initialId={initialReq.vrp_plate_emirate_id}
              emirates={emirates}
            />
          </li>
          <li>
            <PlateTypeSelect initialId={initialReq.vrp_type_id} />
          </li>
          <li>
            <PlateNumberLengthSelect
              initialId={initialReq.vrp_plate_number_len}
            />
          </li>
          <li>
            <div className={cls.row_group}>
              <Price
                initialMin={initialReq.price_min || 0}
                initialMax={initialReq.price_max || 0}
              />
            </div>
          </li>
          <li>
            <PubDaysSelect
              pubDays={pubDays}
              initialId={initialReq.days_publication_id}
            />
          </li>
        </ul>
      </div>
    </Collapse>
  );
}

export function diffInHours(later: Date, earlier: Date) {
  let diff = (later.getTime() - earlier.getTime()) / 1000;
  diff /= 60 * 60;

  return Math.abs(Math.round(diff));
}

export function minutesToHHMM(minutes: number): string {
  return new Date(minutes * 60 * 1000).toISOString().substr(11, 5);
}

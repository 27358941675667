import { Checkbox, Input, Modal, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UploadRes } from '@/modules/files/types';
import { Uploader } from '@/modules/files/Uploader/Uploader';

import cls from './GenerationForm.module.scss';

function makeYearsList() {
  const years = [];
  const start = 1974;
  const end = new Date().getFullYear() + 1;

  for (let i = start; i < end; i++) {
    years.push({ value: i, label: i });
  }

  return years.reverse();
}

const yearsList = makeYearsList();

export type FormType = {
  name: string;
  photo_url: string;
  year_from: string;
  year_to: string;
  is_restyle: boolean;
};

type Props = {
  title: string;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  initialData?: FormType;
  onSave: (form: FormType) => void;
  loading?: boolean;
};

export function GenerationForm({
  title,
  isOpen,
  setOpen,
  initialData,
  onSave,
  loading
}: Props) {
  const { t } = useTranslation();

  const [name, setName] = useState(initialData?.name || '');

  const [uploading, setUploading] = useState(false);
  const [src, setSrc] = useState(initialData?.photo_url || '');
  const onSrcUpload = (res: UploadRes) => {
    setSrc(res.url);
  };

  const [restyle, setRestyle] = useState(!!initialData?.is_restyle);

  const [from, setFrom] = useState(initialData?.year_from || '');
  const [to, setTo] = useState(initialData?.year_to || '');

  const fromOptions = to
    ? yearsList.filter((y) => Number(y.value) <= Number(to))
    : yearsList;

  const toOptions = from
    ? yearsList.filter((y) => Number(y.value) >= Number(from))
    : yearsList;

  const close = () => {
    setOpen(false);
    setName('');
    setSrc('');
    setFrom('');
    setTo('');
    setRestyle(false);
  };

  useEffect(() => {
    if (initialData) {
      setName(initialData.name);
      setSrc(initialData.photo_url);
      setFrom(initialData.year_from);
      setTo(initialData.year_to);
      setRestyle(!!initialData.is_restyle);
    }
  }, [initialData]);

  const isValid = !!from;
  const onOk = () => {
    onSave({
      name,
      photo_url: src,
      year_from: from,
      year_to: to,
      is_restyle: restyle
    });
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      okText={t('common.save')}
      cancelText={t('common.cancel')}
      onCancel={close}
      onOk={onOk}
      okButtonProps={{ disabled: loading || !isValid }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Uploader
        type="image"
        src={src}
        loading={uploading}
        setLoading={setUploading}
        onUpload={onSrcUpload}
        large
      />
      <Space style={{ width: '100%' }} direction="vertical">
        <Input
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          size="large"
          placeholder={t('modelName') as string}
        />
        <Space className={cls.years}>
          <Select
            size="large"
            value={from}
            onChange={setFrom}
            options={fromOptions}
            style={{ width: '100%' }}
            placeholder={t('yearFrom') as string}
          />
          <Select
            size="large"
            value={to}
            onChange={setTo}
            options={toOptions}
            style={{ width: '100%' }}
            allowClear
            placeholder={t('yearTo') as string}
          />
        </Space>
        <Checkbox
          checked={restyle}
          onChange={(e) => setRestyle(e.target.checked)}
        >
          {t('restyle')}
        </Checkbox>
      </Space>
    </Modal>
  );
}

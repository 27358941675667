import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useMemo } from 'react';

import { useEmirates } from '@/modules/emirates/api';
import {
  useSearchReq,
  usePubDays,
  useSortFilters
} from '@/modules/search/hooks';
import { SearchPlates } from '@/modules/search/SearchAdverts/SearchPlates/SearchPlates';
import { MAX_PG_LIMIG_REQ } from '@/utils/consts';

type Props = {
  categoryId: number;
  hideFilters: boolean;
  toggleHideFilters: (v: boolean) => void;
  showResults?: boolean;
};

export function SearchPlatesWrap({
  categoryId,
  hideFilters,
  toggleHideFilters,
  showResults: showResultsProp
}: Props) {
  const [req, showResults] = useSearchReq();

  const [emirates, emiratesLoading] = useEmirates(MAX_PG_LIMIG_REQ);
  const [pubDays, pubDaysLoading] = usePubDays(MAX_PG_LIMIG_REQ);
  const sortReq = useMemo(
    () => ({
      ...MAX_PG_LIMIG_REQ,
      category_id: categoryId
    }),
    [categoryId]
  );
  const [sortFilters, sortFiltersLoading] = useSortFilters(sortReq);
  const loading = emiratesLoading || pubDaysLoading || sortFiltersLoading;

  if (loading) return <Spin indicator={<LoadingOutlined />} />;

  return (
    <SearchPlates
      req={req}
      showResults={showResults || !!showResultsProp}
      emirates={emirates || []}
      pubDays={pubDays || []}
      sortFilters={sortFilters || []}
      hideFilters={hideFilters}
      toggleHideFilters={toggleHideFilters}
    />
  );
}

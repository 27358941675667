import { Segmented } from 'antd';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useCategories } from '@/modules/category/api';
import { CARS_CATEGORY_ID } from '@/modules/search/consts';
import { parseNum } from '@/modules/search/parse-helpers';
import { useSearchStore } from '@/modules/search/store';
import { MAX_PG_LIMIT } from '@/utils/consts';

const req = {
  limit: MAX_PG_LIMIT
};

export function Categories() {
  const [list] = useCategories(req);

  const [searchParams] = useSearchParams();

  const categoryId = useSearchStore((s) => s.categoryId);
  const setCategoryId = useSearchStore((s) => s.setCategoryId);

  const options = useMemo(() => {
    if (list) return list.map((c) => ({ label: c.name, value: c.id }));
    return [];
  }, [list]);

  useEffect(() => {
    if (!categoryId) {
      setCategoryId(setInitialCategory(searchParams));
    }
  }, [categoryId, searchParams, setCategoryId]);

  return (
    <div>
      <Segmented
        size="large"
        options={options}
        value={categoryId || ''}
        onChange={(v) => setCategoryId(Number(v))}
      />
    </div>
  );
}

function setInitialCategory(searchParams: URLSearchParams) {
  const params = Object.fromEntries(searchParams);
  if (params.category_id) {
    const categoryId = parseNum(params.category_id);
    return categoryId || CARS_CATEGORY_ID;
  }

  return CARS_CATEGORY_ID;
}

import { useState, useCallback, useEffect } from 'react';

import { axios } from '@/api/instance';
import { useCommonStore } from '@/app/common-store';
import {
  GetPromotionPackagesRes,
  UpdatePromotionPackageReq
} from '@/modules/promotion/types';
import { showAlert } from '@/utils/network';

export function updatePromotionPackages(
  promotion_id: number,
  req: UpdatePromotionPackageReq
) {
  return axios.patch<unknown>(`/admin/promotion/${promotion_id}`, req);
}

export function usePromotionPackages(
  refreshKey?: number
): [GetPromotionPackagesRes, boolean] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetPromotionPackagesRes>([]);

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetPromotionPackagesRes>(
        '/admin/promotion/list',
        {
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, isLoading];
}

import { Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { smartAutoFiltersIds } from '@/modules/search/helpers';
import { useSearchStore } from '@/modules/search/store';
import { useTransportDriveUnits } from '@/modules/transport/hooks';
import { GetTransportDriveUnitsReq } from '@/modules/transport/types';
import { MAX_PG_LIMIT } from '@/utils/consts';
import { Nullable } from '@/utils/types';

type Props = {
  initialIds: number[];
};

export function DriveUnitsSelect({ initialIds }: Props) {
  const { t } = useTranslation();

  const autoFilters = useSearchStore((s) => s.autoFilters);
  const driveUnitsIds = useSearchStore((s) => s.driveUnitsIds);
  const setDriveUnitsIds = useSearchStore((s) => s.setDriveUnitsIds);

  const [shouldLoadDriveUnits, setShouldLoadDriveUnits] = useState(
    initialIds.length > 0
  );

  const req = useMemo<Nullable<GetTransportDriveUnitsReq>>(() => {
    if (!shouldLoadDriveUnits) return null;
    const ids = smartAutoFiltersIds(autoFilters);
    return { limit: MAX_PG_LIMIT, ...ids };
  }, [autoFilters, shouldLoadDriveUnits]);
  const [driveUnits] = useTransportDriveUnits(req);

  const options = useMemo(
    () =>
      driveUnits ? driveUnits.map((e) => ({ value: e.id, label: e.name })) : [],
    [driveUnits]
  );

  useEffect(() => {
    if (!driveUnitsIds && initialIds.length > 0) {
      setDriveUnitsIds(initialIds);
    }
  }, [driveUnitsIds, initialIds, setDriveUnitsIds]);

  return (
    <div>
      <Select
        options={options}
        value={driveUnitsIds || []}
        onChange={setDriveUnitsIds}
        placeholder={t('driveUnit')}
        onFocus={() => setShouldLoadDriveUnits(true)}
        mode="multiple"
        onClear={() => setDriveUnitsIds([])}
        allowClear
        size="large"
        style={{ width: '100%' }}
      />
    </div>
  );
}

import { useState, useCallback, useEffect } from 'react';

import { axios } from '@/api/instance';
import { useCommonStore } from '@/app/common-store';
import {
  GetModerationAdvertsRes,
  ModerationAdvertDetail
} from '@/modules/moderation/types';
import { showAlert } from '@/utils/network';

export function useModerationAdverts(
  refreshKey?: number
): [GetModerationAdvertsRes, boolean] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetModerationAdvertsRes>([]);

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetModerationAdvertsRes>(
        '/admin/moderation/ads',
        {
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, isLoading];
}

export function useModerationAdvertDetail(
  advertisement_id: number
): [ModerationAdvertDetail | undefined, boolean] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ModerationAdvertDetail>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<ModerationAdvertDetail>(
        '/admin/moderation/ads/detail',
        {
          params: { advertisement_id },
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [advertisement_id, langCode]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

import { Segmented } from 'antd';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountIdType } from '@/modules/accounts/types';
import { useSearchStore } from '@/modules/search/store';
import { Nullable } from '@/utils/types';

type Props = {
  initialId: Nullable<number>;
};

export function AdvertTypes({ initialId }: Props) {
  const { t } = useTranslation();

  const advertTypeId = useSearchStore((s) => s.advertTypeId);
  const setAdvertTypeId = useSearchStore((s) => s.setAdvertTypeId);

  const options = useMemo(
    () => [
      { label: t('common.all'), value: '' },
      { label: t('showrooms'), value: AccountIdType.showroom },
      { label: t('privates'), value: AccountIdType.privatePerson }
    ],
    [t]
  );

  useEffect(() => {
    if (initialId && !advertTypeId) {
      setAdvertTypeId(initialId);
    }
  }, [advertTypeId, initialId, setAdvertTypeId]);

  return (
    <div>
      <Segmented
        size="large"
        options={options}
        value={advertTypeId || ''}
        onChange={(v) => setAdvertTypeId(Number(v))}
        block
      />
    </div>
  );
}

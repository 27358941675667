import { create } from 'zustand';

import { BREAKPOINT } from '@/utils/consts';

type CrumbsType = {
  brandName: string;
  brandPath: string;
  modelName: string;
  modelPath: string;
  generationName: string;
  generationPath: string;
};

interface CommonStore {
  crumbs: CrumbsType;
  setCrumbBrand: (brandName: string, brandPath: string) => void;
  setCrumbModel: (modelName: string, modelPath: string) => void;
  setCrumbGeneration: (generationName: string, generationPath: string) => void;
  windowWidth: number;
  setWindowWidth: (windowWidth: number) => void;
  langCode: string;
  setLangCode: (langCode: string) => void;
}

export const useCommonStore = create<CommonStore>((set, get) => ({
  crumbs: {
    brandName: '',
    brandPath: '',
    modelName: '',
    modelPath: '',
    generationName: '',
    generationPath: ''
  },
  setCrumbBrand: (brandName: string, brandPath: string) => {
    const crumbs = get().crumbs;
    set({ crumbs: { ...crumbs, brandName, brandPath } });
  },
  setCrumbModel: (modelName: string, modelPath: string) => {
    const crumbs = get().crumbs;
    set({ crumbs: { ...crumbs, modelName, modelPath } });
  },
  setCrumbGeneration: (generationName: string, generationPath: string) => {
    const crumbs = get().crumbs;
    set({ crumbs: { ...crumbs, generationName, generationPath } });
  },
  windowWidth: window.innerWidth,
  setWindowWidth: (windowWidth: number) => set({ windowWidth }),
  langCode: '',
  setLangCode: (langCode: string) => set({ langCode })
}));

export function useDesktop(): boolean {
  const windowWidth = useCommonStore((state) => state.windowWidth);
  return windowWidth > BREAKPOINT;
}

import { Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { smartAutoFiltersIds } from '@/modules/search/helpers';
import { useSearchStore } from '@/modules/search/store';
import { useTransportEngines } from '@/modules/transport/hooks';
import { GetTransportEnginesReq } from '@/modules/transport/types';
import { MAX_PG_LIMIT } from '@/utils/consts';
import { Nullable } from '@/utils/types';

type Props = {
  initialIds: number[];
};

export function EnginesSelect({ initialIds }: Props) {
  const { t } = useTranslation();

  const autoFilters = useSearchStore((s) => s.autoFilters);
  const enginesIds = useSearchStore((s) => s.enginesIds);
  const setEnginesIds = useSearchStore((s) => s.setEnginesIds);

  const [shouldLoadEngines, setShouldLoadEngines] = useState(
    initialIds.length > 0
  );

  const req = useMemo<Nullable<GetTransportEnginesReq>>(() => {
    if (!shouldLoadEngines) return null;
    const ids = smartAutoFiltersIds(autoFilters);
    return { limit: MAX_PG_LIMIT, ...ids };
  }, [autoFilters, shouldLoadEngines]);
  const [engines] = useTransportEngines(req);

  const options = useMemo(
    () => (engines ? engines.map((e) => ({ value: e.id, label: e.name })) : []),
    [engines]
  );

  useEffect(() => {
    if (!enginesIds && initialIds.length > 0) {
      setEnginesIds(initialIds);
    }
  }, [enginesIds, initialIds, setEnginesIds]);

  return (
    <div>
      <Select
        options={options}
        value={enginesIds || []}
        onChange={setEnginesIds}
        placeholder={t('engine')}
        onFocus={() => setShouldLoadEngines(true)}
        mode="multiple"
        onClear={() => setEnginesIds([])}
        allowClear
        size="large"
        style={{ width: '100%' }}
      />
    </div>
  );
}

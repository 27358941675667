import { useState, useCallback, useEffect } from 'react';

import { axios } from '@/api/instance';
import { showAlert } from '@/utils/network';

import { LANGS_API_ROUTES } from './api';
import { GetLangsRes } from './types';

// Langs list
export function useLangs(): [GetLangsRes, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetLangsRes>([]);

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetLangsRes>(LANGS_API_ROUTES.getLangs);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EMPTY_BRAND_ID } from '@/modules/search/consts';
import { AutoFilterIds } from '@/modules/search/types';
import { useGenerationsPg, useModelsPg } from '@/modules/transport/hooks';
import { TransportBrand } from '@/modules/transport/types';
import { MAX_PG_LIMIT } from '@/utils/consts';

import cls from './AutoFilterList.module.scss';

type Props = {
  autoFilters: AutoFilterIds[];
  brands: TransportBrand[];
  value: AutoFilterIds;
  onChange: (value: AutoFilterIds) => void;
  onAdd: () => void;
  onRemove: () => void;
  showAdd?: boolean;
  showRemove?: boolean;
};

export function AutoFilter({
  autoFilters,
  brands,
  value,
  onChange,
  onAdd,
  onRemove,
  showAdd,
  showRemove
}: Props) {
  const { t } = useTranslation();

  // Brand
  const isBrandEmpty = value.brand_id === EMPTY_BRAND_ID || !value.brand_id;

  const brandsOptions = useMemo(() => {
    if (brands.length > 0) {
      const popular = brands.filter((b) => b.is_popular);

      return [
        {
          label: t('populars'),
          options: popular.map((b) => ({
            label: b.name,
            value: b.id
          }))
        },
        {
          label: t('common.all'),
          options: brands.map((b) => ({
            label: b.name,
            value: b.id
          }))
        }
      ];
    }

    return [];
  }, [brands, t]);
  const onBrandChange = (brandId: number) => {
    onChange({ brand_id: brandId, model_id: null, generation_id: null });
  };
  const onBrandClear = () => {
    onRemove();
  };

  // Model
  const [shouldLoadModels, setShouldLoadModels] = useState(!!value.model_id);

  const modelsReq = useMemo(
    () =>
      shouldLoadModels
        ? {
            limit: MAX_PG_LIMIT,
            brand_id: isBrandEmpty ? null : value.brand_id
          }
        : null,
    [value.brand_id, isBrandEmpty, shouldLoadModels]
  );
  const [models] = useModelsPg(modelsReq);
  const modelsOptions = useMemo(() => {
    if (models.length > 0) {
      const popular = models.filter((m) => m.is_popular);
      return [
        {
          label: t('populars'),
          options: popular.map((m) => ({ label: m.name, value: m.id }))
        },
        {
          label: t('common.all'),
          options: models.map((m) => ({ label: m.name, value: m.id }))
        }
      ];
    }

    return [];
  }, [models, t]);

  const onModelChange = (modelId: number) => {
    if (
      value.model_id !== modelId &&
      !autoFilters.map((f) => f.model_id).includes(modelId)
    ) {
      onChange({
        brand_id: value.brand_id,
        model_id: modelId,
        generation_id: modelId ? null : value.generation_id
      });
    }
  };
  const onModelClear = () => {
    onChange({ ...value, model_id: null, generation_id: null });
  };

  // Generation
  const [shouldLoadGens, setShouldLoadGens] = useState(!!value.generation_id);

  const generationsReq = useMemo(
    () =>
      shouldLoadGens
        ? {
            limit: MAX_PG_LIMIT,
            model_id: value.model_id
          }
        : null,
    [shouldLoadGens, value.model_id]
  );
  const [generations] = useGenerationsPg(generationsReq);

  const genOptions = useMemo(() => {
    if (generations)
      return generations.map((g) => ({ label: g.name, value: g.id }));
    return [];
  }, [generations]);

  const onGenChange = (v: number[]) => {
    onChange({
      ...value,
      generation_id: v.length > 0 ? v.map((gId) => gId) : null
    });
  };

  const onGenClear = () => {
    onChange({ ...value, generation_id: null });
  };

  return (
    <ul className={cls.list}>
      <li>
        <div className={cls.row}>
          <Select
            options={brandsOptions}
            value={value.brand_id === EMPTY_BRAND_ID ? null : value.brand_id}
            onChange={onBrandChange}
            onClear={onBrandClear}
            allowClear
            placeholder={t('brand')}
            size="large"
            style={{ width: '100%' }}
          />
          {!isBrandEmpty && showAdd && (
            <Button size="large" onClick={onAdd} icon={<PlusOutlined />} />
          )}
        </div>
      </li>

      <li>
        <Select
          disabled={isBrandEmpty}
          options={modelsOptions}
          value={value.model_id}
          onChange={onModelChange}
          onClear={onModelClear}
          allowClear
          onFocus={() => setShouldLoadModels(true)}
          placeholder={t('model')}
          size="large"
          style={{ width: '100%' }}
        />
      </li>

      <li>
        <div className={cls.row}>
          <Select
            disabled={!value.model_id}
            value={(value.generation_id as number[]) || []}
            options={genOptions}
            onChange={onGenChange}
            onClear={onGenClear}
            allowClear
            onFocus={() => setShouldLoadGens(true)}
            placeholder={t('generation')}
            mode="multiple"
            size="large"
            style={{ width: '100%' }}
          />

          {showRemove && (
            <Button size="large" onClick={onRemove} icon={<MinusOutlined />} />
          )}
        </div>
      </li>
    </ul>
  );
}

import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { YearSelect } from '@/modules/search/SearchAdverts/SearchCars/YearSelect';
import { useSearchStore } from '@/modules/search/store';
import { TransportYear } from '@/modules/transport/types';
import { Nullable } from '@/utils/types';

type Props = {
  initialId: Nullable<number>;
  allYears: TransportYear[];
  yearsLoading: boolean;
};

export function YearMax({ initialId, allYears, yearsLoading }: Props) {
  const { t } = useTranslation();

  const yearMinId = useSearchStore((s) => s.yearMinId);
  const yearMaxId = useSearchStore((s) => s.yearMaxId);
  const setYearMaxId = useSearchStore((s) => s.setYearMaxId);

  const filteredYears = useMemo(() => {
    if (yearMinId) {
      const minYear = allYears.find((y) => y.id === yearMinId);
      if (!minYear) return allYears;
      const minYearNum = Number(minYear.name);
      if (!minYearNum || Number.isNaN(minYearNum)) return allYears;

      return allYears.filter((y) => {
        const yearNum = Number(y.name);

        if (yearNum && !Number.isNaN(yearNum)) {
          return yearNum >= minYearNum;
        }

        return true;
      });
    }

    return allYears;
  }, [allYears, yearMinId]);

  useEffect(() => {
    if (!yearMaxId && initialId) {
      setYearMaxId(initialId);
    }
  }, [yearMaxId, initialId, setYearMaxId]);

  return (
    <YearSelect
      allYears={filteredYears}
      yearsLoading={yearsLoading}
      selectedId={yearMaxId}
      placeholder={t('to')}
      onChange={setYearMaxId}
      onClear={() => setYearMaxId(null)}
    />
  );
}

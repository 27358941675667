import { create } from 'zustand';

import { emptyAutoFilter } from '@/modules/search/consts';
import { AutoFilterIds } from '@/modules/search/types';
import { Nullable } from '@/utils/types';

type AutoFiltersSetter = (prev: AutoFilterIds[]) => AutoFilterIds[];

export interface SearchStore {
  userId: Nullable<number>;
  setUserId: (userId: Nullable<number>) => void;
  categoryId: Nullable<number>;
  setCategoryId: (category: number) => void;
  autoFilters: AutoFilterIds[];
  setAutoFilters: (setter: AutoFiltersSetter) => void;
  autoFiltersExclude: AutoFilterIds[];
  setAutoFiltersExclude: (setter: AutoFiltersSetter) => void;
  typeId: Nullable<number>;
  setTypeId: (typeId: Nullable<number>) => void;
  yearMinId: Nullable<number>;
  setYearMinId: (yearMinId: Nullable<number>) => void;
  yearMaxId: Nullable<number>;
  setYearMaxId: (yearMaxId: Nullable<number>) => void;
  sortId: Nullable<number>;
  setSortId: (sortId: Nullable<number>) => void;
  brokenParamId: Nullable<number>;
  setBrokenParamId: (brokenParamId: Nullable<number>) => void;
  pubDayId: Nullable<number>;
  setPubDayId: (pubDayId: Nullable<number>) => void;
  emiratesIds: Nullable<number[]>;
  setEmiratesIds: (emiratesIds: Nullable<number[]>) => void;
  regSpecsIds: Nullable<number[]>;
  setRegSpecsIds: (regSpecsIds: Nullable<number[]>) => void;
  bodiesIds: Nullable<number[]>;
  setBodiesIds: (bodiesIds: Nullable<number[]>) => void;
  transmissionsIds: Nullable<number[]>;
  setTransmissionsIds: (transmissionsIds: Nullable<number[]>) => void;
  enginesIds: Nullable<number[]>;
  setEnginesIds: (enginesIds: Nullable<number[]>) => void;
  driveUnitsIds: Nullable<number[]>;
  setDriveUnitsIds: (driveUnitsIds: Nullable<number[]>) => void;
  colorsIds: Nullable<number[]>;
  setColorsIds: (colorsIds: Nullable<number[]>) => void;
  mileageMin: Nullable<number>;
  setMileageMin: (mileageMin: Nullable<number>) => void;
  mileageMax: Nullable<number>;
  setMileageMax: (mileageMax: Nullable<number>) => void;
  priceMin: Nullable<number>;
  setPriceMin: (priceMin: Nullable<number>) => void;
  priceMax: Nullable<number>;
  setPriceMax: (priceMax: Nullable<number>) => void;
  tradeAvailable: Nullable<boolean>;
  setTradeAvailable: (tradeAvailable: Nullable<boolean>) => void;
  timeTo100Min: Nullable<number>;
  setTimeTo100Min: (timeTo100Min: Nullable<number>) => void;
  timeTo100Max: Nullable<number>;
  setTimeTo100Max: (timeTo100Max: Nullable<number>) => void;
  horsePowerMin: Nullable<number>;
  setHorsePowerMin: (horsePowerMin: Nullable<number>) => void;
  horsePowerMax: Nullable<number>;
  setHorsePowerMax: (horsePowerMax: Nullable<number>) => void;
  volumeMin: Nullable<number>;
  setVolumeMin: (volumeMin: Nullable<number>) => void;
  volumeMax: Nullable<number>;
  setVolumeMax: (volumeMax: Nullable<number>) => void;
  vin: Nullable<string>;
  setVin: (vin: Nullable<string>) => void;
  advertTypeId: Nullable<number>;
  setAdvertTypeId: (advertTypeId: Nullable<number>) => void;
  vrpPlateEmirateId: Nullable<number>;
  setPlateEmirateId: (vrpPlateEmirateId: Nullable<number>) => void;
  vrpPlateEmirateName: Nullable<string>;
  setPlateEmirateName: (vrpPlateEmirateName: Nullable<string>) => void;
  vrpTypeId: Nullable<number>;
  setVrpTypeId: (vrpTypeId: Nullable<number>) => void;
  vrpTypeName: Nullable<string>;
  setVrpTypeName: (vrpTypeName: Nullable<string>) => void;
  vrpPlateNumberLen: Nullable<number>;
  setVrpPlateNumberLen: (vrpPlateNumberLen: Nullable<number>) => void;
  resetValues: (values?: Partial<SearchStore>) => void;
}

export const useSearchStore = create<SearchStore>((set, get) => ({
  userId: null,
  setUserId: (userId: Nullable<number>) => set({ userId }),
  categoryId: null,
  setCategoryId: (categoryId: Nullable<number>) => set({ categoryId }),
  autoFilters: [emptyAutoFilter],
  setAutoFilters: (setter: AutoFiltersSetter) => {
    const prev = get().autoFilters;
    set({ autoFilters: setter([...prev]) });
  },
  autoFiltersExclude: [],
  setAutoFiltersExclude: (setter: AutoFiltersSetter) => {
    const prev = get().autoFiltersExclude;
    set({ autoFiltersExclude: setter([...prev]) });
  },
  yearMinId: null,
  setYearMinId: (yearMinId: Nullable<number>) => set({ yearMinId }),
  yearMaxId: null,
  setYearMaxId: (yearMaxId: Nullable<number>) => set({ yearMaxId }),
  typeId: null,
  setTypeId: (typeId: Nullable<number>) => set({ typeId }),
  sortId: null,
  setSortId: (sortId: Nullable<number>) => set({ sortId }),
  brokenParamId: null,
  setBrokenParamId: (brokenParamId: Nullable<number>) => set({ brokenParamId }),
  pubDayId: null,
  setPubDayId: (pubDayId: Nullable<number>) => set({ pubDayId }),
  emiratesIds: null,
  setEmiratesIds: (emiratesIds: Nullable<number[]>) => {
    set({ emiratesIds });
  },
  regSpecsIds: null,
  setRegSpecsIds: (regSpecsIds: Nullable<number[]>) => {
    set({ regSpecsIds });
  },
  bodiesIds: null,
  setBodiesIds: (bodiesIds: Nullable<number[]>) => set({ bodiesIds }),
  transmissionsIds: null,
  setTransmissionsIds: (transmissionsIds: Nullable<number[]>) =>
    set({ transmissionsIds }),
  enginesIds: null,
  setEnginesIds: (enginesIds: Nullable<number[]>) => set({ enginesIds }),
  driveUnitsIds: null,
  setDriveUnitsIds: (driveUnitsIds: Nullable<number[]>) =>
    set({ driveUnitsIds }),
  colorsIds: null,
  setColorsIds: (colorsIds: Nullable<number[]>) => set({ colorsIds }),
  mileageMin: null,
  setMileageMin: (mileageMin: Nullable<number>) => set({ mileageMin }),
  mileageMax: null,
  setMileageMax: (mileageMax: Nullable<number>) => set({ mileageMax }),
  priceMin: null,
  setPriceMin: (priceMin: Nullable<number>) => set({ priceMin }),
  priceMax: null,
  setPriceMax: (priceMax: Nullable<number>) => set({ priceMax }),
  tradeAvailable: null,
  setTradeAvailable: (tradeAvailable: Nullable<boolean>) =>
    set({ tradeAvailable }),
  timeTo100Min: null,
  setTimeTo100Min: (timeTo100Min: Nullable<number>) => set({ timeTo100Min }),
  timeTo100Max: null,
  setTimeTo100Max: (timeTo100Max: Nullable<number>) => set({ timeTo100Max }),
  horsePowerMin: null,
  setHorsePowerMin: (horsePowerMin: Nullable<number>) => set({ horsePowerMin }),
  horsePowerMax: null,
  setHorsePowerMax: (horsePowerMax: Nullable<number>) => set({ horsePowerMax }),
  volumeMin: null,
  setVolumeMin: (volumeMin: Nullable<number>) => set({ volumeMin }),
  volumeMax: null,
  setVolumeMax: (volumeMax: Nullable<number>) => set({ volumeMax }),
  vin: null,
  setVin: (vin: Nullable<string>) => set({ vin }),
  advertTypeId: null,
  setAdvertTypeId: (advertTypeId: Nullable<number>) => set({ advertTypeId }),
  vrpPlateEmirateId: null,
  setPlateEmirateId: (vrpPlateEmirateId: Nullable<number>) =>
    set({ vrpPlateEmirateId }),
  vrpPlateEmirateName: null,
  setPlateEmirateName: (vrpPlateEmirateName: Nullable<string>) =>
    set({ vrpPlateEmirateName }),
  vrpTypeId: null,
  setVrpTypeId: (vrpTypeId: Nullable<number>) => set({ vrpTypeId }),
  vrpTypeName: null,
  setVrpTypeName: (vrpTypeName: Nullable<string>) => set({ vrpTypeName }),
  vrpPlateNumberLen: null,
  setVrpPlateNumberLen: (vrpPlateNumberLen: Nullable<number>) =>
    set({ vrpPlateNumberLen }),
  resetValues: (values?: Partial<SearchStore>) => {
    set({
      autoFilters: [emptyAutoFilter],
      autoFiltersExclude: [],
      yearMinId: null,
      yearMaxId: null,
      typeId: null,
      sortId: null,
      brokenParamId: null,
      pubDayId: null,
      emiratesIds: null,
      regSpecsIds: null,
      bodiesIds: null,
      transmissionsIds: null,
      enginesIds: null,
      driveUnitsIds: null,
      colorsIds: null,
      mileageMin: null,
      mileageMax: null,
      priceMin: null,
      priceMax: null,
      timeTo100Min: null,
      timeTo100Max: null,
      horsePowerMin: null,
      horsePowerMax: null,
      volumeMin: null,
      volumeMax: null,
      tradeAvailable: null,
      vin: null,
      vrpPlateEmirateId: null,
      vrpPlateEmirateName: null,
      vrpTypeId: null,
      vrpTypeName: null,
      vrpPlateNumberLen: null,
      advertTypeId: null,
      ...values
    });
  }
}));

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { uploadFile } from '@/modules/files/api';
import { UploadRes } from '@/modules/files/types';
import { UploadAcceptType } from '@/modules/files/Uploader/helpers';
import { INVALID_IMG_SRC } from '@/utils/consts';
import { showAlert } from '@/utils/network';

import cls from './Uploader.module.scss';

const emptyFn = () => null;

type Props = {
  fileName?: string;
  src?: string;
  label?: string;
  loading?: boolean;
  large?: boolean;
  setLoading: (loading: boolean) => void;
  onUpload: (res: UploadRes) => void;
  type?: UploadAcceptType;
};

export function Uploader({
  fileName,
  type,
  src,
  label,
  loading,
  large,
  setLoading,
  onUpload
}: Props) {
  const { t } = useTranslation();

  const isImgType = type === 'image';

  // File handle
  const isFileValid = (file: File): boolean => {
    const isImgTypeValid = isImgType ? file.type.startsWith('image') : true;
    const isValidType = isImgTypeValid;

    if (!isValidType) {
      showAlert({ error: t('common:upload.imageTypeError') });
    }

    return isValidType;
  };

  const handleFileChange = (info: UploadChangeParam) => {
    if (info.file.originFileObj && !loading) {
      setLoading(true);
      uploadFile({
        file: info.file.originFileObj
      })
        .then((r) => onUpload(r.data))
        .catch((error) => showAlert({ error }))
        .finally(() => setLoading(false));
    }
  };

  const isValidSrc = !!src && !src.startsWith(INVALID_IMG_SRC);

  return (
    <div className={cn(cls.root, { [cls.root_large]: large })}>
      <Upload
        listType="picture-card"
        showUploadList={false}
        disabled={loading}
        customRequest={emptyFn}
        beforeUpload={isFileValid}
        onChange={handleFileChange}
      >
        {isImgType && isValidSrc ? (
          <img src={src} width="100%" />
        ) : (
          <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            {fileName ? (
              <div className={cls.label}>{fileName}</div>
            ) : (
              <div className={cls.label}>{label || t('common.upload')}</div>
            )}
          </div>
        )}
      </Upload>
    </div>
  );
}

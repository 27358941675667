import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/api/instance';
import { useCommonStore } from '@/app/common-store';
import { GetShowroomTariffsRes } from '@/modules/tariffs/types';
import { showAlert } from '@/utils/network';

export function useShowroomTariffs(
  refreshKey?: number
): [GetShowroomTariffsRes, boolean] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetShowroomTariffsRes>([]);

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetShowroomTariffsRes>(
        '/admin/showroom-tariffs',
        {
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, isLoading];
}

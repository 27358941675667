import { EmirateType } from '@/modules/emirates/types';
import {
  TransportBrand,
  TransportModel,
  TransportYear
} from '@/modules/transport/types';
import { VrpType, VrpCode } from '@/modules/vrp/types';
import { CommonObj, Nullable } from '@/utils/types';

export const APPROVED_ID = 3;
export const REJECTED_ID = 6;

export function getDateStr(createdAt: number | string) {
  const date =
    typeof createdAt === 'string'
      ? new Date(createdAt)
      : new Date(createdAt * 1000);

  return date.toLocaleDateString('ru', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
}

type AdvertType = {
  brand: Nullable<TransportBrand>;
  model: Nullable<TransportModel>;
  generation: Nullable<CommonObj>;
  year_of_issue: Nullable<TransportYear>;
  vrp_plate_emirate?: Nullable<EmirateType>;
  vrp_type?: Nullable<VrpType>;
  vrp_plate_number?: Nullable<string>;
  vrp_plate_code?: Nullable<VrpCode>;
};

export function getAdvertName(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: (key: string, options: Record<string, any>) => string,
  advert: AdvertType
): string {
  const isVrp = !!advert.vrp_plate_number;
  const brand = advert.brand?.name || '';
  const model = advert.model?.name || '';
  const generation = advert.generation?.name || '';
  const year = advert.year_of_issue?.name || '';

  const title = t('advertTitle', {
    brand,
    model,
    generation
  }).trim();
  const carName = year ? [title, year].join(', ') : title;

  if (!isVrp) return carName;

  const emirate =
    'vrp_plate_emirate' in advert ? advert.vrp_plate_emirate?.name || '' : '';
  const type = 'vrp_type' in advert ? advert.vrp_type?.name || '' : '';
  const code =
    'vrp_plate_code' in advert ? advert.vrp_plate_code?.name || '' : '';
  const number =
    'vrp_plate_number' in advert ? advert.vrp_plate_number || '' : '';

  return [type, emirate, `${code} ${number}`].filter((v) => !!v).join(', ');
}

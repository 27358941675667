import { EditOutlined } from '@ant-design/icons';
import { Table, Typography, Space, Input, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateCountry } from '@/modules/countries/api';
import { CountryForm } from '@/modules/countries/CountryForm/CountryForm';
import { useCountries } from '@/modules/countries/hooks';
import { Country } from '@/modules/countries/types';
import { MAX_PG_LIMIT, TABLE_ACTION_CLASS } from '@/utils/consts';
import { showAlert } from '@/utils/network';
import { Nullable } from '@/utils/types';

export type FormType = {
  emoji: string;
  country: string;
  keywords: Nullable<string[]>;
};

export function CountriesList() {
  const { t } = useTranslation();

  // Data
  const [search, setSearch] = useState('');
  const [refreshKey, setRefreshKey] = useState(0);
  const req = useMemo(() => {
    return {
      skip: 0,
      limit: MAX_PG_LIMIT
    };
  }, []);
  const [data, , isLoading] = useCountries(req, refreshKey);

  const filteredData = useMemo(() => {
    const lowSearch = search.toLowerCase();
    const searched = search
      ? data.filter((v) => v.country.toLowerCase().includes(lowSearch))
      : data;

    return searched;
  }, [data, search]);

  const refreshData = () => {
    setRefreshKey((p) => p + 1);
  };

  // Edit
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] =
    useState<Nullable<Country>>(null);
  const onEditClick = (country: Country) => {
    setSelectedCountry(country);
    setEditOpen(true);
  };
  const onEditSave = async (form: FormType) => {
    if (!selectedCountry) return;

    setEditing(true);
    try {
      await updateCountry({
        emoji: form.emoji,
        keywords: form.keywords || null,
        name: form.country,
        country_id: selectedCountry.id
      });
      setSelectedCountry(null);
      setEditOpen(false);
      refreshData();
    } catch (error) {
      showAlert({ error });
    } finally {
      setEditing(false);
    }
  };

  // Columns
  const columns = useMemo<ColumnsType<Country>>(
    () => [
      {
        key: 'emoji',
        dataIndex: 'emoji',
        title: t('countryFlag')
      },
      {
        key: 'country',
        dataIndex: 'country',
        title: t('countryName')
      },
      {
        key: 'code',
        dataIndex: 'code',
        title: t('countryCode')
      },
      {
        key: 'mask',
        dataIndex: 'mask',
        title: t('countyMask')
      },
      {
        key: 'edit',
        render: (_, rec) => (
          <Button
            className={TABLE_ACTION_CLASS}
            onClick={() => onEditClick(rec)}
            disabled={isEditing}
            icon={<EditOutlined />}
            type="link"
          />
        ),
        width: 64
      }
    ],
    [isEditing, t]
  );

  return (
    <div>
      <Space className="table-header">
        <Typography.Title>{t('countries')}</Typography.Title>
      </Space>

      <Space className="table-bar">
        <Input
          style={{ width: 320 }}
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          placeholder={t('common.search') as string}
        />
      </Space>

      <Table
        columns={columns}
        rowKey={(rec) => rec.id}
        dataSource={filteredData}
        pagination={false}
        loading={isLoading}
      />

      {selectedCountry && (
        <CountryForm
          key={selectedCountry.id}
          title={t('common.change')}
          isOpen={isEditOpen}
          setOpen={setEditOpen}
          onSave={onEditSave}
          loading={isEditing}
          initialData={{
            ...selectedCountry,
            keywords: selectedCountry.keywords
          }}
        />
      )}
    </div>
  );
}

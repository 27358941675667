import { EditOutlined } from '@ant-design/icons';
import { Table, Typography, Space, Input, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateChildBody } from '@/modules/transport/api';
import {
  BodyChildForm,
  FormType
} from '@/modules/transport/body-childs/BodyChildForm/BodyChildForm';
import { useBodyChildsPg } from '@/modules/transport/hooks';
import { TransportChildBody } from '@/modules/transport/types';
import { MAX_PG_LIMIT, TABLE_ACTION_CLASS } from '@/utils/consts';
import { showAlert } from '@/utils/network';
import { Nullable } from '@/utils/types';

export function BodyChilds() {
  const { t } = useTranslation();

  // Data
  const [search, setSearch] = useState('');
  const req = useMemo(() => {
    return {
      skip: 0,
      limit: MAX_PG_LIMIT
    };
  }, []);

  const [refreshKey, setRefreshKey] = useState(0);
  const [data, , isLoading] = useBodyChildsPg(req, refreshKey);
  const filteredData = useMemo(() => {
    const lowSearch = search.toLowerCase();
    return search
      ? data.filter((v) => v.name.toLowerCase().startsWith(lowSearch))
      : data;
  }, [data, search]);
  const refreshData = () => {
    setRefreshKey((p) => p + 1);
  };

  // Edit
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const [selectedBody, setSelectedBody] =
    useState<Nullable<TransportChildBody>>(null);
  const onEditClick = (Body: TransportChildBody) => {
    setSelectedBody(Body);
    setEditOpen(true);
  };
  const onEditSave = async (form: FormType) => {
    if (!selectedBody) return;

    setEditing(true);
    try {
      await updateChildBody({
        body_child_id: selectedBody.id,
        name: form.name,
        icon_pdf: form.pdf,
        icon_svg: form.svg
      });
      setSelectedBody(null);
      setEditOpen(false);
      refreshData();
    } catch (error) {
      showAlert({ error });
    } finally {
      setEditing(false);
    }
  };

  // Columns
  const columns = useMemo<ColumnsType<TransportChildBody>>(
    () => [
      {
        key: 'svg',
        title: t('svg'),
        render: (_, rec) => (
          <img src={rec.icon_svg || '#'} width={22} height={22} />
        ),
        width: 54
      },
      {
        key: 'pdf',
        title: t('pdf'),
        render: (_, rec) => (
          <img src={rec.icon_pdf || '#'} width={22} height={22} />
        ),
        width: 54
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: t('bodyName')
      },
      {
        key: 'edit',
        render: (_, rec) => (
          <Button
            className={TABLE_ACTION_CLASS}
            onClick={() => onEditClick(rec)}
            disabled={isEditing}
            icon={<EditOutlined />}
            type="link"
          />
        ),
        width: 64
      }
    ],
    [isEditing, t]
  );

  return (
    <div>
      <Space className="table-header">
        <Typography.Title>{t('bodies')}</Typography.Title>
      </Space>

      <Space className="table-bar">
        <Input
          style={{ width: 320 }}
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          placeholder={t('common.search') as string}
        />
      </Space>

      <Table
        columns={columns}
        rowKey={(rec) => rec.id}
        dataSource={filteredData}
        pagination={false}
        loading={isLoading}
      />

      {selectedBody && (
        <BodyChildForm
          title={t('common.change')}
          isOpen={isEditOpen}
          setOpen={setEditOpen}
          onSave={onEditSave}
          loading={isEditing}
          initialData={{
            pdf: selectedBody.icon_pdf || '',
            svg: selectedBody.icon_svg || '',
            name: selectedBody.name || ''
          }}
        />
      )}
    </div>
  );
}

import { Segmented } from 'antd';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSearchStore } from '@/modules/search/store';
import { TransportType } from '@/modules/transport/types';
import { Nullable } from '@/utils/types';

type Props = {
  initialId: Nullable<number>;
  allTypes: TransportType[];
};

export function SearchTypes({ initialId, allTypes }: Props) {
  const { t } = useTranslation();

  const typeId = useSearchStore((s) => s.typeId);
  const setTypeId = useSearchStore((s) => s.setTypeId);

  const options = useMemo(
    () => [
      { label: t('common.all'), value: '' },
      ...allTypes.map((tp) => ({ label: tp.name, value: tp.id }))
    ],
    [allTypes, t]
  );

  useEffect(() => {
    if (initialId && !typeId) {
      setTypeId(initialId);
    }
  }, [typeId, initialId, setTypeId]);

  return (
    <div>
      <Segmented
        size="large"
        options={options}
        value={typeId || ''}
        onChange={(v) => setTypeId(Number(v))}
        block
      />
    </div>
  );
}

import { Input, Modal, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UploadRes } from '@/modules/files/types';
import { Uploader } from '@/modules/files/Uploader/Uploader';

import cls from './BrandForm.module.scss';

export type FormType = {
  name: string;
  photo_url: string;
  dark_mode_photo_url: string;
  keywords: string[];
};

type Props = {
  title: string;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  initialData?: FormType;
  onSave: (form: FormType) => void;
  loading?: boolean;
};

export function BrandForm({
  title,
  isOpen,
  setOpen,
  initialData,
  onSave,
  loading
}: Props) {
  const { t } = useTranslation();

  const [name, setName] = useState(initialData?.name || '');
  const [keywords, setKeywords] = useState(initialData?.keywords || []);

  const [uploading, setUploading] = useState(false);
  const [src, setSrc] = useState(initialData?.photo_url || '');
  const onSrcUpload = (res: UploadRes) => {
    setSrc(res.url);
  };

  const [darkUploading, setDarkUploading] = useState(false);
  const [darkSrc, setDarkSrc] = useState(
    initialData?.dark_mode_photo_url || ''
  );
  const onDarkSrcUpload = (res: UploadRes) => {
    setDarkSrc(res.url);
  };

  const close = () => {
    setOpen(false);
    setSrc('');
    setDarkSrc('');
    setName('');
    setKeywords([]);
  };

  useEffect(() => {
    if (initialData) {
      setSrc(initialData.photo_url);
      setDarkSrc(initialData.dark_mode_photo_url);
      setName(initialData.name);
      setKeywords(initialData.keywords);
    }
  }, [initialData]);

  const isValid = !!src && !!darkSrc && !!name;
  const onOk = () => {
    onSave({ photo_url: src, dark_mode_photo_url: darkSrc, name, keywords });
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      okText={t('common.save')}
      cancelText={t('common.cancel')}
      onCancel={close}
      onOk={onOk}
      okButtonProps={{ disabled: loading || !isValid }}
      cancelButtonProps={{ disabled: loading }}
    >
      <div className={cls.uploads}>
        <Uploader
          type="image"
          src={src}
          loading={uploading}
          setLoading={setUploading}
          onUpload={onSrcUpload}
        />
        <Uploader
          type="image"
          src={darkSrc}
          loading={darkUploading}
          setLoading={setDarkUploading}
          onUpload={onDarkSrcUpload}
        />
      </div>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Input
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          size="large"
          placeholder={t('brandName') as string}
        />
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder={t('keywords')}
          value={keywords}
          onChange={setKeywords}
        />
      </Space>
    </Modal>
  );
}

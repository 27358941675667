import { Select } from 'antd';
import { useMemo } from 'react';

import { TransportYear } from '@/modules/transport/types';
import { Nullable } from '@/utils/types';

type Props = {
  allYears: TransportYear[];
  yearsLoading: boolean;
  selectedId: Nullable<number>;
  placeholder: string;
  onChange: (id: number) => void;
  onClear: () => void;
};

export function YearSelect({
  allYears,
  selectedId,
  placeholder,
  onChange,
  onClear
}: Props) {
  const options = useMemo(
    () => allYears.map((e) => ({ value: e.id, label: e.name })),
    [allYears]
  );

  return (
    <div>
      <Select
        options={options}
        value={selectedId}
        onChange={onChange}
        placeholder={placeholder}
        onClear={onClear}
        allowClear
        size="large"
        style={{ width: '100%' }}
      />
    </div>
  );
}

import { Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type FormType = {
  name: string;
};

type Props = {
  title: string;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  initialData?: FormType;
  onSave: (form: FormType) => void;
  loading?: boolean;
};

export function ModificationForm({
  title,
  isOpen,
  setOpen,
  initialData,
  onSave,
  loading
}: Props) {
  const { t } = useTranslation();

  const [name, setName] = useState(initialData?.name || '');

  const close = () => {
    setOpen(false);
    setName('');
  };

  useEffect(() => {
    if (initialData) {
      setName(initialData.name);
    }
  }, [initialData]);

  const isValid = !!name;
  const onOk = () => {
    onSave({ name });
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      okText={t('common.save')}
      cancelText={t('common.cancel')}
      onCancel={close}
      onOk={onOk}
      okButtonProps={{ disabled: loading || !isValid }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Input
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
        size="large"
        placeholder={t('modificationName') as string}
      />
    </Modal>
  );
}

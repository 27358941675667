const PATH = '/accounts';

export const ACCOUNTS_API_ROUTES = {
  sendPhoneCodeMain: `${PATH}/auth/code-phone`,
  confirmPhoneCodeMain: `${PATH}/auth/confirm-phone`,
  sendPhoneCodeAdditional: `${PATH}/phone/send-code`,
  confirmPhoneCodeAdditional: `${PATH}/phone/confirm-phone`,
  getAllPhones: `${PATH}/phone/all`,
  getAccountInfo: `${PATH}/detail`,
  updateAccountInfo: `${PATH}/update`,
  getExistedStatuses: `${PATH}/existed-statuses`,
  logout: `${PATH}/logout`,
  deleteAccount: `${PATH}/delete`,
  deleteAccountPhoneCode: `${PATH}/delete/code-phone`,
  resetCounterRemovedFavFromPub: `${PATH}/reset-counter-removed-favorites-from-pub`,
  confirmEmailSendCode: `${PATH}/confirm-email/send-code`,
  confirmEmail: `${PATH}/confirm-email/do`,
  changeEmailSendCode: `${PATH}/change-email/send-code`,
  changeEmail: `${PATH}/change-email/do`,
  changePhoneSendCode: `${PATH}/phone/change-phone/send-code`,
  confirmChangePhone: `${PATH}/phone/change-phone/confirm`,
  getAccountDetail: (account_id: number) => `${PATH}/${account_id}/detail`,
  subscribeUser: (account_id: number) => `${PATH}/subscribe/${account_id}`,
  describeUser: (account_id: number) => `${PATH}/describe/${account_id}`,
  getSubscribersList: `${PATH}/subscribe/list`,
  changeNotificationStatus: (followed_account_id: number) =>
    `${PATH}/subscribe/${followed_account_id}/notifications/change-status`,
  blockUser: (account_id: number) => `${PATH}/${account_id}/block`
};

import { Layout, Space, MenuProps, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { SwitchLang } from '@/ui/layouts/BaseLayout/SwitchLang';

import cls from './BaseLayout.module.scss';
import { Crumbs } from './Crumbs';

const { Header, Content, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    children,
    label
  } as MenuItem;
}

type Props = {
  children: React.ReactNode;
};

export function BaseLayout({ children }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const isAuth = pathname.startsWith('/auth');

  const [activeKey, setActiveKey] = useState(pathname);
  const onKeyChange = (key: string) => {
    navigate(key);
  };

  useEffect(() => {
    setActiveKey(pathname);
  }, [pathname]);

  const menuItems: MenuItem[] = [
    getItem(t('adverts'), '/adverts'),
    getItem(t('deleteAdvert'), '/delete-advert'),
    getItem(t('db'), 'db', [
      getItem(t('banks'), '/db/banks'),
      getItem(t('brands'), '/db/brands'),
      getItem(t('bodies'), '/db/body-childs'),
      getItem(t('countries'), '/db/countries'),
      getItem(t('showroomTariffs'), '/db/tariffs'),
      getItem(t('prompts.title'), '/db/prompts'),
      getItem(t('stickers'), '/db/stickers'),
      getItem(t('promotionPackages'), '/db/promotion'),
      getItem(t('contactLangs'), '/db/langs'),
      getItem(t('accounts'), '/db/accounts')
    ]),
    getItem(t('moderation'), 'moderation', [
      getItem(t('moderationAdverts'), '/moderation'),
      getItem(t('moderationRentAdverts'), '/moderation/rent'),
      getItem(t('reviews.moderation'), '/moderation/reviews'),
      getItem(t('reviews.replyModeration'), '/moderation/replies')
    ])
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider width={280}>
        <div style={{ height: 64 }} />
        <Menu
          theme="dark"
          mode="inline"
          items={menuItems}
          selectedKeys={[activeKey]}
          onClick={(e) => onKeyChange(e.key)}
        />
      </Sider>

      <Layout>
        <Header>
          <div className="container">
            <Space className={cls.header_row}>
              <p>AUTO.AE</p>
              {!isAuth && <SwitchLang />}
            </Space>
          </div>
        </Header>

        <div className={cls.container}>
          {!isAuth && (
            <div className="container">
              <Content>
                <Crumbs />
              </Content>
            </div>
          )}

          <div className="container">
            <div className={cls.content}>{children}</div>
          </div>
        </div>
      </Layout>
    </Layout>
  );
}

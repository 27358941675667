import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';

import { useCommonStore } from '@/app/common-store';
import { PrivateRoute } from '@/app/router/PrivateRoute';
import { AccountsList } from '@/modules/account/AccountsList';
import { AuthForm } from '@/modules/auth/AuthForm/AuthForm';
import { BanksList } from '@/modules/banks/BanksList';
import { ContactLangList } from '@/modules/contact-langs/ContactLangList';
import { CountriesList } from '@/modules/countries/CountriesList';
import { DeleteAdvert } from '@/modules/delete-advert/DeleteAdvert';
import { EmojisList } from '@/modules/emojis/EmojisList';
import { ModerationRentAdvertDetailWrap } from '@/modules/moderation-rent/ModerationRentAdvertDetail/ModerationRentAdvertDetail';
import { ModerationRentList } from '@/modules/moderation-rent/ModerationRentList';
import { ModerationAdvertDetailWrap } from '@/modules/moderation/ModerationAdvertDetail/ModerationAdvertDetail';
import { ModerationList } from '@/modules/moderation/ModerationList';
import { ModerationReplyReviews } from '@/modules/moderation/reviews/ModerationReplyReviews';
import { ModerationReviews } from '@/modules/moderation/reviews/ModerationReviews';
import { PackagesList } from '@/modules/promotion/PackagesList';
import { PromptsList } from '@/modules/prompts/PromptsList';
import { SearchAdverts } from '@/modules/search/SearchAdverts/SearchAdverts';
import { ShowroomTariffsList } from '@/modules/tariffs/ShowroomTariffsList';
import { BodiesListWrap } from '@/modules/transport/bodies/BodiesList';
import { BodyChilds } from '@/modules/transport/body-childs/BodyChilds';
import { BrandsList } from '@/modules/transport/brands/BrandsList';
import { GenerationsListWrap } from '@/modules/transport/generations/GenerationsList/GenerationsList';
import { ModelsListWrap } from '@/modules/transport/models/ModelsList';
import { ModificationsListWrap } from '@/modules/transport/modifications/ModificationsList';

import { ErrorRoute } from './Error';
import { RootLayout } from './RootLayout';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorRoute />,
    handle: {
      crumb: () => ({ textKey: 'main' })
    },
    children: [
      {
        path: '/',
        element: <PrivateRoute />,
        children: [
          { index: true },
          {
            path: 'db',
            element: <Outlet />,
            handle: {
              crumb: () => ({ textKey: 'db' })
            },
            children: [
              {
                index: true
              },
              {
                path: 'modifications',
                element: <ModificationsListWrap />
              },
              {
                path: 'bodies',
                element: <BodiesListWrap />
              },
              {
                path: 'body-childs',
                element: <BodyChilds />
              },
              {
                path: 'brands',
                element: <BrandsList />,
                handle: {
                  crumb: () => ({ textKey: 'brands' })
                }
              },
              {
                path: 'countries',
                element: <CountriesList />,
                handle: {
                  crumb: () => ({ textKey: 'countries' })
                }
              },
              {
                path: 'models',
                element: <ModelsListWrap />,
                handle: {
                  crumb: () => {
                    const { crumbs } = useCommonStore.getState();
                    return {
                      textKey: 'models',
                      text: crumbs.brandName,
                      path: crumbs.brandPath
                    };
                  }
                }
              },
              {
                path: 'generations',
                element: <GenerationsListWrap />,
                handle: {
                  crumb: () => {
                    const { crumbs } = useCommonStore.getState();
                    return {
                      textKey: 'generations',
                      text: crumbs.modelName,
                      path: crumbs.modelPath
                    };
                  }
                }
              },
              {
                path: 'tariffs',
                element: <ShowroomTariffsList />,
                handle: {
                  crumb: () => ({ textKey: 'showroomTariffs' })
                }
              },
              {
                path: 'banks',
                element: <BanksList />
              },
              {
                path: 'prompts',
                element: <PromptsList />
              },
              {
                path: 'stickers',
                element: <EmojisList />
              },
              {
                path: 'promotion',
                element: <PackagesList />
              },
              {
                path: 'langs',
                element: <ContactLangList />
              },
              {
                path: 'accounts',
                element: <AccountsList />
              }
            ]
          },
          {
            path: 'moderation',
            element: <Outlet />,
            handle: {
              crumb: () => ({ textKey: 'moderation' })
            },
            children: [
              {
                index: true,
                element: <ModerationList />
              },
              {
                path: ':id',
                element: <ModerationAdvertDetailWrap />
              },
              {
                path: 'reviews',
                element: <ModerationReviews />
              },
              {
                path: 'replies',
                element: <ModerationReplyReviews />
              },
              {
                path: 'rent',
                element: <Outlet />,
                handle: {
                  crumb: () => ({ textKey: 'moderationRentAdverts' })
                },
                children: [
                  {
                    index: true,
                    element: <ModerationRentList />
                  },
                  {
                    path: ':id',
                    element: <ModerationRentAdvertDetailWrap />
                  }
                ]
              }
            ]
          },
          {
            path: 'adverts',
            element: <SearchAdverts />,
            handle: {
              crumb: () => ({ textKey: 'adverts' })
            }
          },
          {
            path: 'delete-advert',
            element: <DeleteAdvert />,
            handle: {
              crumb: () => ({ textKey: 'deleteAdvert' })
            }
          }
        ]
      },
      {
        path: '/auth',
        element: <AuthForm />
      },
      {
        path: '*',
        element: (
          <div>
            <h1>404</h1>
          </div>
        )
      }
    ]
  }
]);

export function AppRouter() {
  return <RouterProvider router={router} />;
}

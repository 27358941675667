import axiosLib, { AxiosInstance, RawAxiosRequestHeaders } from 'axios';
import { redirect } from 'react-router-dom';

import { useAuthStore } from '@/modules/auth/store';
import { AuthState, AuthType } from '@/modules/auth/types';

import { API_URL, HEADERS, UNAUTH_CODE, makeRequestHeaders } from './settings';

// const initialAuth = getCookieAuth();

export class AutoAPIClass {
  public lang = '';

  public axios: AxiosInstance = axiosLib.create({
    baseURL: API_URL,
    // headers: makeRequestHeaders(initialAuth)
    headers: makeRequestHeaders()
  });

  public headers: RawAxiosRequestHeaders = HEADERS;

  constructor(baseURL: string, auth?: AuthType) {
    this.configure(baseURL, auth);
  }

  public async configure(baseURL: string = API_URL, auth?: AuthType) {
    const { headers } = this;

    this.axios = axiosLib.create({
      baseURL,
      headers: { ...headers, ...makeRequestHeaders(auth) }
    });

    this.axios.interceptors.response.use(undefined, (error) => {
      // Handle 401
      if (
        error &&
        error.isAxiosError &&
        error.response?.status === UNAUTH_CODE
      ) {
        const authStore = useAuthStore.getState();
        if (authStore.authState === AuthState.authed) {
          authStore.signout();
          redirect('/auth');
        }
      }

      return Promise.reject(error);
    });
  }

  public updateAuth(auth?: AuthType): void {
    if (auth) {
      this.axios.defaults.headers.Authorization = `Bearer ${auth.access_token}`;
    } else {
      this.axios.defaults.headers.Authorization = '';
    }
  }

  public setLang(v: string): void {
    this.lang = v;
    this.axios.defaults.headers.lang = v;
  }
}

// API Object
// export const AutoAPI = new AutoAPIClass(API_URL, initialAuth);
export const AutoAPI = new AutoAPIClass(API_URL);
export const { axios } = AutoAPI;

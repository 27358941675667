import {
  ExclamationCircleFilled,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { Button, Table, Typography, Space, Modal } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  deleteModification,
  updateModification
} from '@/modules/transport/api';
import { useModificationsPg } from '@/modules/transport/hooks';
import { TransportModification } from '@/modules/transport/types';
import { MAX_PG_LIMIT, TABLE_ACTION_CLASS } from '@/utils/consts';
import { showAlert } from '@/utils/network';
import { useQuery } from '@/utils/query';
import { Nullable } from '@/utils/types';

import {
  ModificationForm,
  FormType
} from './ModificationForm/ModificationForm';

const { confirm } = Modal;

type Props = {
  bodyId: number;
};

function ModificationsList({ bodyId }: Props) {
  const { t } = useTranslation();

  // Data
  const [refreshKey, setRefreshKey] = useState(0);
  const req = useMemo(() => {
    return {
      body_id: bodyId,
      skip: 0,
      limit: MAX_PG_LIMIT
    };
  }, [bodyId]);
  const [data, , isLoading] = useModificationsPg(req, refreshKey);
  const refreshData = () => {
    setRefreshKey((p) => p + 1);
  };

  // Add
  const [isAdding] = useState<boolean>(false);
  const [isAddOpen, setAddOpen] = useState<boolean>(false);
  const onAddClick = () => {
    setAddOpen(true);
  };
  const onAddSave = (form: FormType) => {
    // todo: create new modification
    console.log(form);
  };

  // Edit
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const [selectedModification, setSelectedModification] =
    useState<Nullable<TransportModification>>(null);
  const onEditClick = (modification: TransportModification) => {
    setSelectedModification(modification);
    setEditOpen(true);
  };
  const onEditSave = async (form: FormType) => {
    if (!selectedModification) return selectedModification;

    setEditing(true);
    try {
      await updateModification({
        ...form,
        modification_id: selectedModification.id
      });
      setSelectedModification(null);
      setEditOpen(false);
      refreshData();
    } catch (error) {
      showAlert({ error });
    } finally {
      setEditing(false);
    }
  };

  // Delete
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const makeModificationDelete = useCallback((modification_id: number) => {
    return new Promise<void>((resolve, reject) => {
      setDeleting(true);

      deleteModification({ modification_id })
        .then(() => {
          refreshData();
          resolve();
        })
        .catch((error) => {
          showAlert({ error });
          reject();
        })
        .finally(() => {
          setDeleting(false);
        });
    });
  }, []);

  const showDeleteConfirm = useCallback(
    (modification: TransportModification) => {
      confirm({
        title: t('common.deleteConfirm'),
        icon: <ExclamationCircleFilled />,
        content: modification.name,
        okText: t('common.delete'),
        cancelText: t('common.cancel'),
        onOk() {
          return makeModificationDelete(modification.id);
        }
      });
    },
    [makeModificationDelete, t]
  );
  const onDeleteClick = useCallback(
    (modification: TransportModification) => {
      setEditOpen(false);
      showDeleteConfirm(modification);
    },
    [showDeleteConfirm]
  );

  // Columns
  const columns = useMemo<ColumnsType<TransportModification>>(
    () => [
      {
        key: 'name',
        dataIndex: 'name'
      },
      {
        key: 'edit',
        render: (_, rec) => (
          <Button
            className={TABLE_ACTION_CLASS}
            onClick={() => onEditClick(rec)}
            disabled={isDeleting || isEditing}
            icon={<EditOutlined />}
            type="link"
          />
        ),
        width: 64
      },
      {
        key: 'delete',
        render: (_, rec) => (
          <Button
            className={TABLE_ACTION_CLASS}
            onClick={() => onDeleteClick(rec)}
            disabled={isDeleting || isEditing}
            icon={<DeleteOutlined />}
            type="link"
            danger
          />
        ),
        width: 64
      }
    ],
    [isDeleting, isEditing, onDeleteClick]
  );

  return (
    <div>
      <Space className="table-header">
        <Typography.Title>{t('modifications')}</Typography.Title>
        <Button onClick={onAddClick} type="primary">
          {t('common.add')}
        </Button>
      </Space>

      <Table
        columns={columns}
        rowKey={(rec) => rec.id}
        dataSource={data}
        pagination={false}
        loading={isLoading}
      />

      <ModificationForm
        title={t('common.add')}
        isOpen={isAddOpen}
        setOpen={setAddOpen}
        onSave={onAddSave}
        loading={isAdding}
      />

      {selectedModification && (
        <ModificationForm
          title={t('common.change')}
          isOpen={isEditOpen}
          setOpen={setEditOpen}
          onSave={onEditSave}
          loading={isEditing}
          initialData={selectedModification}
        />
      )}
    </div>
  );
}

export function ModificationsListWrap() {
  const query = useQuery();
  const bodyId = useMemo(() => {
    if (query.body_id) {
      const num = Number(query.body_id);
      if (!Number.isNaN(num)) return num;
    }
    return null;
  }, [query.body_id]);

  return bodyId ? <ModificationsList bodyId={bodyId} /> : <></>;
}

import { Input } from 'antd';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { MIN_VOLUME, MAX_VOLUME } from '@/modules/search/helpers';
import { useSearchStore } from '@/modules/search/store';
import { onlyNumbers, numberInRange } from '@/utils/format';
import { Nullable } from '@/utils/types';

type Props = {
  initialMin: number;
  initialMax: number;
};

export function Volume({ initialMin, initialMax }: Props) {
  const { t } = useTranslation();

  const min = useSearchStore((s) => s.volumeMin);
  const setMin = useSearchStore((s) => s.setVolumeMin);
  const max = useSearchStore((s) => s.volumeMax);
  const setMax = useSearchStore((s) => s.setVolumeMax);

  const [minFraction, setMinFraction] = useState('');
  const [maxFraction, setMaxFraction] = useState('');

  useEffect(() => {
    if (min === null && initialMin) {
      setMin(initialMin);
    }
  }, [initialMin, min, setMin]);

  useEffect(() => {
    if (max === null && initialMax) {
      setMax(initialMax);
    }
  }, [initialMax, max, setMax]);

  const handleValue = (
    e: React.FormEvent<HTMLInputElement>,
    setFraction: React.Dispatch<React.SetStateAction<string>>,
    setInt: (newValue: Nullable<number>) => void
  ) => {
    const newValue = onlyNumbers(e.currentTarget.value, '.');
    if (newValue && newValue[newValue.length - 1] === '.') {
      setFraction(newValue);
      return;
    }

    const newValueInt = Number(numberInRange(newValue, MIN_VOLUME, MAX_VOLUME));
    setFraction(newValueInt.toString());
    setInt(newValueInt);
  };

  useEffect(() => {
    if (!min && min !== 0) setMinFraction('');
  }, [min]);

  useEffect(() => {
    if (!max && max !== 0) setMaxFraction('');
  }, [max]);

  return (
    <>
      <Input
        value={minFraction}
        onChange={(e) => handleValue(e, setMinFraction, setMin)}
        placeholder={t('volumeFrom') as string}
        inputMode="numeric"
        size="large"
      />
      <Input
        value={maxFraction}
        onChange={(e) => handleValue(e, setMaxFraction, setMax)}
        placeholder={t('to') as string}
        inputMode="numeric"
        size="large"
      />
    </>
  );
}

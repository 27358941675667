import { AuthType } from '@/modules/auth/types';
import { API_URL as API_URL_CONST } from '@/utils/consts';

export const UNAUTH_CODE = 401;

export const API_URL = API_URL_CONST;
export const HEADERS = {
  Accept: 'application/json'
};

export function makeRequestHeaders(auth?: AuthType) {
  if (auth) {
    return {
      ...HEADERS,
      Authorization: `Bearer ${auth.access_token}`
    };
  }

  return { ...HEADERS };
}

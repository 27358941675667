import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import 'nprogress/nprogress.css';

import { AppRouter } from '@/app/router/AppRouter';
import i18n from '@/modules/i18n';
import { Alert } from '@/ui/Alert/Alert';
import '@/styles/index.scss';

import { AppProgress } from './AppProgress';
import { useApp } from './useApp';

function App() {
  useApp();

  return (
    <Suspense fallback={<AppProgress />}>
      <I18nextProvider i18n={i18n}>
        <HelmetProvider>
          <AppRouter />
          <Alert />
        </HelmetProvider>
      </I18nextProvider>
    </Suspense>
  );
}

export default App;

import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { Button, Table, Typography, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getDateStr } from '@/modules/moderation/helpers';
import { RejectForm } from '@/modules/moderation/RejectForm';
import { moderateReplyReview } from '@/modules/moderation/reviews/api';
import { APPROVED_ID, REJECTED_ID } from '@/modules/moderation/reviews/helpers';
import { useModerationReplyReviews } from '@/modules/moderation/reviews/hooks';
import { ModerationReplyReview } from '@/modules/moderation/reviews/types';
import { APP_URL, MAX_PG_LIMIT, TABLE_ACTION_CLASS } from '@/utils/consts';
import { showAlert } from '@/utils/network';
import { Nullable } from '@/utils/types';

const req = {
  limit: MAX_PG_LIMIT,
  skip: 0
};

export function ModerationReplyReviews() {
  const { t } = useTranslation();

  // Data
  const [refreshKey, setRefreshKey] = useState(0);
  const [data, isLoading] = useModerationReplyReviews(req, refreshKey);
  const refreshData = () => {
    setRefreshKey((p) => p + 1);
  };

  // Moderate
  const [isChanging, setChanging] = useState(false);

  const [isRejectOpen, setRejectOpen] = useState(false);
  const [rejectReviewId, setRejectReviewId] = useState<Nullable<number>>(null);
  const [reasonText, setReasonText] = useState('');
  const changeStatus = useCallback(
    async (id: number, approved: boolean) => {
      setChanging(true);
      try {
        await moderateReplyReview({
          status_id: approved ? APPROVED_ID : REJECTED_ID,
          review_id: id,
          rejected_reason: approved ? null : reasonText
        });
        if (!approved) {
          setReasonText('');
          setRejectReviewId(null);
        }
        refreshData();
      } catch (error) {
        showAlert({ error });
      } finally {
        setChanging(false);
      }
    },
    [reasonText]
  );

  const onRejectClick = (id: number) => {
    setRejectOpen(true);
    setRejectReviewId(id);
  };
  const onReject = () => {
    setRejectOpen(false);
    if (rejectReviewId) changeStatus(rejectReviewId, false);
  };

  // Columns
  const columns = useMemo<ColumnsType<ModerationReplyReview>>(
    () => [
      {
        key: 'text',
        title: t('reviews.text'),
        render: (_, rec) => {
          return <p className="wrap_text">{rec.reviewer.comment}</p>;
        }
      },
      {
        key: 'reply',
        title: t('reviews.replyText'),
        render: (_, rec) => {
          return <p className="wrap_text">{rec.reviewed.comment}</p>;
        }
      },
      {
        key: 'who',
        title: t('reviews.who'),
        render: (_, rec) => {
          return (
            <a
              className="wrap_text"
              href={`${APP_URL}/profile/${rec.reviewer.id}`}
              target="_blank"
              rel="noreferrer"
              style={{ color: '#1677FF' }}
            >
              {rec.reviewer.name}
            </a>
          );
        }
      },
      {
        key: 'whom',
        title: t('reviews.whom'),
        render: (_, rec) => {
          return (
            <a
              className="wrap_text"
              href={`${APP_URL}/profile/${rec.reviewed.id}`}
              target="_blank"
              rel="noreferrer"
              style={{ color: '#1677FF' }}
            >
              {rec.reviewed.name}
            </a>
          );
        }
      },
      {
        key: 'rating',
        title: t('reviews.rating'),
        render: (_, rec) => rec.rating
      },
      {
        key: 'date',
        title: t('reviews.reviewDate'),
        render: (_, rec) => getDateStr(rec.reviewer.created_at)
      },
      {
        key: 'date',
        title: t('reviews.replyDate'),
        render: (_, rec) => getDateStr(rec.reviewed.created_at)
      },
      {
        key: 'actions',
        render: (_, rec) => (
          <Space>
            <Button
              className={TABLE_ACTION_CLASS}
              onClick={() => changeStatus(rec.review_id, true)}
              disabled={isChanging || isLoading}
              icon={<CheckSquareOutlined />}
              type="link"
            />
            <Button
              className={TABLE_ACTION_CLASS}
              onClick={() => onRejectClick(rec.review_id)}
              disabled={isChanging || isLoading}
              icon={<CloseSquareOutlined />}
              type="link"
              danger
            />
          </Space>
        ),
        width: 64
      }
    ],
    [changeStatus, isChanging, isLoading, t]
  );

  return (
    <div>
      <Space className="table-header">
        <Typography.Title>{t('reviews.replyModeration')}</Typography.Title>
      </Space>

      <Table
        columns={columns}
        rowKey={(rec) => rec.reply_id}
        dataSource={data}
        pagination={false}
        loading={isLoading}
      />

      <RejectForm
        isOpen={isRejectOpen}
        setOpen={setRejectOpen}
        text={reasonText}
        setText={setReasonText}
        onSubmit={onReject}
      />
    </div>
  );
}

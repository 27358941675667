import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/api/instance';
import { useCommonStore } from '@/app/common-store';
import { COUNTRY_API_ROUTES } from '@/modules/countries/api';
import {
  Country,
  GetCountriesReq,
  GetCountriesRes
} from '@/modules/countries/types';
import { showAlert } from '@/utils/network';

// Countries
export function useCountries(
  params?: GetCountriesReq,
  refreshKey?: number
): [Country[], number, boolean] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Country[]>([]);
  const [total, setTotal] = useState<number>(data.length);

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetCountriesRes>(
        COUNTRY_API_ROUTES.getCountries,
        {
          params,
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data.country_codes);
      setTotal(r.data.count);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params, langCode]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, total, isLoading];
}

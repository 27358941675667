import { Collapse } from 'react-collapse';

import { EmirateType } from '@/modules/emirates/types';
import { RegSpec } from '@/modules/regional-specs/types';
import { AdvertTypes } from '@/modules/search/SearchAdverts/SearchCars/AdvertTypes';
import { AutoFilterList } from '@/modules/search/SearchAdverts/SearchCars/AutoFilterList/AutoFilterList';
import { BodiesSelect } from '@/modules/search/SearchAdverts/SearchCars/BodiesSelect';
import { BrokenParamsSelect } from '@/modules/search/SearchAdverts/SearchCars/BrokenParamsSelect';
import { ColorsSelect } from '@/modules/search/SearchAdverts/SearchCars/ColorsSelect';
import { DriveUnitsSelect } from '@/modules/search/SearchAdverts/SearchCars/DriveUnitsSelect';
import { EmiratesSelect } from '@/modules/search/SearchAdverts/SearchCars/EmiratesSelect';
import { EnginesSelect } from '@/modules/search/SearchAdverts/SearchCars/EnginesSelect';
import { HorsePower } from '@/modules/search/SearchAdverts/SearchCars/HorsePower';
import { Mileage } from '@/modules/search/SearchAdverts/SearchCars/Mileage';
import { Price } from '@/modules/search/SearchAdverts/SearchCars/Price';
import { PubDaysSelect } from '@/modules/search/SearchAdverts/SearchCars/PubDaysSelect';
import { RegionSpecsSelect } from '@/modules/search/SearchAdverts/SearchCars/RegionSpecsSelect';
import { SearchTypes } from '@/modules/search/SearchAdverts/SearchCars/SearchTypes';
import { TimeToHundred } from '@/modules/search/SearchAdverts/SearchCars/TimeToHundred';
import { TradeAvailable } from '@/modules/search/SearchAdverts/SearchCars/TradeAvailable';
import { TransmissionsSelect } from '@/modules/search/SearchAdverts/SearchCars/TransmissionsSelect';
import { Volume } from '@/modules/search/SearchAdverts/SearchCars/Volume';
import { YearMax } from '@/modules/search/SearchAdverts/SearchCars/YearMax';
import { YearMin } from '@/modules/search/SearchAdverts/SearchCars/YearMin';
import { PubDay, BrokenParam, SearchAdvertsReq } from '@/modules/search/types';
import {
  TransportBrand,
  TransportType,
  TransportColor,
  TransportYear
} from '@/modules/transport/types';

import cls from './SearchCars.module.scss';

type Props = {
  brands: TransportBrand[];
  types: TransportType[];
  colors: TransportColor[];
  emirates: EmirateType[];
  regSpecs: RegSpec[];
  years: TransportYear[];
  pubDays: PubDay[];
  brokenParams: BrokenParam[];
  yearsLoading: boolean;
  initialReq: SearchAdvertsReq;
  hideFilters?: boolean;
};

export function SearchDesktop({
  brands,
  types,
  colors,
  emirates,
  regSpecs,
  years,
  pubDays,
  brokenParams,
  yearsLoading,
  initialReq,
  hideFilters
}: Props) {
  return (
    <Collapse isOpened={!hideFilters}>
      <div className={cls.box}>
        <ul className={cls.fields}>
          <li>
            <EmiratesSelect
              initialIds={initialReq.emirate_ids || []}
              emirates={emirates}
            />
          </li>
          <li>
            <AdvertTypes initialId={initialReq.advertisement_type_id} />
          </li>
          <li>
            <SearchTypes initialId={initialReq.type_id} allTypes={types} />
          </li>
          <AutoFilterList initial={initialReq.auto_filter} brands={brands} />
          <li>
            <RegionSpecsSelect
              initialIds={initialReq.regional_spec_ids || []}
              specs={regSpecs}
            />
          </li>
          <li>
            <div className={cls.row}>
              <BodiesSelect initialIds={initialReq.body_ids || []} />
              <TransmissionsSelect
                initialIds={initialReq.transmission_ids || []}
              />
            </div>
          </li>
          <li>
            <div className={cls.row}>
              <EnginesSelect initialIds={initialReq.engine_ids || []} />
              <DriveUnitsSelect initialIds={initialReq.drive_unit_ids || []} />
            </div>
          </li>
          <li>
            <div className={cls.row_group}>
              <YearMin
                initialId={initialReq.year_of_issue_min_id}
                allYears={years}
                yearsLoading={yearsLoading}
              />
              <YearMax
                initialId={initialReq.year_of_issue_max_id}
                allYears={years}
                yearsLoading={yearsLoading}
              />
            </div>
          </li>
          <li>
            <div className={cls.row_group}>
              <Mileage
                initialMin={initialReq.mileage_min || 0}
                initialMax={initialReq.mileage_max || 0}
              />
            </div>
          </li>
          <li>
            <div className={cls.row_group}>
              <Price
                initialMin={initialReq.price_min || 0}
                initialMax={initialReq.price_max || 0}
              />
            </div>
          </li>
        </ul>

        <div className={cls.additional_fields}>
          <div>
            <div>
              <ul className={cls.fields}>
                <li>
                  <ColorsSelect
                    initialIds={initialReq.colour_ids || []}
                    colors={colors}
                  />
                </li>
                <li>
                  <div className={cls.row_group}>
                    <BrokenParamsSelect
                      params={brokenParams}
                      initialId={initialReq.broken_id}
                    />
                    <PubDaysSelect
                      pubDays={pubDays}
                      initialId={initialReq.days_publication_id}
                    />
                  </div>
                </li>
                <li>
                  <TradeAvailable initial={initialReq.available_trade} />
                </li>
                <li>
                  <div className={cls.row_group}>
                    <HorsePower
                      initialMin={initialReq.horse_power_from || 0}
                      initialMax={initialReq.horse_power_up_to || 0}
                    />
                  </div>
                </li>
                <li>
                  <div className={cls.row_group}>
                    <TimeToHundred
                      initialMin={initialReq.time_to_100_from || 0}
                      initialMax={initialReq.time_to_100_up_to || 0}
                    />
                  </div>
                </li>
                <li>
                  <div className={cls.row_group}>
                    <Volume
                      initialMin={initialReq.volume_from || 0}
                      initialMax={initialReq.volume_up_to || 0}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Collapse>
  );
}

import { Input, Modal, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateContactLangReq } from '@/modules/contact-langs/types';

export type FormType = CreateContactLangReq;

type Props = {
  title: string;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  initialData?: FormType;
  onSave: (form: FormType) => void;
  loading?: boolean;
};

export function ContactLangForm({
  title: headerTitle,
  isOpen,
  setOpen,
  initialData,
  onSave,
  loading
}: Props) {
  const { t } = useTranslation();

  const [title, setTitle] = useState(initialData?.title || '');
  const [nameRu, setNameRu] = useState(initialData?.name_ru || '');
  const [nameEn, setNameEn] = useState(initialData?.name_en || '');

  const close = () => {
    setOpen(false);
    setTitle('');
    setNameRu('');
    setNameEn('');
  };

  useEffect(() => {
    if (initialData) {
      setTitle(initialData.title || '');
      setNameRu(initialData.name_ru || '');
      setNameEn(initialData.name_en || '');
    }
  }, [initialData]);

  const isValid = !!title && !!nameRu && !!nameEn;

  const onOk = () => {
    onSave({
      title,
      name_ru: nameRu,
      name_en: nameEn
    });
  };

  return (
    <Modal
      title={headerTitle}
      open={isOpen}
      okText={t('common.save')}
      cancelText={t('common.cancel')}
      onCancel={close}
      onOk={onOk}
      okButtonProps={{ disabled: loading || !isValid }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <p>{t('title')}</p>
          <Input
            value={title}
            onChange={(e) => setTitle(e.currentTarget.value)}
            size="large"
          />
        </div>
        <div>
          <p>{t('contactLangRu')}</p>
          <Input
            value={nameRu}
            onChange={(e) => setNameRu(e.currentTarget.value)}
            size="large"
          />
        </div>
        <div>
          <p>{t('contactLangEn')}</p>
          <Input
            value={nameEn}
            onChange={(e) => setNameEn(e.currentTarget.value)}
            size="large"
          />
        </div>
      </Space>
    </Modal>
  );
}

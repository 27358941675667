import type { AxiosResponse } from 'axios';

import { axios } from '@/api/instance';
import { getFormData } from '@/utils/network';

import { UploadReq, UploadRes } from './types';

export const FILES_API_ROUTES = {
  upload: '/files/v2/upload',
  getSignedLink: '/files/signed-link'
};

export function uploadFile(req: UploadReq): Promise<AxiosResponse<UploadRes>> {
  return axios.post<UploadRes>(
    FILES_API_ROUTES.upload,
    getFormData({ file: req.file })
  );
}

import { Link } from 'react-router-dom';

import { Nullable } from '@/utils/types';

import cls from './GenerationsList.module.scss';

type Props = {
  to: string;
  src?: Nullable<string>;
  onClick?: () => void;
};

export function GenerationImage({ src, to, onClick }: Props) {
  const imgSrc = src || '/img/placeholder.svg';

  return (
    <Link className={cls.img} to={to} onClick={onClick}>
      <img src={imgSrc} />
    </Link>
  );
}

import { Input, Modal, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateBankReq } from '@/modules/banks/types';
import { UploadRes } from '@/modules/files/types';
import { Uploader } from '@/modules/files/Uploader/Uploader';
import { onlyNumbers } from '@/utils/format';

import cls from './BankForm.module.scss';

export type FormType = CreateBankReq;

type Props = {
  title: string;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  initialData?: FormType;
  onSave: (form: FormType) => void;
  loading?: boolean;
};

export function BankForm({
  title,
  isOpen,
  setOpen,
  initialData,
  onSave,
  loading
}: Props) {
  const { t } = useTranslation();

  const [uploading, setUploading] = useState(false);
  const [src, setSrc] = useState(initialData?.photo_url || '');
  const onSrcUpload = (res: UploadRes) => {
    setSrc(res.url);
  };

  const [nameRu, setNameRu] = useState(initialData?.name_ru || '');
  const [nameEn, setNameEn] = useState(initialData?.name_en || '');
  const [rate, setRate] = useState(
    initialData?.interest_rate ? String(initialData?.interest_rate) : ''
  );
  const [initialPayment, setInitialPayment] = useState(
    initialData?.initial_payment ? String(initialData?.initial_payment) : ''
  );
  const [minLoanTerm, setMinLoanTerm] = useState(
    initialData?.minimum_loan_term ? String(initialData?.minimum_loan_term) : ''
  );
  const [maxLoanTerm, setMaxLoanTerm] = useState(
    initialData?.maximum_loan_term ? String(initialData?.maximum_loan_term) : ''
  );

  const close = () => {
    setOpen(false);
    setSrc('');
    setNameRu('');
    setNameEn('');
    setRate('');
    setInitialPayment('');
    setMinLoanTerm('');
    setMaxLoanTerm('');
  };

  useEffect(() => {
    if (initialData) {
      setSrc(initialData.photo_url);
      setNameRu(initialData.name_ru);
      setNameEn(initialData.name_en || '');
      setRate(
        initialData.interest_rate ? String(initialData.interest_rate) : ''
      );
      setInitialPayment(
        initialData.initial_payment ? String(initialData.initial_payment) : ''
      );
      setMinLoanTerm(
        initialData.minimum_loan_term
          ? String(initialData.minimum_loan_term)
          : ''
      );
      setMaxLoanTerm(
        initialData.maximum_loan_term
          ? String(initialData.maximum_loan_term)
          : ''
      );
    }
  }, [initialData]);

  const isValid =
    !!src &&
    !!nameRu &&
    !!rate &&
    !!initialPayment &&
    !!minLoanTerm &&
    !!maxLoanTerm;

  const onOk = () => {
    onSave({
      photo_url: src,
      name_ru: nameRu,
      name_en: nameEn || null,
      interest_rate: Number(rate),
      initial_payment: Number(initialPayment),
      minimum_loan_term: Number(minLoanTerm),
      maximum_loan_term: Number(maxLoanTerm)
    });
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      okText={t('common.save')}
      cancelText={t('common.cancel')}
      onCancel={close}
      onOk={onOk}
      okButtonProps={{ disabled: loading || !isValid }}
      cancelButtonProps={{ disabled: loading }}
    >
      <div className={cls.uploads}>
        <Uploader
          type="image"
          src={src}
          loading={uploading}
          setLoading={setUploading}
          onUpload={onSrcUpload}
        />
      </div>
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <p>{t('bankNameRu')}</p>
          <Input
            value={nameRu}
            onChange={(e) => setNameRu(e.currentTarget.value)}
            size="large"
          />
        </div>
        <div>
          <p>{t('bankNameEn')}</p>
          <Input
            value={nameEn}
            onChange={(e) => setNameEn(e.currentTarget.value)}
            size="large"
          />
        </div>
        <div>
          <p>{t('interestRate')}</p>
          <Input
            value={rate}
            onChange={(e) => setRate(onlyNumbers(e.currentTarget.value))}
            size="large"
            inputMode="numeric"
          />
        </div>
        <div>
          <p>{t('initialPayment')}</p>
          <Input
            value={initialPayment}
            onChange={(e) =>
              setInitialPayment(onlyNumbers(e.currentTarget.value))
            }
            size="large"
            inputMode="numeric"
          />
        </div>
        <div>
          <p>{t('minLoanTerm')}</p>
          <Input
            value={minLoanTerm}
            onChange={(e) => setMinLoanTerm(onlyNumbers(e.currentTarget.value))}
            size="large"
            inputMode="numeric"
          />
        </div>
        <div>
          <p>{t('maxLoanTerm')}</p>
          <Input
            value={maxLoanTerm}
            onChange={(e) => setMaxLoanTerm(onlyNumbers(e.currentTarget.value))}
            size="large"
            inputMode="numeric"
          />
        </div>
      </Space>
    </Modal>
  );
}

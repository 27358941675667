import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/api/instance';
import { useCommonStore } from '@/app/common-store';
import { GetAccountsReq, GetAccountsRes } from '@/modules/account/types';
import { showAlert } from '@/utils/network';

export function useAccounts(
  params?: GetAccountsReq,
  refreshKey?: number
): [GetAccountsRes | undefined, boolean] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetAccountsRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetAccountsRes>('/admin/account', {
        params,
        headers: {
          lang: langCode || null
        }
      });
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, isLoading];
}

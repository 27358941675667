import { axios } from '@/api/instance';
import { CreateBankReq, UpdateBankReq } from '@/modules/banks/types';

const PATH = '/admin/banks';

export function createBank(req: CreateBankReq) {
  return axios.post<unknown>(PATH, req);
}

export function updateBank(req: UpdateBankReq) {
  return axios.patch<unknown>(PATH, req);
}

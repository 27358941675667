import { axios } from '@/api/instance';
import {
  ModerateReplyReviewReq,
  ModerateReviewReq
} from '@/modules/moderation/reviews/types';

export function moderateReview(req: ModerateReviewReq) {
  return axios.patch<unknown>('/admin/moderation/reviews/change-status', req);
}

export function moderateReplyReview(req: ModerateReplyReviewReq) {
  return axios.patch<unknown>(
    '/admin/moderation/reviews/reply/change-status',
    req
  );
}

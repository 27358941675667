import { createContext, useState } from 'react';

type SearchSelectContextType = {
  autoFiltersSetted: boolean;
  brokenParamsSetted: boolean;
  pubDaysSetted: boolean;
  plateEmirateSetted: boolean;
  plateNumberLenSetted: boolean;
  plateTypeSetted: boolean;
  setAutoFiltersSetted: (v: boolean) => void;
  setBrokenParamsSetted: (v: boolean) => void;
  setPubDaysSetted: (v: boolean) => void;
  setPlateEmirateSetted: (v: boolean) => void;
  setPlateNumberLenSetted: (v: boolean) => void;
  setPlateTypeSetted: (v: boolean) => void;
};

export const SearchSelectContext = createContext<SearchSelectContextType>({
  autoFiltersSetted: false,
  brokenParamsSetted: false,
  pubDaysSetted: false,
  plateEmirateSetted: false,
  plateNumberLenSetted: false,
  plateTypeSetted: false,
  setAutoFiltersSetted: () => null,
  setBrokenParamsSetted: () => null,
  setPubDaysSetted: () => null,
  setPlateEmirateSetted: () => null,
  setPlateNumberLenSetted: () => null,
  setPlateTypeSetted: () => null
});

export function useSearchSelect(): SearchSelectContextType {
  const [autoFiltersSetted, setAutoFiltersSetted] = useState(false);
  const [brokenParamsSetted, setBrokenParamsSetted] = useState(false);
  const [pubDaysSetted, setPubDaysSetted] = useState(false);
  const [plateEmirateSetted, setPlateEmirateSetted] = useState(false);
  const [plateNumberLenSetted, setPlateNumberLenSetted] = useState(false);
  const [plateTypeSetted, setPlateTypeSetted] = useState(false);

  return {
    autoFiltersSetted,
    brokenParamsSetted,
    pubDaysSetted,
    plateEmirateSetted,
    plateNumberLenSetted,
    plateTypeSetted,
    setAutoFiltersSetted,
    setBrokenParamsSetted,
    setPubDaysSetted,
    setPlateEmirateSetted,
    setPlateNumberLenSetted,
    setPlateTypeSetted
  };
}

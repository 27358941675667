import { axios } from '@/api/instance';
import {
  DeleteTransportBrandReq,
  DeleteTransportGenerationReq,
  DeleteTransportModelReq,
  DeleteTransportModificationReq,
  UpdateTransportBodyReq,
  UpdateTransportBrandReq,
  UpdateTransportChildBodyReq,
  UpdateTransportGenerationReq,
  UpdateTransportModelReq,
  UpdateTransportModificationReq
} from '@/modules/transport/types';

export const TRANSPORT_API_ROUTES = {
  getBrands: '/admin/transport-brands/list',
  updateBrand: '/admin/transport-brands/update',
  deleteBrand: '/admin/transport-brands/delete',
  getModels: '/admin/transport-models/list',
  updateModel: '/admin/transport-models/update',
  deleteModel: '/admin/transport-models/delete',
  getGenerations: '/admin/transport-generation/list',
  updateGeneration: '/admin/transport-generation/update',
  deleteGeneration: '/admin/transport-generation/delete',
  getYears: '/admin/transport-issue/list',
  getBodyChilds: '/admin/transport-body-child/list',
  updateChildBody: '/admin/transport-body-child/update',
  getBodies: '/admin/transport-body/list',
  updateBody: '/admin/transport-body/update',
  getModifications: '/admin/transport-modifications/list',
  updateModification: '/admin/transport-modifications/update',
  deleteModification: '/admin/transport-modifications/delete',
  getCharacteristic: '/admin/transport-detail-characteristic'
};

// Brand
export function updateBrand(req: UpdateTransportBrandReq) {
  return axios.patch<unknown>(TRANSPORT_API_ROUTES.updateBrand, req);
}

export function deleteBrand(params: DeleteTransportBrandReq) {
  return axios.delete<unknown>(TRANSPORT_API_ROUTES.deleteBrand, { params });
}

// Model
export function updateModel(req: UpdateTransportModelReq) {
  return axios.patch<unknown>(TRANSPORT_API_ROUTES.updateModel, req);
}

export function deleteModel(params: DeleteTransportModelReq) {
  return axios.delete<unknown>(TRANSPORT_API_ROUTES.deleteModel, { params });
}

// Generation
export function updateGeneration(req: UpdateTransportGenerationReq) {
  return axios.patch<unknown>(TRANSPORT_API_ROUTES.updateGeneration, req);
}

export function deleteGeneration(params: DeleteTransportGenerationReq) {
  return axios.delete<unknown>(TRANSPORT_API_ROUTES.deleteGeneration, {
    params
  });
}

// Body
export function updateBody(req: UpdateTransportBodyReq) {
  return axios.patch<unknown>(TRANSPORT_API_ROUTES.updateBody, req);
}

export function updateChildBody(req: UpdateTransportChildBodyReq) {
  return axios.patch<unknown>(TRANSPORT_API_ROUTES.updateChildBody, req);
}

// Modification
export function updateModification(req: UpdateTransportModificationReq) {
  return axios.patch<unknown>(TRANSPORT_API_ROUTES.updateModification, req);
}

export function deleteModification(params: DeleteTransportModificationReq) {
  return axios.delete<unknown>(TRANSPORT_API_ROUTES.deleteModification, {
    params
  });
}

import { create } from 'zustand';

import { Nullable } from '@/utils/types';

export type AlertType = 'success' | 'error';
export type AlertData = {
  type: AlertType;
  text: string;
};

interface AlertStore {
  data: Nullable<AlertData>;
  show: (alertData: AlertData) => void;
  hide: () => void;
}

export const useAlertStore = create<AlertStore>((set) => ({
  data: null,
  show: (data: AlertData) => set({ data }),
  hide: () => set({ data: null })
}));

import { nanoid } from 'nanoid';
import { useCallback, useContext, useEffect } from 'react';

import {
  emptyAutoFilter,
  EMPTY_BRAND_ID,
  MAX_AUTO_FILTERS_LEN
} from '@/modules/search/consts';
import { SearchSelectContext } from '@/modules/search/search-select-ctx';
import { AutoFilter } from '@/modules/search/SearchAdverts/SearchCars/AutoFilterList/AutoFilter';
import { useSearchStore } from '@/modules/search/store';
import { AutoFilterIds } from '@/modules/search/types';
import { TransportBrand } from '@/modules/transport/types';
import { Nullable } from '@/utils/types';

import cls from '../SearchCars.module.scss';

type Props = {
  initial: Nullable<AutoFilterIds[]>;
  brands: TransportBrand[];
};

export function AutoFilterList({ initial, brands }: Props) {
  const setAutoFilters = useSearchStore((s) => s.setAutoFilters);
  const autoFilters = useSearchStore((s) => s.autoFilters);
  const length = autoFilters.length;

  const { autoFiltersSetted, setAutoFiltersSetted } =
    useContext(SearchSelectContext);

  const onAutoFilterChange = useCallback(
    (i: number) => {
      return (value: AutoFilterIds) => {
        setAutoFiltersSetted(true);
        setAutoFilters((prev) =>
          prev.map((f, j) => {
            if (i === j) {
              return { ...f, ...value };
            }
            return f;
          })
        );
      };
    },
    [setAutoFilters, setAutoFiltersSetted]
  );

  const onAutoFilterRemove = useCallback(
    (i: number) => {
      return () => {
        setAutoFiltersSetted(true);
        setAutoFilters((prev) => {
          const result = prev.filter((_, j) => i !== j);
          return result.length <= 0 ? [emptyAutoFilter] : result;
        });
      };
    },
    [setAutoFilters, setAutoFiltersSetted]
  );

  const onAutoFilterAdd = useCallback(() => {
    setAutoFiltersSetted(true);
    setAutoFilters((prev) => [
      ...prev,
      { ...emptyAutoFilter, nanoid: nanoid() }
    ]);
  }, [setAutoFilters, setAutoFiltersSetted]);

  useEffect(() => {
    const isEmpty =
      !autoFilters ||
      autoFilters.filter((v) => v.brand_id !== EMPTY_BRAND_ID).length <= 0;

    if (!autoFiltersSetted && isEmpty && initial && initial.length > 0) {
      setAutoFiltersSetted(true);
      setAutoFilters(() => initial);
    }
  }, [
    autoFilters,
    autoFiltersSetted,
    initial,
    setAutoFilters,
    setAutoFiltersSetted
  ]);

  const showAdd = useCallback(
    (index: number) => index === length - 1 && length < MAX_AUTO_FILTERS_LEN,
    [length]
  );

  return (
    <>
      {autoFilters.map((v, i) => {
        return (
          <li className={cls.fields_fit} key={v.nanoid}>
            <AutoFilter
              autoFilters={autoFilters}
              brands={brands}
              value={v}
              onChange={onAutoFilterChange(i)}
              onRemove={onAutoFilterRemove(i)}
              onAdd={onAutoFilterAdd}
              showAdd={showAdd(i)}
              showRemove={length > 1}
            />
          </li>
        );
      })}
    </>
  );
}

import {
  CheckSquareOutlined,
  CloseSquareOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button, Descriptions, Space, Spin, Typography } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { moderateRentAdvert } from '@/modules/moderation-rent/api';
import { useModerationRentAdvertDetail } from '@/modules/moderation-rent/hooks';
import {
  APPROVED_ID,
  getAdvertName,
  REJECTED_ID
} from '@/modules/moderation/helpers';
import { RejectForm } from '@/modules/moderation/RejectForm';
import { ADV_CUR, APP_URL } from '@/utils/consts';
import { minutesToHHMM } from '@/utils/date';
import { numberWithSpaces } from '@/utils/format';
import { showAlert } from '@/utils/network';
import { parseNull } from '@/utils/parse';
import { Nullable } from '@/utils/types';

import cls from './ModerationRentAdvertDetail.module.scss';

const { Item } = Descriptions;

type Props = {
  id: number;
};

function ModerationRentAdvertDetail({ id }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [detail, loading] = useModerationRentAdvertDetail(id);

  const title = useMemo(() => {
    if (!detail) return '';
    return getAdvertName(t, detail);
  }, [detail, t]);

  // Moderate
  const [isChanging, setChanging] = useState(false);
  const [isRejectOpen, setRejectOpen] = useState(false);
  const [rejectAdId, setRejectAdId] = useState<Nullable<number>>(null);
  const [reasonText, setReasonText] = useState('');
  const changeStatus = useCallback(
    async (id: number, approved: boolean) => {
      setChanging(true);
      try {
        await moderateRentAdvert({
          status_id: approved ? APPROVED_ID : REJECTED_ID,
          rent_id: id,
          rejected_reason: approved ? null : reasonText
        });
        if (!approved) {
          setReasonText('');
          setRejectAdId(null);
        }
        navigate('/moderation/rent');
      } catch (error) {
        showAlert({ error });
      } finally {
        setChanging(false);
      }
    },
    [navigate, reasonText]
  );

  const onRejectClick = (id: number) => {
    setRejectOpen(true);
    setRejectAdId(id);
  };
  const onReject = () => {
    setRejectOpen(false);
    if (rejectAdId) changeStatus(rejectAdId, false);
  };

  return (
    <div className={cls.root}>
      {loading && <Spin indicator={<LoadingOutlined />} />}

      {!loading && detail && (
        <div>
          <header className={cls.header}>
            <Typography.Title level={2}>{title}</Typography.Title>
            <Space>
              <Space>
                <Button
                  onClick={() => onRejectClick(detail.id)}
                  disabled={isChanging}
                  type="primary"
                  danger
                >
                  {t('reject')}
                </Button>
                <Button
                  onClick={() => changeStatus(detail.id, true)}
                  disabled={isChanging}
                  type="primary"
                >
                  {t('approve')}
                </Button>
              </Space>
            </Space>
          </header>

          {detail.photos && detail.photos.length > 0 && (
            <ul className={cls.photos}>
              {detail.photos.map((p) => (
                <li key={p.photo_url}>
                  <a href={p.photo_url} target="_blank" rel="noreferrer">
                    <img src={p.photo_url} alt="" />
                  </a>
                </li>
              ))}
            </ul>
          )}

          <Descriptions bordered size="small">
            <Item span={3} label={t('description')}>
              {detail.description}
            </Item>
            {detail.tariffs && (
              <Item span={3} label={t('showroomTariffs')}>
                <ul>
                  {detail.tariffs.map((tf) => (
                    <li key={tf.id}>
                      {tf.days_from}-{tf.days_up_to} /{' '}
                      {numberWithSpaces(tf.price)} {ADV_CUR}
                    </li>
                  ))}
                </ul>
              </Item>
            )}
            <Item span={3} label={t('id')}>
              {detail.id}
            </Item>
            <Item span={3} label={t('url')}>
              <a
                className={cls.link}
                href={`${APP_URL}/${detail.url}`}
                target="_blank"
                rel="noreferrer"
              >{`${APP_URL}/${detail.url}`}</a>
            </Item>
            <Item span={3} label={t('status')}>
              {detail.status.name}
            </Item>
            <Item span={3} label="deposit">
              {detail.deposit
                ? `${numberWithSpaces(detail.deposit)} ${ADV_CUR}`
                : parseNull(detail.deposit)}
            </Item>
            <Item span={3} label="deposit_return_days">
              {parseNull(detail.deposit_return_days)}
            </Item>
            <Item span={3} label="age">
              {parseNull(detail.age)}
            </Item>
            <Item span={3} label="driving_experience">
              {parseNull(detail.driving_experience)}
            </Item>
            <Item span={3} label="delivery_to_customer">
              <Button
                icon={
                  detail.delivery_to_customer ? (
                    <CheckSquareOutlined />
                  ) : (
                    <CloseSquareOutlined />
                  )
                }
                type="link"
                danger={!detail.delivery_to_customer}
                style={{ pointerEvents: 'none' }}
              />
            </Item>
            <Item span={3} label="delivery_to_airport">
              <Button
                icon={
                  detail.delivery_to_airport ? (
                    <CheckSquareOutlined />
                  ) : (
                    <CloseSquareOutlined />
                  )
                }
                type="link"
                danger={!detail.delivery_to_airport}
                style={{ pointerEvents: 'none' }}
              />
            </Item>

            <Item span={3} label="pay_methods">
              {detail.pay_methods?.map((p) => p.name).join(', ')}
            </Item>
            <Item span={3} label="docs">
              {detail.docs?.map((p) => p.name).join(', ')}
            </Item>
            <Item span={3} label="account_id">
              {detail.account.id}
            </Item>
            <Item span={3} label={t('authorName')}>
              {detail.account.name}
            </Item>
            <Item span={3} label={t('email')}>
              {detail.account.email}
            </Item>
            <Item span={3} label={t('address')}>
              {detail.address?.name} <br /> {detail.address?.address}
            </Item>
            {detail.address && (
              <Item span={3} label={t('snapshot')}>
                {detail.address.snapshot_url && (
                  <img
                    className={cls.snapshot}
                    src={detail.address.snapshot_url}
                    alt=""
                  />
                )}
              </Item>
            )}
            <Item span={3} label={t('callAfter')}>
              {detail.communication_method?.call_after
                ? minutesToHHMM(detail.communication_method?.call_after)
                : 0}
            </Item>
            <Item span={3} label={t('callBefore')}>
              {detail.communication_method?.call_before
                ? minutesToHHMM(detail.communication_method?.call_before)
                : 0}
            </Item>
            <Item span={3} label={t('availableChat')}>
              <Button
                icon={
                  detail.available_chat ? (
                    <CheckSquareOutlined />
                  ) : (
                    <CloseSquareOutlined />
                  )
                }
                type="link"
                danger={!detail.available_chat}
                style={{ pointerEvents: 'none' }}
              />
            </Item>
            <Item span={3} label={t('availablePhone')}>
              <Button
                icon={
                  detail.communication_method?.available_phone ? (
                    <CheckSquareOutlined />
                  ) : (
                    <CloseSquareOutlined />
                  )
                }
                type="link"
                danger={!detail.communication_method?.available_phone}
                style={{ pointerEvents: 'none' }}
              />
            </Item>

            <Item span={3} label={t('category')}>
              {detail.category.name}
            </Item>
            <Item span={3} label={t('brand')}>
              {detail.brand?.name}
            </Item>
            <Item span={3} label={t('model')}>
              {detail.model?.name}
            </Item>
            <Item span={3} label={t('generation')}>
              {detail.generation?.name}
            </Item>
            <Item span={3} label={t('year')}>
              {detail.year_of_issue?.name}
            </Item>
            <Item span={3} label={t('body')}>
              {detail.body?.name}
            </Item>
            <Item span={3} label={t('modification')}>
              {detail.modification?.name}
            </Item>
            <Item span={3} label={t('engine')}>
              {detail.engine?.name}
            </Item>
            <Item span={3} label={t('driveUnit')}>
              {detail.drive_unit?.name}
            </Item>
            <Item span={3} label={t('transmission')}>
              {detail.transmission?.name}
            </Item>
            <Item span={3} label={t('color')}>
              {detail.body_colour?.name}
            </Item>
            <Item span={3} label="interior_colour">
              {detail.interior_colour?.name}
            </Item>
            <Item span={3} label={t('complectation')}>
              {detail.complectation?.name}
            </Item>
            <Item span={3} label={t('regSpec')}>
              {detail.regional_spec?.name}
            </Item>
          </Descriptions>
        </div>
      )}

      <RejectForm
        isOpen={isRejectOpen}
        setOpen={setRejectOpen}
        text={reasonText}
        setText={setReasonText}
        onSubmit={onReject}
      />
    </div>
  );
}

export function ModerationRentAdvertDetailWrap() {
  const { id } = useParams();
  const numId = id ? Number(id) : null;
  const isIdValid = !!numId && !Number.isNaN(numId);
  return isIdValid ? <ModerationRentAdvertDetail id={numId} /> : <></>;
}

import { useRouteError } from 'react-router-dom';

export function ErrorRoute() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: any = useRouteError();

  return (
    <div id="error-page">
      <p>{error.statusText || error.message}</p>
    </div>
  );
}

import {
  ExclamationCircleFilled,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { Button, Table, Typography, Space, Modal, Input, Checkbox } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCommonStore } from '@/app/common-store';
import { deleteModel, updateModel } from '@/modules/transport/api';
import { useModelsPg } from '@/modules/transport/hooks';
import { TransportModel } from '@/modules/transport/types';
import { MAX_PG_LIMIT, TABLE_ACTION_CLASS } from '@/utils/consts';
import { showAlert } from '@/utils/network';
import { useQuery } from '@/utils/query';
import { Nullable } from '@/utils/types';

import { ModelForm, FormType } from './ModelForm/ModelForm';

const { confirm } = Modal;

type Props = {
  brandId: number;
  brandName: string;
};

function ModelsList({ brandId, brandName }: Props) {
  const { t } = useTranslation();
  const crumbBrandName = useCommonStore((s) => s.crumbs.brandName);
  const setCrumbModel = useCommonStore((s) => s.setCrumbModel);

  // Data
  const [popularFirst, setPopularFirst] = useState(false);
  const [search, setSearch] = useState('');
  const [refreshKey, setRefreshKey] = useState(0);
  const req = useMemo(() => {
    return {
      brand_id: brandId,
      skip: 0,
      limit: MAX_PG_LIMIT
    };
  }, [brandId]);
  const [data, , isLoading] = useModelsPg(req, refreshKey);
  const filteredData = useMemo(() => {
    const lowSearch = search.toLowerCase();
    const searched = search
      ? data.filter((v) => v.name.toLowerCase().startsWith(lowSearch))
      : data;

    return popularFirst
      ? [...searched].sort((a, b) => {
          const popularA = a.is_popular ? 1 : -1;
          const popularB = b.is_popular ? 1 : -1;
          return popularB - popularA;
        })
      : searched;
  }, [data, popularFirst, search]);

  const refreshData = () => {
    setRefreshKey((p) => p + 1);
  };

  // Add
  const [isAdding] = useState<boolean>(false);
  const [isAddOpen, setAddOpen] = useState<boolean>(false);
  const onAddClick = () => {
    setAddOpen(true);
  };
  const onAddSave = (form: FormType) => {
    // todo: create new model
    console.log(form);
  };

  // Edit
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] =
    useState<Nullable<TransportModel>>(null);
  const onEditClick = (model: TransportModel) => {
    setSelectedModel(model);
    setEditOpen(true);
  };
  const onEditSave = async (form: FormType) => {
    if (!selectedModel) return selectedModel;

    setEditing(true);
    try {
      await updateModel({
        ...form,
        model_id: selectedModel.id,
        is_popular: selectedModel.is_popular
      });
      setSelectedModel(null);
      setEditOpen(false);
      refreshData();
    } catch (error) {
      showAlert({ error });
    } finally {
      setEditing(false);
    }
  };
  const togglePopular = useCallback(
    async (model: TransportModel, is_popular: boolean) => {
      setEditing(true);
      try {
        await updateModel({ ...model, is_popular, model_id: model.id });
        refreshData();
      } catch (error) {
        showAlert({ error });
      } finally {
        setEditing(false);
      }
    },
    []
  );

  // Delete
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const makeModelDelete = useCallback((model_id: number) => {
    return new Promise<void>((resolve, reject) => {
      setDeleting(true);

      deleteModel({ model_id })
        .then(() => {
          refreshData();
          resolve();
        })
        .catch((error) => {
          showAlert({ error });
          reject();
        })
        .finally(() => {
          setDeleting(false);
        });
    });
  }, []);

  const showDeleteConfirm = useCallback(
    (model: TransportModel) => {
      confirm({
        title: t('common.deleteConfirm'),
        icon: <ExclamationCircleFilled />,
        content: model.name,
        okText: t('common.delete'),
        cancelText: t('common.cancel'),
        onOk() {
          return makeModelDelete(model.id);
        }
      });
    },
    [makeModelDelete, t]
  );
  const onDeleteClick = useCallback(
    (model: TransportModel) => {
      setEditOpen(false);
      showDeleteConfirm(model);
    },
    [showDeleteConfirm]
  );

  // Columns
  const columns = useMemo<ColumnsType<TransportModel>>(
    () => [
      {
        key: 'name',
        dataIndex: 'name',
        render: (_, rec) => {
          const params = {
            model_id: rec.id.toString(),
            model_name: rec.name
          };

          return (
            <Link
              to={`/db/generations?${new URLSearchParams(params).toString()}`}
            >
              <span>{rec.name}</span>
            </Link>
          );
        }
      },
      {
        key: 'popular',
        title: t('popular'),
        render: (_, rec) => (
          <Checkbox
            className={TABLE_ACTION_CLASS}
            checked={rec.is_popular}
            onChange={() => togglePopular(rec, !rec.is_popular)}
            disabled={isDeleting || isEditing}
          />
        )
      },
      {
        key: 'edit',
        render: (_, rec) => (
          <Button
            className={TABLE_ACTION_CLASS}
            onClick={() => onEditClick(rec)}
            disabled={isDeleting || isEditing}
            icon={<EditOutlined />}
            type="link"
          />
        ),
        width: 64
      },
      {
        key: 'delete',
        render: (_, rec) => (
          <Button
            className={TABLE_ACTION_CLASS}
            onClick={() => onDeleteClick(rec)}
            disabled={isDeleting || isEditing}
            icon={<DeleteOutlined />}
            type="link"
            danger
          />
        ),
        width: 64
      }
    ],
    [isDeleting, isEditing, onDeleteClick, t, togglePopular]
  );

  return (
    <div>
      <Space className="table-header">
        <Typography.Title>
          {crumbBrandName || brandName
            ? t('brandModels', { brand: crumbBrandName || brandName })
            : t('models')}
        </Typography.Title>
        <Button onClick={onAddClick} type="primary">
          {t('common.add')}
        </Button>
      </Space>

      <Space className="table-bar">
        <Input
          style={{ width: 320 }}
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          placeholder={t('common.search') as string}
        />
        <Checkbox
          checked={popularFirst}
          onChange={(e) => setPopularFirst(e.target.checked)}
        >
          {t('popularFirst')}
        </Checkbox>
      </Space>

      <Table
        columns={columns}
        rowKey={(rec) => rec.id}
        dataSource={filteredData}
        pagination={false}
        loading={isLoading}
        onRow={(rec) => ({
          onClick: (e) => {
            const el = e.target as HTMLElement;
            if (!el?.closest(`.${TABLE_ACTION_CLASS}`)) {
              setCrumbModel(rec.name, `/db/models?brand_id=${brandId}`);
            }
          }
        })}
      />

      <ModelForm
        title={t('common.add')}
        isOpen={isAddOpen}
        setOpen={setAddOpen}
        onSave={onAddSave}
        loading={isAdding}
      />

      {selectedModel && (
        <ModelForm
          title={t('common.change')}
          isOpen={isEditOpen}
          setOpen={setEditOpen}
          onSave={onEditSave}
          loading={isEditing}
          initialData={selectedModel}
        />
      )}
    </div>
  );
}

export function ModelsListWrap() {
  const query = useQuery();
  const brandId = useMemo(() => {
    if (query.brand_id) {
      const num = Number(query.brand_id);
      if (!Number.isNaN(num)) return num;
    }
    return null;
  }, [query.brand_id]);
  const brandName = useMemo(() => query.brand_name || '', [query.brand_name]);

  return brandId ? (
    <ModelsList brandId={brandId} brandName={brandName as string} />
  ) : (
    <></>
  );
}

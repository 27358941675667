import { Button, Input } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuthStore } from '@/modules/auth/store';
import { AUTH_PASS } from '@/utils/consts';
import { showAlert } from '@/utils/network';

import cls from './AuthForm.module.scss';

const FAKE_TOKEN = 'autoae-allowed';

export function AuthForm() {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  const navigate = useNavigate();
  const setAuth = useAuthStore((s) => s.setAuth);

  const onSubmit = () => {
    if (value === AUTH_PASS) {
      setAuth({ access_token: FAKE_TOKEN });
      navigate('/');
    } else {
      showAlert({ type: 'error', text: t('auth.wrongPassword') as string });
    }
  };

  return (
    <div className={cls.root}>
      <Input
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        placeholder={t('auth.enterPassword') as string}
        type="password"
      />
      <Button onClick={onSubmit} disabled={!value} block type="primary">
        {t('auth.enter')}
      </Button>
    </div>
  );
}

import { useState, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { axios } from '@/api/instance';
import { useCommonStore } from '@/app/common-store';
import {
  paramsToSearchReq,
  parseSearchParams,
  parseResultsParam
} from '@/modules/search/helpers';
import {
  GetBrokenParamsReq,
  GetBrokenParamsRes,
  GetPubDaysReq,
  GetPubDaysRes,
  GetSortFiltersReq,
  GetSortFiltersRes,
  SearchAdvertsReq,
  SearchAdvertsRes
} from '@/modules/search/types';
import { showAlert } from '@/utils/network';
import { PaginationReq } from '@/utils/types';

// Search adverts
type UseSearchAdvertsPg = {
  isLoading: boolean;
  data: SearchAdvertsRes | undefined;
  load: (skip: number) => void;
};

export function useSearchAdvertsPg(
  req: SearchAdvertsReq,
  pg: PaginationReq
): UseSearchAdvertsPg {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<SearchAdvertsRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const queryReq: PaginationReq = { ...pg };

      const r = await axios.post<SearchAdvertsRes>('/admin/search', req, {
        params: queryReq,
        headers: {
          lang: langCode || null
        }
      });
      const loadedData = r.data;
      setData(loadedData);
      setLoading(false);
    } catch (error) {
      showAlert({ error });
      setLoading(false);
    }
  }, [langCode, pg, req]);

  useEffect(() => {
    load();
  }, [load]);

  return { isLoading, data, load };
}

export function useSearchReq(): [SearchAdvertsReq, boolean] {
  const [searchParams] = useSearchParams();
  const req = paramsToSearchReq(parseSearchParams(searchParams));
  const showResults = parseResultsParam(searchParams);
  return [req, showResults];
}

// Other
export function usePubDays(
  params?: GetPubDaysReq
): [GetPubDaysRes | undefined, boolean] {
  const langCode = useCommonStore((s) => s.langCode);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetPubDaysRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetPubDaysRes>(
        '/advertisements/pub_days/list',
        { params, headers: { lang: langCode || null } }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useBrokenParams(
  params?: GetBrokenParamsReq
): [GetBrokenParamsRes | undefined, boolean] {
  const langCode = useCommonStore((s) => s.langCode);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetBrokenParamsRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetBrokenParamsRes>(
        '/advertisements/broken-params/list',
        {
          params,
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useSortFilters(
  params?: GetSortFiltersReq
): [GetSortFiltersRes | undefined, boolean] {
  const langCode = useCommonStore((s) => s.langCode);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetSortFiltersRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetSortFiltersRes>(
        '/advertisements/sort_filters/list',
        {
          params,
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

import { useCallback, useEffect } from 'react';

import { useCommonStore } from '@/app/common-store';
import { getCookieAuth } from '@/modules/auth/helpers';
import { useAuthStore } from '@/modules/auth/store';
import { AuthState } from '@/modules/auth/types';
import { useUserStore } from '@/modules/user/store';

const handleAuth = () => {
  const auth = getCookieAuth();
  const authStore = useAuthStore.getState();

  if (auth) {
    authStore.setAuth(auth);
  } else {
    authStore.setAuthState(AuthState.not_authed);
  }
};

export function useApp() {
  // Calc
  const setWindowWidth = useCommonStore((s) => s.setWindowWidth);
  const onWindowResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [setWindowWidth]);

  useEffect(() => {
    onWindowResize();
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, [onWindowResize]);

  // Auth
  useEffect(() => {
    handleAuth();
  }, []);

  // Handle (not_)authed
  const authState = useAuthStore((s) => s.authState);
  const fetchUser = useUserStore((s) => s.fetchUser);
  const resetUserStore = useUserStore((s) => s.reset);
  useEffect(() => {
    if (authState === AuthState.authed) {
      fetchUser();
    } else {
      resetUserStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);
}

import { Input } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { MAX_PRICE_LEN } from '@/modules/search/helpers';
import { useSearchStore } from '@/modules/search/store';
import { ADV_CUR } from '@/utils/consts';
import { maxLength, onlyNumbers, numberWithSpaces } from '@/utils/format';

function handleValue(e: React.FormEvent<HTMLInputElement>): number {
  return Number(maxLength(onlyNumbers(e.currentTarget.value), MAX_PRICE_LEN));
}

type Props = {
  initialMin: number;
  initialMax: number;
};

export function Price({ initialMin, initialMax }: Props) {
  const { t } = useTranslation();

  const min = useSearchStore((s) => s.priceMin);
  const setMin = useSearchStore((s) => s.setPriceMin);
  const max = useSearchStore((s) => s.priceMax);
  const setMax = useSearchStore((s) => s.setPriceMax);

  useEffect(() => {
    if (min === null && initialMin) {
      setMin(initialMin);
    }
  }, [initialMin, min, setMin]);

  useEffect(() => {
    if (max === null && initialMax) {
      setMax(initialMax);
    }
  }, [initialMax, max, setMax]);

  return (
    <>
      <Input
        value={min ? numberWithSpaces(min) : ''}
        onChange={(e) => setMin(handleValue(e))}
        placeholder={`${t('priceFrom')}, ${ADV_CUR}`}
        inputMode="numeric"
        size="large"
      />
      <Input
        value={max ? numberWithSpaces(max) : ''}
        onChange={(e) => setMax(handleValue(e))}
        placeholder={t('to') as string}
        inputMode="numeric"
        size="large"
      />
    </>
  );
}

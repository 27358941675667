import { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useAlertStore } from './alert-store';
import cls from './Alert.module.scss';
import fadeCls from './FadeTransition.module.scss';
import { CloseIcon } from './icons';

const DURATION = 5000;

export function Alert() {
  const { data, hide } = useAlertStore();

  const nodeRef = useRef(null);
  const timer = useRef<number>(-1);

  useEffect(() => {
    if (data) {
      window.clearTimeout(timer.current);
      timer.current = window.setTimeout(hide, DURATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    return () => window.clearTimeout(timer.current);
  }, []);

  return (
    <CSSTransition
      classNames={{ ...fadeCls }}
      timeout={200}
      in={!!data}
      nodeRef={nodeRef}
      unmountOnExit
    >
      {data ? (
        <div
          className={cls.root}
          data-type={data.type}
          ref={nodeRef}
          key="alert"
        >
          <p>{data.text}</p>
          <button type="button" onClick={hide}>
            <CloseIcon />
          </button>
        </div>
      ) : (
        <div />
      )}
    </CSSTransition>
  );
}

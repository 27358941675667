import { Select } from 'antd';
import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { RegSpec } from '@/modules/regional-specs/types';
import { useSearchStore } from '@/modules/search/store';

type Props = {
  initialIds: number[];
  specs: RegSpec[];
};

export function RegionSpecsSelect({ initialIds, specs }: Props) {
  const { t } = useTranslation();

  const regSpecsIds = useSearchStore((s) => s.regSpecsIds);
  const setRegSpecsIds = useSearchStore((s) => s.setRegSpecsIds);

  const options = useMemo(
    () => specs.map((e) => ({ value: e.id, label: e.name })),
    [specs]
  );

  useEffect(() => {
    if (!regSpecsIds && initialIds.length > 0) {
      setRegSpecsIds(initialIds);
    }
  }, [regSpecsIds, initialIds, setRegSpecsIds]);

  return (
    <div>
      <Select
        options={options}
        value={regSpecsIds || []}
        onChange={setRegSpecsIds}
        placeholder={t('regionSpecs')}
        mode="multiple"
        onClear={() => setRegSpecsIds([])}
        allowClear
        size="large"
        style={{ width: '100%' }}
      />
    </div>
  );
}

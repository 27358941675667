import { useState, useCallback, useEffect } from 'react';

import { axios } from '@/api/instance';
import { useCommonStore } from '@/app/common-store';
import { GetRegSpecsReq, GetRegSpecsRes } from '@/modules/regional-specs/types';
import { showAlert } from '@/utils/network';

export function useRegSpecs(
  params?: GetRegSpecsReq
): [GetRegSpecsRes | undefined, boolean] {
  const langCode = useCommonStore((s) => s.langCode);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetRegSpecsRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetRegSpecsRes>('/regional-specs/list', {
        params,
        headers: {
          lang: langCode || null
        }
      });
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

import { create } from 'zustand';

import { showAlert } from '@/utils/network';

import { NullableUser } from './types';

interface UserStore {
  isUserLoading: boolean;
  user: NullableUser;
  setUser: (user: NullableUser) => void;
  fetchUser: () => void;
  reset: () => void;
}

export const useUserStore = create<UserStore>((set) => ({
  isUserLoading: false,
  user: null,
  setUser: (user: NullableUser) => set({ user }),
  reset: () => {
    set({ user: null, isUserLoading: false });
  },
  fetchUser: async () => {
    set({ isUserLoading: true });
    try {
      // const { data } = await getUser();
      // set({ user: data, isUserLoading: false });
    } catch (error) {
      showAlert({ error });
      set({ isUserLoading: false });
    }
  }
}));

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, Input, Button, Space } from 'antd';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { deleteAdvertisement } from '@/modules/delete-advert/api';
import { onlyNumbers } from '@/utils/format';
import { showAlert } from '@/utils/network';

const { confirm } = Modal;

export function DeleteAdvert() {
  const { t } = useTranslation();

  const [id, setId] = useState('');
  const [isDeleting, setDeleting] = useState<boolean>(false);

  const makeDelete = useCallback((advertId: number) => {
    return new Promise<void>((resolve, reject) => {
      setDeleting(true);

      deleteAdvertisement(advertId)
        .then(() => {
          setId('');
          resolve();
        })
        .catch((error) => {
          showAlert({ error });
          reject();
        })
        .finally(() => {
          setDeleting(false);
        });
    });
  }, []);

  const showDeleteConfirm = useCallback(() => {
    confirm({
      title: t('common.deleteConfirm'),
      icon: <ExclamationCircleFilled />,
      content: t('idValue', { id }),
      okText: t('common.delete'),
      cancelText: t('common.cancel'),
      onOk() {
        return makeDelete(Number(id));
      }
    });
  }, [id, makeDelete, t]);

  return (
    <Space wrap>
      <Input
        style={{ width: 320 }}
        value={id}
        onChange={(e) => setId(onlyNumbers(e.currentTarget.value))}
        placeholder={t('enterAdvertId') as string}
        disabled={isDeleting}
      />
      <Button
        type="primary"
        onClick={showDeleteConfirm}
        disabled={isDeleting || !id}
        loading={isDeleting}
        danger
      >
        {t('deleteAdvert')}
      </Button>
    </Space>
  );
}

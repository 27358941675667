import { ExclamationCircleFilled, DeleteOutlined } from '@ant-design/icons';
import { Button, Table, Typography, Space, Modal } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  createContactLang,
  deleteContactLang
} from '@/modules/contact-langs/api';
import { useContactLangs } from '@/modules/contact-langs/hooks';
import { ContactLang } from '@/modules/contact-langs/types';
import { TABLE_ACTION_CLASS } from '@/utils/consts';
import { showAlert } from '@/utils/network';

import { ContactLangForm, FormType } from './ContactLangForm/ContactLangForm';
const { confirm } = Modal;
export function ContactLangList() {
  const { t } = useTranslation();

  // Data
  const [refreshKey, setRefreshKey] = useState(0);
  const [data, isLoading] = useContactLangs(refreshKey);
  const refreshData = () => {
    setRefreshKey((p) => p + 1);
  };

  // Add
  const [isAdding, setAdding] = useState<boolean>(false);
  const [isAddOpen, setAddOpen] = useState<boolean>(false);
  const onAddClick = () => {
    setAddOpen(true);
  };
  const onAddSave = async (form: FormType) => {
    setAdding(true);

    try {
      await createContactLang({
        ...form
      });
      setAddOpen(false);
      refreshData();
    } catch (error) {
      showAlert({ error });
    } finally {
      setAdding(false);
    }
  };

  // Delete
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const makeLangDelete = useCallback((lang_id: number) => {
    return new Promise<void>((resolve, reject) => {
      setDeleting(true);

      deleteContactLang(lang_id)
        .then(() => {
          refreshData();
          resolve();
        })
        .catch((error) => {
          showAlert({ error });
          reject();
        })
        .finally(() => {
          setDeleting(false);
        });
    });
  }, []);

  const showDeleteConfirm = useCallback(
    (lang: ContactLang) => {
      confirm({
        title: t('common.deleteConfirm'),
        icon: <ExclamationCircleFilled />,
        content: lang.title,
        okText: t('common.delete'),
        cancelText: t('common.cancel'),
        onOk() {
          return makeLangDelete(lang.id);
        }
      });
    },
    [makeLangDelete, t]
  );
  const onDeleteClick = useCallback(
    (lang: ContactLang) => {
      showDeleteConfirm(lang);
    },
    [showDeleteConfirm]
  );

  // Columns
  const columns = useMemo<ColumnsType<ContactLang>>(
    () => [
      {
        key: 'title',
        dataIndex: 'title',
        title: t('title')
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: t('name')
      },
      {
        key: 'delete',
        render: (_, rec) => (
          <Button
            className={TABLE_ACTION_CLASS}
            onClick={() => onDeleteClick(rec)}
            disabled={isDeleting}
            icon={<DeleteOutlined />}
            type="link"
            danger
          />
        ),
        width: 64
      }
    ],
    [isDeleting, onDeleteClick, t]
  );

  return (
    <div>
      <Space className="table-header">
        <Typography.Title>{t('contactLangs')}</Typography.Title>
        <Button onClick={onAddClick} type="primary" disabled={isAdding}>
          {t('common.add')}
        </Button>
      </Space>

      <Table
        columns={columns}
        rowKey={(rec) => rec.id}
        dataSource={data}
        pagination={false}
        loading={isLoading}
      />

      <ContactLangForm
        title={t('common.add')}
        isOpen={isAddOpen}
        setOpen={setAddOpen}
        onSave={onAddSave}
        loading={isAdding}
      />
    </div>
  );
}

import { useState, useCallback, useEffect } from 'react';

import { axios } from '@/api/instance';
import { useCommonStore } from '@/app/common-store';
import { showAlert } from '@/utils/network';
import { CommonObj, Nullable, PaginationReq } from '@/utils/types';

import { TRANSPORT_API_ROUTES } from './api';
import {
  GetTransportBodiesReq,
  GetTransportBodiesRes,
  GetTransportBrandsReq,
  GetTransportBrandsRes,
  GetTransportChildBodiesReq,
  GetTransportChildBodiesRes,
  GetTransportDriveUnitsReq,
  GetTransportDriveUnitsRes,
  GetTransportEnginesReq,
  GetTransportEnginesRes,
  GetTransportGenerationsReq,
  GetTransportGenerationsRes,
  GetTransportModelsReq,
  GetTransportModelsRes,
  GetTransportModificationsReq,
  GetTransportModificationsRes,
  GetTransportTransmissionsReq,
  GetTransportTransmissionsRes,
  GetTransportYearsReq,
  GetTransportYearsRes,
  TransportBody,
  TransportBrand,
  TransportCharacteristic,
  TransportChildBody,
  TransportColor,
  TransportGeneration,
  TransportIconType,
  TransportModel,
  TransportModification,
  TransportType,
  TransportYear
} from './types';

// Brands
export function useBrandsPg(
  params?: GetTransportBrandsReq,
  refreshKey?: number
): [TransportBrand[], number, boolean] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TransportBrand[]>([]);
  const [total, setTotal] = useState<number>(data.length);

  const load = useCallback(async () => {
    if (!langCode) return;
    setLoading(true);

    try {
      const r = await axios.get<GetTransportBrandsRes>(
        TRANSPORT_API_ROUTES.getBrands,
        {
          params,
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data.brands);
      setTotal(r.data.count);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params, langCode]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, total, isLoading];
}

// Models
export function useModelsPg(
  params: Nullable<GetTransportModelsReq>,
  refreshKey?: number
): [TransportModel[], number, boolean] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TransportModel[]>([]);
  const [total, setTotal] = useState<number>(data.length);

  const load = useCallback(async () => {
    if (!langCode || params === null || !params.brand_id) return;
    setLoading(true);

    try {
      const r = await axios.get<GetTransportModelsRes>(
        TRANSPORT_API_ROUTES.getModels,
        {
          params,
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data.models);
      setTotal(r.data.count);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, total, isLoading];
}

// Generations
export function useGenerationsPg(
  params: Nullable<GetTransportGenerationsReq>
): [TransportGeneration[], number, boolean, () => Promise<void>] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TransportGeneration[]>([]);
  const [total, setTotal] = useState<number>(data.length);

  const load = useCallback(async () => {
    if (!langCode || params === null || !params.model_id) return;
    setLoading(true);

    try {
      const r = await axios.get<GetTransportGenerationsRes>(
        TRANSPORT_API_ROUTES.getGenerations,
        {
          params,
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data.generations);
      setTotal(r.data.count);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, total, isLoading, load];
}

// Years
export function useYearsPg(
  params: GetTransportYearsReq
): [TransportYear[], number, boolean, () => Promise<void>] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TransportYear[]>([]);
  const [total, setTotal] = useState<number>(data.length);

  const load = useCallback(async () => {
    if (!langCode) return;
    setLoading(true);

    try {
      const r = await axios.get<GetTransportYearsRes>(
        TRANSPORT_API_ROUTES.getYears,
        {
          params,
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data.years);
      setTotal(r.data.count);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, total, isLoading, load];
}

// Bodies
export function useBodiesPg(
  params?: GetTransportBodiesReq,
  refreshKey?: number
): [
  TransportBody[],
  number,
  boolean,
  React.Dispatch<React.SetStateAction<TransportBody[]>>
] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TransportBody[]>([]);
  const [total, setTotal] = useState<number>(data.length);

  const load = useCallback(async () => {
    if (!langCode) return;
    setLoading(true);

    try {
      const r = await axios.get<GetTransportBodiesRes>(
        TRANSPORT_API_ROUTES.getBodies,
        {
          params,
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data.bodies);
      setTotal(r.data.count);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params, langCode]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, total, isLoading, setData];
}

export function useBodyChildsPg(
  params?: GetTransportChildBodiesReq,
  refreshKey?: number
): [
  TransportChildBody[],
  number,
  boolean,
  React.Dispatch<React.SetStateAction<TransportChildBody[]>>
] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TransportChildBody[]>([]);
  const [total, setTotal] = useState<number>(data.length);

  const load = useCallback(async () => {
    if (!langCode) return;
    setLoading(true);

    try {
      const r = await axios.get<GetTransportChildBodiesRes>(
        TRANSPORT_API_ROUTES.getBodyChilds,
        {
          params,
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data.body_childs);
      setTotal(r.data.count);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params, langCode]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, total, isLoading, setData];
}

// Bodies
export interface UseTransportBodiesReq extends PaginationReq {
  generation_id?: number;
  brand_ids?: number[];
  model_ids?: number[];
  icon_type?: TransportIconType;
}

export function useTransportBodies(
  params?: Nullable<UseTransportBodiesReq>
): [CommonObj[] | undefined, boolean] {
  const langCode = useCommonStore((s) => s.langCode);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<CommonObj[]>([]);

  const load = useCallback(async () => {
    if (params === null) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const r = await axios.get<CommonObj[]>('/transport-body/list', {
        params,
        headers: {
          lang: langCode || null
        }
      });
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

// Modifications
export function useModificationsPg(
  params: GetTransportModificationsReq,
  refreshKey?: number
): [TransportModification[], number, boolean] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TransportModification[]>([]);
  const [total, setTotal] = useState<number>(data.length);

  const load = useCallback(async () => {
    if (!langCode) return;
    setLoading(true);

    try {
      const r = await axios.get<GetTransportModificationsRes>(
        TRANSPORT_API_ROUTES.getModifications,
        {
          params,
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data.modifications);
      setTotal(r.data.count);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, total, isLoading];
}

// Characteristic
export function useCharacteristic(
  modification_id: number
): [TransportCharacteristic | undefined, boolean] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TransportCharacteristic>();

  const load = useCallback(async () => {
    if (!langCode) return;
    setLoading(true);

    try {
      const r = await axios.get<TransportCharacteristic>(
        TRANSPORT_API_ROUTES.getCharacteristic,
        {
          params: { modification_id },
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, modification_id]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

// Types
export function useTransportTypes(
  params?: Nullable<PaginationReq>
): [TransportType[] | undefined, boolean] {
  const langCode = useCommonStore((s) => s.langCode);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TransportType[]>([]);

  const load = useCallback(async () => {
    if (params === null) return;
    setLoading(true);

    try {
      const r = await axios.get<TransportType[]>('/transport-types/list', {
        params,
        headers: {
          lang: langCode || null
        }
      });
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

// Colors
export function useTransportColors(
  params?: PaginationReq
): [TransportColor[] | undefined, boolean] {
  const langCode = useCommonStore((s) => s.langCode);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TransportColor[]>([]);

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<TransportColor[]>('/transport-colors/list', {
        params,
        headers: {
          lang: langCode || null
        }
      });
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

// Years
export function useTransportYears(
  params?: GetTransportYearsReq
): [GetTransportYearsRes | undefined, boolean] {
  const langCode = useCommonStore((s) => s.langCode);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportYearsRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetTransportYearsRes>(
        TRANSPORT_API_ROUTES.getYears,
        {
          params,
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useTransportTransmissions(
  params?: Nullable<GetTransportTransmissionsReq>
): [GetTransportTransmissionsRes | undefined, boolean] {
  const langCode = useCommonStore((s) => s.langCode);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportTransmissionsRes>([]);

  const load = useCallback(async () => {
    if (params === null) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const r = await axios.get<GetTransportTransmissionsRes>(
        '/transport-transmissions/list',
        {
          params,
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useTransportEngines(
  params?: Nullable<GetTransportEnginesReq>
): [GetTransportEnginesRes | undefined, boolean] {
  const langCode = useCommonStore((s) => s.langCode);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportEnginesRes>([]);

  const load = useCallback(async () => {
    if (params === null) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const r = await axios.get<GetTransportEnginesRes>(
        '/transport-engine/list',
        {
          params,
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useTransportDriveUnits(
  params?: Nullable<GetTransportDriveUnitsReq>
): [GetTransportDriveUnitsRes | undefined, boolean] {
  const langCode = useCommonStore((s) => s.langCode);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportDriveUnitsRes>([]);

  const load = useCallback(async () => {
    if (params === null) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const r = await axios.get<GetTransportDriveUnitsRes>(
        '/transport-drive-unit/list',
        {
          params,
          headers: {
            lang: langCode || null
          }
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode, params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

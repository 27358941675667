import { create } from 'zustand';

import { axios } from '@/api/instance';
import { ACCOUNTS_API_ROUTES } from '@/modules/accounts/api';
import { deleteCookie, setCookie } from '@/utils/cookie';
import { Nullable } from '@/utils/types';

import { APP_AUTH_NAME } from './helpers';
import { AuthState, AuthType } from './types';

interface AuthStore {
  authState: AuthState;
  setAuthState: (authState: AuthState) => void;
  auth: Nullable<AuthType>;
  setAuth: (auth: AuthType) => void;
  remove: () => void;
  signout: () => Promise<void>;
}

export const useAuthStore = create<AuthStore>((set, get) => ({
  authState: AuthState.initial,
  setAuthState: (authState: AuthState) => set({ authState }),
  auth: null,
  setAuth: (auth: AuthType) => {
    setCookie(APP_AUTH_NAME, JSON.stringify(auth));
    // AutoAPI.updateAuth(auth);
    set({ auth, authState: AuthState.authed });
  },
  remove: () => {
    deleteCookie(APP_AUTH_NAME);
    // AutoAPI.updateAuth();
    set({ auth: null, authState: AuthState.not_authed });
  },
  signout: () => {
    const { remove } = get();
    return new Promise<void>((resolve, reject) => {
      axios
        .delete(ACCOUNTS_API_ROUTES.logout)
        .then(() => {
          remove();
          resolve();
        })
        .catch(() => {
          remove();
          reject();
        });
    });
  }
}));

export function useAuthed(): [boolean, AuthState] {
  const authState = useAuthStore((state) => state.authState);
  return [authState === AuthState.authed, authState];
}

import { EditOutlined } from '@ant-design/icons';
import { Button, Table, Typography, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  usePromotionPackages,
  updatePromotionPackages
} from '@/modules/promotion/api';
import { PromotionPackage } from '@/modules/promotion/types';
import { TABLE_ACTION_CLASS } from '@/utils/consts';
import { showAlert } from '@/utils/network';
import { Nullable } from '@/utils/types';

import { PackageForm, FormType } from './PackageForm/PackageForm';

export function PackagesList() {
  const { t } = useTranslation();

  // Data
  const [refreshKey, setRefreshKey] = useState(0);
  const [data, isLoading] = usePromotionPackages(refreshKey);
  const refreshData = () => {
    setRefreshKey((p) => p + 1);
  };

  // Edit
  const optionsList = data.find((p) => !!p.options)?.options;
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] =
    useState<Nullable<PromotionPackage>>(null);
  const onEditClick = (p: PromotionPackage) => {
    setSelectedPackage(p);
    setEditOpen(true);
  };
  const onEditSave = async (form: FormType) => {
    if (!selectedPackage) return;

    setEditing(true);
    try {
      await updatePromotionPackages(selectedPackage.id, form);
      setSelectedPackage(null);
      setEditOpen(false);
      refreshData();
    } catch (error) {
      showAlert({ error });
    } finally {
      setEditing(false);
    }
  };

  // Columns
  const columns = useMemo<ColumnsType<PromotionPackage>>(
    () => [
      {
        key: 'img',
        render: (_, rec) => (
          <img
            src={rec.logo_url || ''}
            width={40}
            height={40}
            style={{ objectFit: 'contain' }}
          />
        ),
        width: 54
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: t('name')
      },
      {
        key: 'price',
        title: t('price'),
        dataIndex: 'price'
      },
      {
        key: 'old_price',
        title: t('oldPrice'),
        dataIndex: 'old_price'
      },
      {
        key: 'valid_days',
        title: t('validDays'),
        dataIndex: 'valid_days'
      },
      {
        key: 'options',
        title: t('options'),
        render: (_, rec) =>
          rec.options
            ?.filter((opt) => opt.include)
            .map((opt) => opt.name)
            .join(', '),
        width: 300
      },
      {
        key: 'edit',
        render: (_, rec) => (
          <Button
            className={TABLE_ACTION_CLASS}
            onClick={() => onEditClick(rec)}
            disabled={isEditing}
            icon={<EditOutlined />}
            type="link"
          />
        ),
        width: 64
      }
    ],
    [isEditing, t]
  );

  return (
    <div>
      <Space className="table-header">
        <Typography.Title>{t('promotionPackages')}</Typography.Title>
      </Space>

      <Table
        columns={columns}
        rowKey={(rec) => rec.id}
        dataSource={data}
        pagination={false}
        loading={isLoading}
      />

      {optionsList && selectedPackage && (
        <PackageForm
          key={selectedPackage.id}
          title={t('common.change')}
          isOpen={isEditOpen}
          setOpen={setEditOpen}
          onSave={onEditSave}
          loading={isEditing}
          initialData={selectedPackage}
          fullOptions={optionsList}
        />
      )}
    </div>
  );
}

import { Checkbox } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useSearchStore } from '@/modules/search/store';
import { Nullable } from '@/utils/types';

type Props = {
  initial: Nullable<boolean>;
};

export function TradeAvailable({ initial }: Props) {
  const { t } = useTranslation();

  const tradeAvailable = useSearchStore((s) => s.tradeAvailable);
  const setTradeAvailable = useSearchStore((s) => s.setTradeAvailable);

  useEffect(() => {
    if (initial !== null) {
      setTradeAvailable(initial);
    }
  }, [initial, setTradeAvailable]);

  return (
    <div>
      <Checkbox
        id="page-search-trade-checkbox"
        checked={!!tradeAvailable}
        onChange={(e) => setTradeAvailable(e.target.checked)}
      >
        {t('tradeAvailable')}
      </Checkbox>
    </div>
  );
}

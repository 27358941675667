import { Select } from 'antd';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EmirateType } from '@/modules/emirates/types';
import { useSearchStore } from '@/modules/search/store';

type Props = {
  initialIds: number[];
  emirates: EmirateType[];
};

export function EmiratesSelect({ initialIds, emirates }: Props) {
  const { t } = useTranslation();

  const emiratesIds = useSearchStore((s) => s.emiratesIds);
  const setEmiratesIds = useSearchStore((s) => s.setEmiratesIds);

  const options = useMemo(
    () => emirates.map((e) => ({ value: e.id, label: e.name })),
    [emirates]
  );

  useEffect(() => {
    if (!emiratesIds && initialIds.length > 0) {
      setEmiratesIds(initialIds);
    }
  }, [emiratesIds, initialIds, setEmiratesIds]);

  return (
    <div>
      <Select
        options={options}
        value={emiratesIds || []}
        onChange={setEmiratesIds}
        placeholder={t('emirate')}
        mode="multiple"
        onClear={() => setEmiratesIds([])}
        allowClear
        size="large"
        style={{ width: '100%' }}
      />
    </div>
  );
}

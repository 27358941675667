import { useState, useCallback, useEffect } from 'react';

import { axios } from '@/api/instance';
import { useCommonStore } from '@/app/common-store';
import { GetEmojisReq, GetEmojisRes } from '@/modules/emojis/types';
import { showAlert } from '@/utils/network';

export function useEmojis(
  params?: GetEmojisReq,
  refreshKey?: number
): [GetEmojisRes | undefined, boolean] {
  const langCode = useCommonStore((s) => s.langCode);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetEmojisRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get('/admin/emoji/list', {
        params,
        headers: {
          lang: langCode || null
        }
      });
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params, langCode]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, isLoading];
}

export function changeEmojiPrice(emoji_price: number) {
  return axios.patch<unknown>('/admin/emoji/price', { emoji_price });
}

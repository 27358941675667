import { Table, Typography, Space, Input, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { changeEmojiPrice, useEmojis } from '@/modules/emojis/api';
import { EmojiType } from '@/modules/emojis/types';
import { ADV_CUR, MAX_PG_LIMIT } from '@/utils/consts';
import { numberWithSpaces, onlyNumbers } from '@/utils/format';
import { showAlert } from '@/utils/network';

const req = {
  limit: MAX_PG_LIMIT,
  skip: 0
};

export function EmojisList() {
  const { t } = useTranslation();

  // Data
  const [refreshKey, setRefreshKey] = useState(0);
  const [data, isLoading] = useEmojis(req, refreshKey);
  const refreshData = () => {
    setRefreshKey((p) => p + 1);
  };

  // Price
  const [price, setPrice] = useState('');
  useEffect(() => {
    if (data && data[0]) {
      setPrice(String(data[0].price));
    }
  }, [data]);
  const [priceLoading, setPriceLoading] = useState(false);
  const savePrice = async () => {
    const num = Number(price);
    const isValid = !!num && !Number.isNaN(num);
    if (!isValid) return;

    try {
      setPriceLoading(true);
      await changeEmojiPrice(num);
      setPrice(String(num));
      refreshData();
    } catch (error) {
      showAlert({ error });
    } finally {
      setPriceLoading(false);
    }
  };

  // Columns
  const columns = useMemo<ColumnsType<EmojiType>>(
    () => [
      {
        key: 'title',
        dataIndex: 'title',
        title: t('name')
      },
      {
        key: 'price',
        title: t('price'),
        render: (_, rec) =>
          rec.price ? `${numberWithSpaces(rec.price)} ${ADV_CUR}` : ''
      }
    ],
    [t]
  );

  return (
    <div>
      <Space className="table-header">
        <Typography.Title>{t('stickers')}</Typography.Title>
      </Space>

      <Space className="table-bar">
        <p>{t('price')}</p>
        <Input
          style={{ width: 320 }}
          value={price}
          onChange={(e) => setPrice(onlyNumbers(e.currentTarget.value))}
          disabled={!data || priceLoading}
        />
        <Button
          onClick={savePrice}
          disabled={!data || priceLoading}
          type="primary"
        >
          {t('common.save')}
        </Button>
      </Space>

      <Table
        columns={columns}
        rowKey={(rec) => rec.id}
        dataSource={data}
        pagination={false}
        loading={isLoading}
      />
    </div>
  );
}

import { Select } from 'antd';
import { useEffect, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchSelectContext } from '@/modules/search/search-select-ctx';
import { useSearchStore } from '@/modules/search/store';
import { PubDay } from '@/modules/search/types';
import { Nullable } from '@/utils/types';

type Props = {
  initialId: Nullable<number>;
  pubDays: PubDay[];
};

export function PubDaysSelect({ initialId, pubDays }: Props) {
  const { t } = useTranslation();

  const pubDayId = useSearchStore((s) => s.pubDayId);
  const setPubDayId = useSearchStore((s) => s.setPubDayId);
  const { pubDaysSetted, setPubDaysSetted } = useContext(SearchSelectContext);

  const options = useMemo(
    () => pubDays.map((e) => ({ value: e.id, label: e.name })),
    [pubDays]
  );

  const onChange = (id: number) => {
    setPubDaysSetted(true);
    setPubDayId(id);
  };

  const onClear = () => {
    setPubDaysSetted(true);
    setPubDayId(null);
  };

  useEffect(() => {
    if (!pubDayId && initialId && !pubDaysSetted) {
      setPubDaysSetted(true);
      setPubDayId(initialId);
    }
  }, [pubDayId, initialId, pubDaysSetted, setPubDayId, setPubDaysSetted]);

  return (
    <div>
      <Select
        options={options}
        value={pubDayId}
        onChange={onChange}
        placeholder={t('publishDuration')}
        onClear={onClear}
        allowClear
        size="large"
        style={{ width: '100%' }}
      />
    </div>
  );
}

import { axios } from '@/api/instance';
import { UpdateCountryReq } from '@/modules/countries/types';

export const COUNTRY_API_ROUTES = {
  getCountries: '/admin/country-codes',
  updateCountry: '/admin/country-codes/update'
};

export function updateCountry(req: UpdateCountryReq) {
  return axios.patch<unknown>(COUNTRY_API_ROUTES.updateCountry, req);
}

import { axios } from '@/api/instance';
import { CreateContactLangReq } from '@/modules/contact-langs/types';

const PATH = '/admin/contact-langs';

export function createContactLang(req: CreateContactLangReq) {
  return axios.post<unknown>(`${PATH}/add`, req);
}

export function deleteContactLang(lang_id: number) {
  return axios.delete<unknown>(`${PATH}/delete`, { params: { lang_id } });
}

import { Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EMPTY_BRAND_ID } from '@/modules/search/consts';
import { smartAutoFiltersIds } from '@/modules/search/helpers';
import { useSearchStore } from '@/modules/search/store';
import {
  useTransportBodies,
  UseTransportBodiesReq
} from '@/modules/transport/hooks';
import { MAX_PG_LIMIT } from '@/utils/consts';
import { Nullable } from '@/utils/types';

type Props = {
  initialIds: number[];
};

export function BodiesSelect({ initialIds }: Props) {
  const { t } = useTranslation();

  const autoFilters = useSearchStore((s) => s.autoFilters);
  const bodiesIds = useSearchStore((s) => s.bodiesIds);
  const setBodiesIds = useSearchStore((s) => s.setBodiesIds);

  const [shouldLoadBodies, setShouldLoadBodies] = useState(
    initialIds.length > 0
  );

  const req = useMemo<Nullable<UseTransportBodiesReq>>(() => {
    if (!shouldLoadBodies) return null;

    const filters = autoFilters.filter((f) => f.brand_id !== EMPTY_BRAND_ID);
    const idsReq = smartAutoFiltersIds(filters);
    return { limit: MAX_PG_LIMIT, ...idsReq };
  }, [autoFilters, shouldLoadBodies]);
  const [bodies] = useTransportBodies(req);

  const options = useMemo(
    () => (bodies ? bodies.map((e) => ({ value: e.id, label: e.name })) : []),
    [bodies]
  );

  useEffect(() => {
    if (!bodiesIds && initialIds.length > 0) {
      setBodiesIds(initialIds);
    }
  }, [bodiesIds, initialIds, setBodiesIds]);

  return (
    <div>
      <Select
        options={options}
        value={bodiesIds || []}
        onChange={setBodiesIds}
        placeholder={t('body')}
        onFocus={() => setShouldLoadBodies(true)}
        mode="multiple"
        onClear={() => setBodiesIds([])}
        allowClear
        size="large"
        style={{ width: '100%' }}
      />
    </div>
  );
}

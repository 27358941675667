import { Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UploadRes } from '@/modules/files/types';
import { Uploader } from '@/modules/files/Uploader/Uploader';

import cls from './BodyChildForm.module.scss';

export type FormType = {
  pdf: string;
  svg: string;
  name: string;
};

type Props = {
  title: string;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  initialData?: FormType;
  onSave: (form: FormType) => void;
  loading?: boolean;
};

export function BodyChildForm({
  title,
  isOpen,
  setOpen,
  initialData,
  onSave,
  loading
}: Props) {
  const { t } = useTranslation();

  // PDF
  const [pdfUploading, setPdfUploading] = useState(false);
  const [pdf, setPdf] = useState(initialData?.pdf || '');
  const [pdfName, setPdfName] = useState('');
  const onPdfUpload = (res: UploadRes) => {
    setPdf(res.url);
    setPdfName(res.name);
  };

  // SVG
  const [svgUploading, setSvgUploading] = useState(false);
  const [svg, setSvg] = useState(initialData?.svg || '');
  const onSvgUpload = (res: UploadRes) => {
    setSvg(res.url);
  };

  // Doors
  const [name, setName] = useState(initialData?.name || '');

  const close = () => {
    setOpen(false);
    setPdf('');
    setPdfName('');
    setSvg('');
    setName('');
  };

  useEffect(() => {
    if (initialData) {
      setPdf(initialData.pdf);
      setPdfName('');
      setSvg(initialData.svg);
      setName(initialData.name);
    }
  }, [initialData]);

  const isValid = !!name && !!pdf && !!svg;
  const onOk = () => {
    if (isValid)
      onSave({
        pdf,
        svg,
        name
      });
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      okText={t('common.save')}
      cancelText={t('common.cancel')}
      onCancel={close}
      onOk={onOk}
      okButtonProps={{ disabled: loading || !isValid }}
      cancelButtonProps={{ disabled: loading }}
    >
      <div className={cls.uploads}>
        <Uploader
          src={pdf}
          label={t('pdf') as string}
          loading={pdfUploading}
          setLoading={setPdfUploading}
          onUpload={onPdfUpload}
          fileName={pdfName}
        />
        <Uploader
          type="image"
          src={svg}
          label={t('svg') as string}
          loading={svgUploading}
          setLoading={setSvgUploading}
          onUpload={onSvgUpload}
        />
      </div>
      <Input
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
        size="large"
        placeholder={t('bodyName') as string}
      />
    </Modal>
  );
}

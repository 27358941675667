import { Select } from 'antd';
import { useEffect, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { EmirateType } from '@/modules/emirates/types';
import { SearchSelectContext } from '@/modules/search/search-select-ctx';
import { useSearchStore } from '@/modules/search/store';
import { Nullable } from '@/utils/types';

type Props = {
  initialId: Nullable<number>;
  emirates: EmirateType[];
};

export function PlateEmirateSelect({ initialId, emirates }: Props) {
  const { t } = useTranslation();

  const vrpPlateEmirateId = useSearchStore((s) => s.vrpPlateEmirateId);
  const setPlateEmirateId = useSearchStore((s) => s.setPlateEmirateId);
  const setPlateEmirateName = useSearchStore((s) => s.setPlateEmirateName);
  const { plateEmirateSetted, setPlateEmirateSetted } =
    useContext(SearchSelectContext);

  const options = useMemo(
    () => emirates.map((e) => ({ value: e.id, label: e.name })),
    [emirates]
  );
  const onChange = (v: number) => {
    setPlateEmirateSetted(true);
    setPlateEmirateId(v);
    const em = emirates.find((e) => e.id === v);
    setPlateEmirateName(em?.name || '');
  };

  const onClear = () => {
    setPlateEmirateSetted(true);
    setPlateEmirateId(null);
    setPlateEmirateName(null);
  };

  useEffect(() => {
    if (!vrpPlateEmirateId && initialId !== null && !plateEmirateSetted) {
      setPlateEmirateSetted(true);
      setPlateEmirateId(initialId);
      setPlateEmirateName(
        emirates.find((emirate) => emirate.id === initialId)?.name || null
      );
    }
  }, [
    emirates,
    vrpPlateEmirateId,
    initialId,
    plateEmirateSetted,
    setPlateEmirateId,
    setPlateEmirateName,
    setPlateEmirateSetted
  ]);

  return (
    <div>
      <Select
        options={options}
        value={vrpPlateEmirateId}
        onChange={onChange}
        placeholder={t('plateEmirate')}
        onClear={onClear}
        allowClear
        size="large"
        style={{ width: '100%' }}
      />
    </div>
  );
}

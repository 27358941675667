import { nanoid } from 'nanoid';

import { AutoFilterIds } from '@/modules/search/types';

export const BREAKPOINT = 1072;
export const CARS_CATEGORY_ID = 1;
export const VRP_CATEGORY_ID = 2;
export const PRICE_MIN = 0;
export const PRICE_MAX = 35000000;
export const EMPTY_BRAND_ID = -1;
export const MAX_AUTO_FILTERS_LEN = 5;
export const DEBOUNCE_TIME = 500;
export const SEARCH_RESULT_ID = 'search-result';

export const emptyAutoFilter: AutoFilterIds = {
  nanoid: nanoid(),
  brand_id: EMPTY_BRAND_ID,
  model_id: null,
  generation_id: null
};

import { EditOutlined } from '@ant-design/icons';
import { Button, Table, Typography, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updatePrompt } from '@/modules/prompts/api';
import { usePrompts } from '@/modules/prompts/hooks';
import { PromptForm } from '@/modules/prompts/PromptForm/PromptForm';
import { PromptType } from '@/modules/prompts/types';
import { TABLE_ACTION_CLASS } from '@/utils/consts';
import { showAlert } from '@/utils/network';
import { Nullable } from '@/utils/types';

export function PromptsList() {
  const { t } = useTranslation();

  // Data
  const [refreshKey, setRefreshKey] = useState(0);
  const [data, isLoading] = usePrompts(refreshKey);
  const refreshData = () => {
    setRefreshKey((p) => p + 1);
  };

  // Edit
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const [selectedPrompt, setSelectedPrompt] =
    useState<Nullable<PromptType>>(null);
  const onEditClick = (p: PromptType) => {
    setSelectedPrompt(p);
    setEditOpen(true);
  };
  const onEditSave = async (form: { id: number; prompt: string }) => {
    if (!selectedPrompt) return;

    setEditing(true);
    try {
      await updatePrompt({
        id: form.id,
        prompt_message: form.prompt
      });
      setSelectedPrompt(null);
      setEditOpen(false);
      refreshData();
    } catch (error) {
      showAlert({ error });
    } finally {
      setEditing(false);
    }
  };

  // Columns
  const columns = useMemo<ColumnsType<PromptType>>(
    () => [
      {
        key: 'accountType',
        title: t('prompts.accountType'),
        render: (_, rec) => <p>{rec.account_type.name}</p>,
        width: 128
      },
      {
        key: 'promptType',
        title: t('prompts.promptType'),
        render: (_, rec) => <p>{rec.prompt_type.name}</p>,
        width: 128
      },
      {
        key: 'text',
        title: t('prompts.text'),
        render: (_, rec) => (
          <p style={{ whiteSpace: 'pre-wrap' }}>{rec.prompt}</p>
        )
      },
      {
        key: 'edit',
        render: (_, rec) => (
          <Button
            className={TABLE_ACTION_CLASS}
            onClick={() => onEditClick(rec)}
            disabled={isEditing}
            icon={<EditOutlined />}
            type="link"
          />
        ),
        width: 64
      }
    ],
    [isEditing, t]
  );

  return (
    <div>
      <Space className="table-header">
        <Typography.Title>{t('prompts.title')}</Typography.Title>
      </Space>

      <Table
        columns={columns}
        rowKey={(rec) => rec.prompt_id}
        dataSource={data}
        pagination={false}
        loading={isLoading}
      />

      {selectedPrompt && (
        <PromptForm
          key={selectedPrompt.prompt_id}
          title={t('common.change')}
          isOpen={isEditOpen}
          setOpen={setEditOpen}
          onSave={onEditSave}
          loading={isEditing}
          initialData={{
            ...selectedPrompt
          }}
        />
      )}
    </div>
  );
}

import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, Params, useMatches } from 'react-router-dom';

import cls from './BaseLayout.module.scss';

type CrumbType = {
  textKey: string;
  text?: string;
  path?: string;
};

type RouteMatchWithCrumb = {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: {
    crumb: () => CrumbType;
  };
};

export function Crumbs() {
  const { t } = useTranslation();
  const matches = useMatches() as RouteMatchWithCrumb[];
  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => {
      const c = match.handle?.crumb();

      return {
        title: (
          <Link to={c.path || match.pathname}>{c.text || t(c.textKey)}</Link>
        )
      };
    });

  return <Breadcrumb items={crumbs} className={cls.crumbs} />;
}

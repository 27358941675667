import { EditOutlined } from '@ant-design/icons';
import { Table, Typography, Space, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCommonStore } from '@/app/common-store';
import { updateBody } from '@/modules/transport/api';
import {
  BodyForm,
  FormType
} from '@/modules/transport/bodies/BodyForm/BodyForm';
import { useBodiesPg } from '@/modules/transport/hooks';
import { TransportBody } from '@/modules/transport/types';
import { MAX_PG_LIMIT, TABLE_ACTION_CLASS } from '@/utils/consts';
import { showAlert } from '@/utils/network';
import { useQuery } from '@/utils/query';
import { Nullable } from '@/utils/types';

type Props = {
  generationId: number;
};

function BodiesList({ generationId }: Props) {
  const { t } = useTranslation();
  const generationName = useCommonStore((s) => s.crumbs.generationName);

  // Data
  const req = useMemo(() => {
    return {
      generation_id: generationId,
      skip: 0,
      limit: MAX_PG_LIMIT
    };
  }, [generationId]);

  const [refreshKey, setRefreshKey] = useState(0);
  const [data, , isLoading] = useBodiesPg(req, refreshKey);

  const refreshData = () => {
    setRefreshKey((p) => p + 1);
  };

  // Edit
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const [selectedBody, setSelectedBody] =
    useState<Nullable<TransportBody>>(null);
  const onEditClick = (Body: TransportBody) => {
    setSelectedBody(Body);
    setEditOpen(true);
  };
  const onEditSave = async (form: FormType) => {
    if (!selectedBody) return;

    setEditing(true);
    try {
      await updateBody({ ...form, body_id: selectedBody.id });
      setSelectedBody(null);
      setEditOpen(false);
      refreshData();
    } catch (error) {
      showAlert({ error });
    } finally {
      setEditing(false);
    }
  };

  // Columns
  const columns = useMemo<ColumnsType<TransportBody>>(
    () => [
      {
        key: 'img',
        render: (_, rec) => (
          <img
            src={rec.photo_url}
            width={48}
            height={48}
            style={{ objectFit: 'contain' }}
          />
        )
      },
      {
        key: 'brand',
        title: t('brand'),
        render: (_, rec) => rec.brand.name
      },
      {
        key: 'model',
        title: t('model'),
        render: (_, rec) => rec.model.name
      },
      {
        key: 'generation',
        title: t('generation'),
        render: (_, rec) => rec.generation.name
      },
      {
        key: 'notice',
        title: t('notice'),
        render: (_, rec) => rec.notice
      },
      {
        key: 'numberOfDoors',
        title: t('numberOfDoors'),
        render: (_, rec) => rec.count_doors
      },
      {
        key: 'child',
        title: t('bodyChild'),
        render: (_, rec) => `${rec.body_child.name}`
      },
      {
        key: 'childParent',
        title: t('bodyChildParent'),
        render: (_, rec) => `${rec.body_child.body_parent?.name}`
      },
      {
        key: 'edit',
        render: (_, rec) => (
          <Button
            className={TABLE_ACTION_CLASS}
            onClick={() => onEditClick(rec)}
            disabled={isEditing}
            icon={<EditOutlined />}
            type="link"
          />
        ),
        width: 64
      }
    ],
    [isEditing, t]
  );

  return (
    <div>
      <Space className="table-header">
        <Typography.Title>
          {generationName
            ? t('bodiesByGen', { gen: generationName })
            : t('bodies')}
        </Typography.Title>
      </Space>

      <Table
        columns={columns}
        rowKey={(rec) => rec.id}
        dataSource={data}
        pagination={false}
        loading={isLoading}
      />

      {selectedBody && (
        <BodyForm
          title={t('common.change')}
          isOpen={isEditOpen}
          setOpen={setEditOpen}
          onSave={onEditSave}
          loading={isEditing}
          initialData={{
            photo_url: selectedBody.photo_url || '',
            count_doors: selectedBody.count_doors || 0,
            body_child_id: selectedBody.body_child.id
          }}
        />
      )}
    </div>
  );
}

export function BodiesListWrap() {
  const query = useQuery();
  const generationId = useMemo(() => {
    if (query.generation_id) {
      const num = Number(query.generation_id);
      if (!Number.isNaN(num)) return num;
    }
    return null;
  }, [query.generation_id]);

  return generationId ? <BodiesList generationId={generationId} /> : <></>;
}

import NProgress from 'nprogress';
import { useEffect } from 'react';

NProgress.configure({
  easing: 'ease',
  minimum: 0.3,
  speed: 800,
  showSpinner: false
});

export const AppProgress: React.FC = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return <div />;
};

import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/api/instance';
import { useCommonStore } from '@/app/common-store';
import { GetPromptsRes } from '@/modules/prompts/types';
import { showAlert } from '@/utils/network';

export function usePrompts(refreshKey?: number): [GetPromptsRes, boolean] {
  const langCode = useCommonStore((s) => s.langCode);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetPromptsRes>([]);

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetPromptsRes>('/admin/ai/prompt', {
        headers: {
          lang: langCode || null
        }
      });
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [langCode]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, isLoading];
}

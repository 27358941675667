import { Input, Modal, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/utils/types';

export type FormType = {
  emoji: string;
  country: string;
  keywords: Nullable<string[]>;
};

type Props = {
  title: string;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  initialData?: FormType;
  onSave: (form: FormType) => void;
  loading?: boolean;
};

export function CountryForm({
  title,
  isOpen,
  setOpen,
  initialData,
  onSave,
  loading
}: Props) {
  const { t } = useTranslation();

  const [emoji, setEmoji] = useState(initialData?.emoji || '');
  const [country, setCountry] = useState(initialData?.country || '');
  const [keywords, setKeywords] = useState(initialData?.keywords || []);

  const close = () => {
    setOpen(false);
    setCountry('');
    setKeywords([]);
  };

  useEffect(() => {
    if (initialData) {
      setCountry(initialData.country);
      initialData.keywords && setKeywords(initialData.keywords);
    }
  }, [initialData]);

  const isValid = !!country;
  const onOk = () => {
    onSave({ emoji, country, keywords });
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      okText={t('common.save')}
      cancelText={t('common.cancel')}
      onCancel={close}
      onOk={onOk}
      okButtonProps={{ disabled: loading || !isValid }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Input
          value={emoji}
          onChange={(e) => setEmoji(e.currentTarget.value)}
          size="large"
          placeholder={t('countyFlag') as string}
        />
        <Input
          value={country}
          onChange={(e) => setCountry(e.currentTarget.value)}
          size="large"
          placeholder={t('countyName') as string}
        />
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder={t('keywords')}
          value={keywords}
          onChange={setKeywords}
        />
      </Space>
    </Modal>
  );
}

import { Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  setOpen: (v: boolean) => void;
  text: string;
  setText: (v: string) => void;
  onSubmit: () => void;
};

export function RejectForm({
  isOpen,
  setOpen,
  text,
  setText,
  onSubmit
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('reject')}
      open={isOpen}
      okText={t('reject')}
      cancelText={t('common.cancel')}
      onCancel={() => setOpen(false)}
      onOk={onSubmit}
      okButtonProps={{ disabled: !text, danger: true }}
    >
      <div className="root">
        <Input.TextArea
          value={text}
          onChange={(e) => setText(e.currentTarget.value)}
          placeholder={t('rejectReason') as string}
          autoSize
        />
      </div>
    </Modal>
  );
}

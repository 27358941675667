import { Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { VRP_CATEGORY_ID } from '@/modules/search/consts';
import { Categories } from '@/modules/search/SearchAdverts/Categories';
import { SearchCarsWrap } from '@/modules/search/SearchAdverts/SearchCars/SearchCarsWrap';
import { SearchPlatesWrap } from '@/modules/search/SearchAdverts/SearchPlates/SearchPlatesWrap';
import { useSearchStore } from '@/modules/search/store';

import cls from './SearchAdverts.module.scss';

export function SearchAdverts() {
  const { t } = useTranslation();

  const categoryId = useSearchStore((s) => s.categoryId);
  const isVrp = categoryId === VRP_CATEGORY_ID;

  // Toggle filters visibility
  const [hideFilters, toggleHideFilters] = useState(false);

  return (
    <div>
      <div className={cls.header}>
        <Typography.Title level={1}>{t('adverts')}</Typography.Title>
        <Categories />
      </div>

      {categoryId && (
        <>
          {isVrp ? (
            <SearchPlatesWrap
              categoryId={categoryId}
              hideFilters={hideFilters}
              toggleHideFilters={toggleHideFilters}
              showResults
            />
          ) : (
            <SearchCarsWrap
              categoryId={categoryId}
              hideFilters={hideFilters}
              toggleHideFilters={toggleHideFilters}
              showResults
            />
          )}
        </>
      )}
    </div>
  );
}
